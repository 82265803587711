import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Pagination,
} from "@mui/material";
import styled from "styled-components";

import { JWTDecoder } from "../../customer/style/Customer.module";
import { GET_USER_INFO } from "../gql/mypage.gql";
import { client } from "../../../client";

const MyPageMembership = () => {
  const accessToken = localStorage.getItem("accessToken");
  const [membershipList, setMembershipList] = useState([]);
  const [currentPoint, setCurrentPoint] = useState("");

  const MyPageContainer = styled.div`
    margin: 20px;
    border: 1px solid black;
  `;

  useEffect(() => {
    client
      .query({
        query: GET_USER_INFO,
        context: {
          headers: {
            authorization: accessToken ? `Bearer ${accessToken}` : "",
          },
        },
        fetchPolicy: "no-cache", // 캐시를 사용하지 않도록 설정
      })
      .then((res) => {
        setCurrentPoint(res.data.getUserInfo.current_point);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <MyPageContainer>
      <Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>내 멤버십 정보</TableCell>
              <TableCell>남은 기간</TableCell>
              <TableCell>현재 포인트</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>{currentPoint}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </MyPageContainer>
  );
};

export default MyPageMembership;
