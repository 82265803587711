// SidebarMenu.js

import React, { useEffect, useState } from "react";
import { gql } from "@apollo/client";
import { client } from "../../../client";

import styled from "styled-components";
import AWS from "aws-sdk";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Box,
} from "@mui/material";
import FileUploaderV1 from "../admin-document/FileUploaderV1";
import { Textarea } from "@mui/joy";
import { useNavigate } from "react-router-dom";

const StyledFormContainer = styled.div`
  max-width: 400px;
  margin: 0 auto;
`;

const CREATE_NOTICE_ADMIN = gql`
  mutation createdNoticeByAdmin(
    $user_id: Int
    $visible: Boolean
    $title: String
    $importance: Importance
    $content: String
    $object_url: String
  ) {
    createdNoticeByAdmin(
      user_id: $user_id
      visible: $visible
      title: $title
      importance: $importance
      content: $content
      object_url: $object_url
    ) {
      message
    }
  }
`;

const CreateNoticeAdmin = () => {
  useEffect(() => {}, []);

  const [selectedFile, setSelectedFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();

  const token = localStorage.getItem("accessToken")?.replace("Bearer ", "");

  const base64Payload = token.split(".")[1];

  const base64 = base64Payload.replace(/-/g, "+").replace(/_/g, "/");

  const decodedJWT = JSON.parse(
    decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    )
  );

  const userId = decodedJWT.id;

  // TODO env 에 넣기
  const ACCESS_KEY = "AKIA2MB36EXBJXFEOVGR";
  const SECRET_ACCESS_KEY = "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";
  const REGION = "ap-northeast-2";
  const S3_BUCKET = "seum-file";

  AWS.config.update({
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const uploadFile = (file) => {
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: "upload/" + file?.name,
    };

    if (selectedFile) {
      myBucket
        .putObject(params)
        .on("httpUploadProgress", (evt) => {
          // setProgress(Math.round((evt.loaded / evt.total) * 100))
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            setSelectedFile(null);
          }, 3000);
        })
        .send((err, data) => {
          if (err) {
            console.error("Error uploading:", err);
          } else {
            const objectUrl = myBucket.getSignedUrl("getObject", {
              Key: params.Key,
            });

            const objectUrlString = objectUrl.split("?")[0];

            setFormData({
              ...formData,
              object_url: objectUrlString,
            });
            alert(`파일업로드가 완료됐습니다. ${objectUrlString}`);
            handleSubmit(objectUrlString);
          }
        });
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = (objectUrlString) => {
    let variables = {
      user_id: userId,
      visible: formData.visible,
      title: formData.title,
      content: formData.content,
      importance: "GENERAL",
      object_url: objectUrlString,
    };

    client
      .mutate({
        mutation: CREATE_NOTICE_ADMIN,
        variables: variables,
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        window.alert("등록되었습니다.");

        navigate("/admin/notice");
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const [formData, setFormData] = useState({
    type: "SUPPORT",
    is_view: 1,
    view_order: null,
    title: null,
    description: null,
    object_url: null,
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    let updatedValue = value;
    if (name === "id") {
      updatedValue = parseInt(value, 10);
    } else if (name === "visible") {
      updatedValue = value === "1";
    }
    setFormData({ ...formData, [name]: updatedValue });
  };

  return (
    <div>
      <h2>공지사항 등록</h2>
      <StyledFormContainer>
        <form>
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel id="visible">노출</InputLabel>

            <Select
              labelId="visible"
              id="visible"
              name="visible"
              value={formData.visible}
              defaultValue={1}
              label="visible"
              onChange={handleChange}
            >
              <MenuItem value={1}>노출</MenuItem>
              <MenuItem value={0}>비노출</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <TextField
              label="제목"
              name="title"
              defaultValue={formData.title}
              onChange={handleChange}
              sx={{ marginBottom: 2 }}
            ></TextField>
          </FormControl>

          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <Textarea
              id="content"
              name="content"
              placeholder="내용을 입력해주세요"
              onChange={handleChange}
              sx={{ marginBottom: 2, height: "250px" }}
            ></Textarea>
          </FormControl>

          <FileUploaderV1
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
          ></FileUploaderV1>

          <Button variant="contained" onClick={() => uploadFile(selectedFile)}>
            업로드
          </Button>
        </form>
      </StyledFormContainer>
    </div>
  );
};

export default CreateNoticeAdmin;
