import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import FileDownloader from "../download/FileDownloader";
import PointUnit from "../../common/PointUnit";
import { client } from "../../../client";
import { GET_USER_INFO } from "../../common/gql/userinfo.gql";
import {
  GET_DOCUMENT_URL,
  GET_DUCUMENT_BY_ID,
  PURCHASE_DOCUMENT,
} from "../page/gql/document.gql";
import FileDownloaderList from "../download/FileDownloaderList";

const ListComponent = ({
  headers,
  columnRatios,
  items,
  isRedownload = false,
  fetchData,
}) => {
  console.log("고등 부교재?", fetchData);
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("accessToken");

  const goToDetailPage = (item) => {
    const { file, downloadUrl, ...itemWithoutFile } = item;
    navigate(`/document/${item.index}`, { state: { item } });
  };

  const promotePayment = async (item) => {
    if (!isRedownload) {
      const confirmDownload = window.confirm(
        "포인트를 이용해 파일을 다운로드 하시겠습니까?"
      );
      if (!confirmDownload) {
        return;
      }
    }

    try {
      const documentRes = await client.query({
        query: GET_DUCUMENT_BY_ID,
        variables: {
          document_id: parseInt(item.index),
        },
      });

      const documentUrl =
        documentRes.data.getDocument.document_url[0].object_url;

      const userRes = await client.query({
        query: GET_USER_INFO,
        context: {
          headers: {
            authorization: accessToken ? `Bearer ${accessToken}` : "",
          },
        },
        fetchPolicy: "no-cache",
      });

      await client
        .mutate({
          mutation: PURCHASE_DOCUMENT,
          variables: {
            user_id: userRes.data.getUserInfo.id,
            document_id: item.index,
            point: item.price,
            is_redownload: isRedownload,
          },
        })
        .then(async (res) => {
          // const response = await fetch(documentUrl);
          // if (!response.ok) throw new Error("서버 응답이 올바르지 않습니다.");
          // const blob = await response.blob();
          // const downloadUrl = window.URL.createObjectURL(blob);

          // // URL에서 파일명 추출 (옵션)
          // const filename = documentUrl.split("/").pop().split("?")[0];

          // // 다운로드 링크 생성 및 클릭
          // const link = document.createElement("a");
          // link.href = downloadUrl;
          // link.setAttribute("download", decodeURIComponent(filename));
          // document.body.appendChild(link);
          // link.click();

          // // 사용 후 다운로드 링크 제거
          // document.body.removeChild(link);
          // window.URL.revokeObjectURL(downloadUrl);

          if (res.data.purchaseDocument === "membership purchase") {
            alert(
              "멤버십으로 구매가 완료 되었습니다. pdf와 한글파일 모두 자유롭게 다운로드 가능합니다"
            );
            fetchData();
            // window.location.reload();
          } else if (res.data.purchaseDocument === "point purchase") {
            alert(
              "포인트로 구매가 완료 되었습니다. pdf와 한글파일 모두 자유롭게 다운로드 가능합니다"
            );
            fetchData();
            // window.location.reload();
          }
        })
        .catch((err) => {
          console.log("err1...", err);
          if (isRedownload) {
            fetchData();
            alert(err);
          } else {
            if (err.message == "DOWNLOAD_LOG_EXISTS") {
              throw err;
            }
            console.error("Error purchasing document", err);
            alert("포인트가 부족합니다.");
            // TODO 포인트가 부족한 경우 직접 구매 모달을 띄운다.
            // input - userId, documentInfo (이름, amount)
            navigate(`/payment/singleDocument/${item.index}`);
          }
        });
    } catch (err) {
      console.log("err2...", err);
      // throw err;
      alert(err.message);
      // if (err.message == "DOWNLOAD_LOG_EXISTS") {
      //   alert("이미 다운로드한 자료입니다.");
      // }
      // console.error("Error fetching user id", err);
      // alert("로그인이 필요합니다.");
    }
  };

  return (
    <ListContainer>
      <Header columns={columnRatios}>
        {headers.map((header, index) => {
          const isCenter =
            header === "다운로드수" ||
            header === "가격" ||
            header === "업로드일" ||
            header === "파일" ||
            header === "자료";
          const style = {
            minWidth: header === "파일" ? "70px" : "auto",
            width: header === "학년" || header === "월" ? "20px" : "auto", // 원하는 너비로 조정
          };
          return (
            <HeaderItem key={index} isCenter={isCenter} style={style}>
              {header}
            </HeaderItem>
          );
        })}
      </Header>
      <List>
        {items?.map((item, index) => (
          <ListItem key={index} columns={columnRatios}>
            {Object.entries(item).map(([key, value], propIndex) => {
              const alignCenter =
                key === "downloaded" ||
                key === "downloadUrl" ||
                key === "created_at";
              const paddingRight = key === "price";

              const style = {
                minWidth: key === "downloadUrl" ? "70px" : "auto",
                width: key === "학년" || key === "월" ? "20px" : "auto", // 원하는 너비로 조정
              };

              if (key === "downloadUrl") {
                return (
                  <ItemProperty
                    key={propIndex}
                    alignCenter={alignCenter}
                    style={style}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        gap: "10px",
                        // justifyContent: "space-between",
                      }}
                    >
                      {value.pdfUrl ? (
                        <FileDownloaderList
                          url={value.pdfUrl}
                          documentId={value.id}
                          point={value.price}
                          isRedownload={true}
                          fetchData={fetchData}
                        >
                          <img src="/images/pdf-icon.png" alt="PDF 다운로드" />
                        </FileDownloaderList>
                      ) : null}
                      {value.hwpUrl ? (
                        <FileDownloaderList
                          url={value.hwpUrl}
                          documentId={value.id}
                          point={value.price}
                          isRedownload={true}
                          fetchData={fetchData}
                        >
                          <img src="/images/hwp-icon.jpeg" alt="HWP 다운로드" />
                        </FileDownloaderList>
                      ) : null}
                    </div>
                    {value.hwpUrl == null && value.pdfUrl == null ? (
                      <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMwAAADACAMAAAB/Pny7AAAAYFBMVEX///8AAADx8fF7e3uJiYldXV1JSUnPz89/f3+MjIy/v79BQUH39/cuLi6oqKgMDAw2Njbh4eEfHx/o6OixsbGgoKBOTk4XFxdmZmaUlJRsbGzFxcXa2tooKChUVFS3t7c3fQqgAAADBElEQVR4nO3dC3aqMBSFYRpRUCOoiPVVO/9Z3vai1geEACFn49rfBJp/LUNCUBoE7qlknn6YpedjD3+4B+pQU1LYSI/TSjS3iplq6YHaiCZWMbGSHqgNxqBiDCrGoGIMKsagYgwqxqBiDCrGoGIMKsagYgwqxqBiDCrGoGIMKsagYgwqxqBiDCrGoGIMKsagYgwqxqBiDCrGoGIMKsagYgwqxqBiDCrGoLKMCd/pN2freYnJbi/TqFbRrMQxX1rFVElFYradxlwtE2jJempZCLQEgd3UaGwmEhP10hKKtARB0kPLQWr10XvnLZOTUMvPxTl2HSP5w/rV2G1LLthi/XN5S9LbtdmXu5az3IS5SD5dtSxkVph7euqoJZWdMAXLV2bUGkHcEqyc7GvmEvvLEicXMZF0xdWxe0si3fCn8y4NY8IUdNitZQkyYQpq16UlhZkwhVOXSxrcm46iReuWEO8EbbNu2TJeSQ/9ld6326UtIV8OpkZtWtZAK8y9rM1JWgy0wjxocZQ2x5v8V813aYCT/2bTsOVbesBGzU6fYunhmukmt2pb6dHWyc7WLVjby1LWp09r+QOMejO7h01pjrrCPMitdmkj3BXmnorr3tb644y8wtxT9UfQyyFMmEJW90FbI5z42arb1+ykB9iI+fRpKT28hkz7ms+hTP4bw60a9vayTPUlTeqBchenihvP3SBW/mfH0n3NdnAT5j+dlDwjBHg+1o6evu5rQA9jLOiXI2jYwxgL6mna7PDvxwxWD7s0wW+TOHH/3aevoU7+m/zWAvFwvKPbt4Wm0iNx4HpJO0gPxIks/F08AR8ptaKOeTIb8ALz5H1KfNLfcVgFbCeVVA40vnyMleEbCWfh0T8xnGNfri+mmLHw6J8YzuOmjJHEGMZ4wBjGeMAYxnjAGMZ4wBjGeMAYxnjAGMZ4wBjGeMAYxnjAGMZ4wBjGeMAYxnjAGMZ4wJg3jdEKiO4Ws4hHQGLDezosYoaDMagYg4oxqBiDijGoGIPqEtPDO6QF/P4/gX8280euK7HuygAAAABJRU5ErkJggg=="
                        width="20px"
                        alt="결제"
                        onClick={() => promotePayment(item)}
                        style={{ cursor: "pointer" }}
                      />
                    ) : null}
                  </ItemProperty>
                );
              } else if (key === "name") {
                return (
                  <ItemProperty
                    key={propIndex}
                    isClickable
                    onClick={() => goToDetailPage(item)}
                    alignCenter={alignCenter}
                  >
                    {value}
                  </ItemProperty>
                );
              } else if (
                key === "docu_type1_name" ||
                key === "docu_type2_name"
              ) {
                return null;
              } else if (key === "price") {
                return (
                  <ItemProperty
                    key={propIndex}
                    alignCenter={alignCenter}
                    paddingRight={paddingRight}
                  >
                    {" "}
                    <PointUnit> {value.toLocaleString()} </PointUnit>{" "}
                  </ItemProperty>
                );
              } else {
                return (
                  <ItemProperty key={propIndex} alignCenter={alignCenter}>
                    {value}
                  </ItemProperty>
                );
              }
            })}
          </ListItem>
        ))}
      </List>
    </ListContainer>
  );
};

export default ListComponent;

const ListContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  overflow: hidden;
  background: white;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ListItem = styled.li`
  display: grid;
  grid-template-columns: ${(props) => props.columns};
  gap: 11px;
  padding: 8px 16px;
  align-items: center;
  border-bottom: 1px solid #eee;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #f9f9f9;
  }

  &:last-child {
    border-bottom: none;
  }
`;

const ItemProperty = styled.span`
  width: 100%;
  margin: 0;
  // padding: 4px;
  text-align: ${({ alignCenter }) => (alignCenter ? "center" : "left")};
  padding-right: ${(paddingRight) => (paddingRight ? "2rem" : "0rem")}
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 2px;

  ${(props) =>
    props.isClickable &&
    `
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`}
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: ${(props) => props.columns};
  text-align: left;
  background: #f5f5f5;
  color: #333;
  font-weight: bold;
  padding: 16px;
  gap: 10px;
`;

const HeaderItem = styled.div`
  text-align: ${({ isCenter }) => (isCenter ? "center" : "left")};
`;
