
import { useSearchParams } from "react-router-dom";
import MyPagePayment from "./MyPagePayment";
import React, { useEffect, useState } from "react";
import { client } from "../../../client";
import { PURCHASE_PRODUCT } from "../../payment/gql/payment.gql";

const MyPage = () => {
  const accessToken = localStorage.getItem("accessToken");
  const [searchParams] = useSearchParams();
  const merchant_uid = searchParams.get("merchant_uid");
  const imp_uid = searchParams.get("imp_uid");


  useEffect(() => {
    // 모바일 환경에서 결제 결과 처리
    if (merchant_uid && imp_uid) {
      try {
        completeOrder(parseInt(merchant_uid), imp_uid);
      } catch (err) {
        throw err;
      }
    }
    // else {
    //   alert("결제 실패 : 결제 정보가 없습니다.");
    // }
  }, [merchant_uid, imp_uid]);

  const completeOrder = async (paymentLogId, imp_uid) => {
    await client
      .mutate({
        mutation: PURCHASE_PRODUCT,
        context: {
          headers: {
            authorization: accessToken ? `Bearer ${accessToken}` : "",
          },
        },
        variables: {
          payment_log_id: paymentLogId,
          imp_uid: imp_uid,
        },
      })
      .then((res) => {
        if(res.data.purchaseProduct == "success") {
          alert("결제 성공");
        } else {
          alert("결제 실패 : 결제가 취소되었습니다.");
        }
      })
      .catch((err) => {
        alert("결제 실패 : 결제가 취소되었습니다.", err);
        console.error("Error completing order", err);
      });
  };

  return (
    <MyPagePayment />
    // <SidebarLayoutMyPage>
    //   <Routes>
    //     {/* <Route path="/" element={<MyPageDownload />} /> */}
    //     <Route path="/" element={<MyPagePayment />} />
    //   </Routes>
    //   <MyPagePayment />
    // </SidebarLayoutMyPage>
  );
};

export default MyPage;
