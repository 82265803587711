import React, { useEffect, useState } from "react";
import { FIND_EMAIL, SEND_SMS, VERIFY_EMAIL } from "./Search.gql";
import { client } from "../../client";
import { useNavigate } from "react-router-dom";
import {
  SignUpContainer,
  Divider,
  Form,
  FormGroup,
  Label,
  Title,
  Input,
  Button,
} from "./Search.module";
import { Box } from "@mui/joy";

const SearchId = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    phone_number: "",
    sms_auth_number: "",
  });

  const [verifyCertificationCode, setVerifyCertificationCode] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");

  const [countdown, setCountdown] = useState(0);
  const [timer, setTimer] = useState(null);
  const [isCounting, setIsCounting] = useState(false);

  const handleChange = (e) => {
    const { id, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const toPassword = () => {
    navigate("/search/password");
  };

  const confirm = (e) => {
    e.preventDefault();

    client
      .mutate({
        mutation: VERIFY_EMAIL,
        variables: {
          phone_number: formData.phone_number,
          sms_auth_number: parseInt(formData.sms_auth_number),
        },
      })
      .then((res) => {
        setVerifyCertificationCode(true);
        window.alert(
          "아이디 찾기에 성공했습니다. 아래 버튼을 눌러 확인해주세요."
        );
        setIsCounting(false);
      })
      .catch((err) => {
        window.alert("올바르지 않은 인증 번호이거나 존재하지 않는 회원입니다.");
        console.error(err);
      });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await client
        .mutate({
          mutation: FIND_EMAIL,
          variables: {
            phone_number: formData.phone_number,
            sms_auth_number: parseInt(formData.sms_auth_number),
          },
        })
        .then((res) => {
          setConfirmed(true);
          setEmailAddress(res.data.findEmailBySMSAuthNumber.info);
        });
    } catch (err) {
      window.alert("존재하지 않는 아이디거나 인증번호가 잘못되었습니다.");
      setFormData({
        phone_number: "",
        sms_auth_number: "",
      });
      console.error(err);
    }
  };

  const sendSMS = async (e) => {
    e.preventDefault();

    clearInterval(timer);

    await client
      .mutate({
        mutation: SEND_SMS,
        variables: {
          phone_number: formData.phone_number,
        },
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        window.alert("인증번호가 발송되었습니다.");
        const newTimer = startCountdown();
        setTimer(newTimer);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const startCountdown = () => {
    setCountdown(600);
    setIsCounting(true);
    const newTimer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);
    return newTimer;
  };

  useEffect(() => {
    if (countdown === 0) {
      setIsCounting(false);
    }
  }, [countdown]);

  return (
    <div>
      <SignUpContainer>
        <Title>아이디 찾기</Title>
        <Divider disabled={!verifyCertificationCode} />
        {confirmed === false ? (
          <Form>
            <FormGroup>
              <Label htmlFor="phone_number">전화번호</Label>
              <Input
                id="phone_number"
                value={formData.phone_number}
                onChange={handleChange}
                placeholder="-를 제외한 숫자만 입력"
                maxLength={11}
              />
              {isCounting === false ? (
                <Button onClick={sendSMS}>인증번호 발송</Button>
              ) : (
                <Button onClick={sendSMS}>{countdown}초 남음</Button>
              )}
            </FormGroup>

            <FormGroup>
              <Label>인증번호</Label>
              <Input
                id="sms_auth_number"
                value={formData.sms_auth_number}
                onChange={handleChange}
                placeholder="6자리 숫자 입력"
                maxLength={6}
              />
              <Button onClick={confirm}>인증번호 확인</Button>
            </FormGroup>

            <Button onClick={handleSubmit} disabled={!verifyCertificationCode}>
              아이디 확인
            </Button>
          </Form>
        ) : (
          <Form>
            <Box
              height={300}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {emailAddress}
            </Box>

            <Button onClick={toPassword}>비밀번호 찾기</Button>
          </Form>
        )}
      </SignUpContainer>
    </div>
  );
};

export default SearchId;
