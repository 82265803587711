// SidebarMenu.js

import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { client } from "../../../client";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import BannerModal from "./BannerModal";

import { Box, InputBase, Typography } from "@mui/material";

const GET_BANNER_LIST = gql`
  query getBannerImageListByAdmin {
    getBannerImageListByAdmin {
      id
      title
      image_url
      visible
    }
  }
`;

const BannerPage = () => {
  const [ bannerList, setBannerList ] = useState([]);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const fetchData = async () => {
    await client
      .query({
        query: GET_BANNER_LIST,
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        setBannerList(res.data.getBannerImageListByAdmin);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  // const userList = data.getUserListByAdmin;
  const handleEditClick = (item) => {
    setSelectedBanner(item);
    setModalOpen(!isModalOpen);
  };

  const handleCloseClick = () => {
    setModalOpen(false);
  };

  return (
    <div>
      <Box>
        <h2>배너 관리</h2>
      </Box>
      <Table sx={{ minWidth: 700 }}>
        <TableHead>
          <TableRow>
            <TableCell>id</TableCell>
            <TableCell  sx={{width: "50px"}} align="right">이름</TableCell>
            <TableCell sx={{width: "150px"}}  align="right">이미지</TableCell>
            {/* <TableCell align="right">노출여부</TableCell> */}
            <TableCell align="right">수정</TableCell>
            <TableCell align="right">삭제</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bannerList.map((banner) => (
            <TableRow key={banner.id}>
              <TableCell component="th" scope="row">
                {banner.id}
              </TableCell>
              <TableCell component="th" scope="row">
                <div style={{ width: "100px" }}>{banner.title}</div>
              </TableCell>
              <TableCell align="right">
                <img src={banner.image_url} width="600px"></img>
              </TableCell>
              {/* <TableCell align="right">
                {banner.visible == 1 ? '노출' : '비노출'}
              </TableCell> */}
              <TableCell align="right">
                <Button onClick={() => handleEditClick(banner)}>수정</Button>
              </TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Modal for Edit */}
      <BannerModal 
        isOpen={isModalOpen}
        handleCloseClick={handleCloseClick} 
        refetchParentData={fetchData} 
        data = {selectedBanner}>
      </BannerModal>
    </div>
  );
};

export default BannerPage;
