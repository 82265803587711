// SidebarMenu.js

import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { client } from "../../../client";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styled from "styled-components";
import AWS from "aws-sdk";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
} from "@mui/material";
import FileUploader from "./FileUploader";

const StyledFormContainer = styled.div`
  max-width: 400px;
  margin: 0 auto;
`;

const UPLOAD_DOCUMENT = gql`
  mutation uploadDocument($document_upload_input: document_upload_input) {
    uploadDocument(document_upload_input: $document_upload_input) {
      status
    }
  }
`;

const DocuUploadPage = () => {
  useEffect(() => {}, []);

  // 파일 업로드 파트
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFiles2, setSelectedFiles2] = useState([]);
  const [progress, setProgress] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  // TODO env 에 넣기
  const ACCESS_KEY = "AKIA2MB36EXBJXFEOVGR";
  const SECRET_ACCESS_KEY = "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";
  const REGION = "ap-northeast-2";
  const S3_BUCKET = "seum-file";

  AWS.config.update({
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const uploadFile = (file) => {
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: "upload/" + file?.name,
    };

    return new Promise((resolve, reject) => {
      myBucket
        .putObject(params)
        .on("httpUploadProgress", (evt) => {
          console.log("Upload Progress:", evt);
          setShowAlert(true);
        })
        .send((err, data) => {
          if (err) {
            console.error("Error uploading:", err);
            reject(err);
          } else {
            console.log("Upload successful:", data);
            const objectUrl = myBucket.getSignedUrl("getObject", {
              Key: params.Key,
            });
            console.log("Object URL:", objectUrl.split("?")[0]);
            resolve(objectUrl.split("?")[0]);
          }
        });
    });
  };

  const uploadDocument = async (uploadedUrls) => {
    return client.mutate({
      mutation: UPLOAD_DOCUMENT,
      variables: {
        document_upload_input: {
          ...formData,
          year: parseInt(formData.year),
          month_id: parseInt(formData.month_id),
          document_url_v2: uploadedUrls,
        },
      },
      fetchPolicy: "no-cache",
    });

    // return console.log("document_url_v2 :", documentUrlV2);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // pdf
      const uploadPromises = selectedFiles.map((file) => uploadFile(file));
      const uploadedUrls = await Promise.all(uploadPromises);
      // 한글
      const uploadPromises2 = selectedFiles2.map((file) => uploadFile(file));
      const uploadedUrls2 = await Promise.all(uploadPromises2);

      console.log("uploadedUrls :", uploadedUrls);
      console.log("uploadedUrls2 :", uploadedUrls2);

      const urlResult = [];
      if (uploadedUrls.length > 0) {
        const pdfUrl = uploadedUrls[0];
        urlResult.push({ type: "PDF", object_url: pdfUrl });
      }
      if (uploadedUrls2.length > 0) {
        const hwpUrl = uploadedUrls2[0];
        urlResult.push({ type: "HWP", object_url: hwpUrl });
      }

      try {
        const res = await uploadDocument(urlResult);
        console.log(res);
        alert("파일 업로드가 완료됐습니다.");
        setShowAlert(false);
        setSelectedFiles([]);
      } catch (err) {
        console.log(err);
        alert("파일 업로드 중 오류가 발생했습니다.");
      }
    } catch (err) {
      console.error("Error during upload process:", err);
    }
  };

  const [formData, setFormData] = useState({
    docu_type1_id: 1,
    docu_type2_id: null,
    name: null,
    price: null,
    grade: null,
    year: null,
    subject: null,
    publisher_id: null,
    category1_id: null,
    category2_id: null,
    month_id: null,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  //   const handleSubmit = (event) => {
  //     event.preventDefault();
  //     // 여기서 업로드 로직을 추가할 수 있습니다.
  //     // 업로드 로직을 호출하거나 상태를 초기화하거나 다른 작업을 수행할 수 있습니다.
  //   };

  // const userList = data.getUserListByAdmin;
  // const handleEditClick = (document) => {
  //   setSelectedDocument(document);
  //   setModalOpen(!isModalOpen);
  // };

  // const handleCloseClick = () => {
  //   setModalOpen(false);
  // }

  return (
    <div>
      <h2>자료 업로드</h2>
      {/* <div> {JSON.stringify(formData)} </div>
      <div> {JSON.stringify(selectedFile)} </div> */}
      <StyledFormContainer>
        {/* 필드리스트 */}
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel id="docu_type1_id-label">대분류</InputLabel>
            <Select
              labelId="docu_type1_id-label"
              id="docu_type1_id"
              name="docu_type1_id"
              value={formData.docu_type1_id}
              defaultValue={1}
              label="Document Type 1"
              onChange={handleChange}
            >
              {/* 여기에 옵션들을 추가하세요 */}
              <MenuItem value={1}>모의고사/학평</MenuItem>
              <MenuItem value={2}>고등 부교재 (EBS 교재)</MenuItem>
              <MenuItem value={3}>수능연계교재 (수능특강, 수능완성)</MenuItem>
              <MenuItem value={4}>고등교과서</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel id="docu_type2_id-label">중분류</InputLabel>
            <Select
              labelId="docu_type2_id-label"
              id="docu_type2_id"
              name="docu_type2_id"
              value={formData.docu_type2_id}
              defaultValue={1}
              label="Document Type 2"
              onChange={handleChange}
            >
              {/* 여기에 옵션들을 추가하세요 */}
              <MenuItem value={1}>수업용자료</MenuItem>
              <MenuItem value={2}>워크북</MenuItem>
              <MenuItem value={3}>변형문제</MenuItem>
              <MenuItem value={4}>패키지</MenuItem>
            </Select>
          </FormControl>

          {/* 나머지 입력 필드들 */}
          <TextField
            fullWidth
            label="자료명"
            name="name"
            value={formData.name}
            onChange={handleChange}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            fullWidth
            label="가격"
            name="price"
            value={formData.price}
            onChange={handleChange}
            sx={{ marginBottom: 2 }}
          />
          {formData.docu_type1_id == "1" ? (
            <div>
              <TextField
                fullWidth
                label="학년"
                name="grade"
                value={formData.grade}
                onChange={handleChange}
                sx={{ marginBottom: 2 }}
              />
              <TextField
                fullWidth
                label="시행 연도"
                name="year"
                value={formData.year}
                onChange={handleChange}
                sx={{ marginBottom: 2 }}
              />
              <TextField
                fullWidth
                label="시행 월"
                name="month_id"
                value={formData.month_id}
                onChange={handleChange}
                sx={{ marginBottom: 2 }}
              />
            </div>
          ) : null}
          {formData.docu_type1_id == "4" ? (
            <div>
              <TextField
                fullWidth
                label="과목"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                sx={{ marginBottom: 2 }}
              />
              {/* <TextField
                fullWidth
                label="과목출판사"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                sx={{ marginBottom: 2 }}
              /> */}

              <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <InputLabel id="docu_type2_id-label">출판사</InputLabel>
                <Select
                  labelId="docu_type2_id-label"
                  id="publisher_id"
                  name="publisher_id"
                  value={formData.docu_type2_id}
                  defaultValue={1}
                  label="출판사"
                  onChange={handleChange}
                >
                  {/* 여기에 옵션들을 추가하세요 */}
                  <MenuItem value={1}>교학사</MenuItem>
                  <MenuItem value={2}>진학사</MenuItem>
                </Select>
              </FormControl>
            </div>
          ) : null}

          {formData.docu_type1_id == "2" ? (
            <div>
              <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <InputLabel id="docu_type2_id-label">분류 1</InputLabel>
                <Select
                  labelId="docu_type2_id-label"
                  id="category1_id"
                  name="category1_id"
                  value={formData.category1_id}
                  defaultValue={1}
                  label="분류 1"
                  onChange={handleChange}
                >
                  {/* 여기에 옵션들을 추가하세요 */}
                  {/* # id	name	docu_type1_id
                  1	리딩파워	2
                  2	올림포스	2
                  3	수능특강 LIGHT	2
                  4	올림포스 전국연합 기출 영어 독해	2
                  5	수능특강 영어	3
                  6	수능특강 영어독해	3
                  7	수능완성	3 */}
                  <MenuItem value={1}>리딩파워</MenuItem>
                  <MenuItem value={2}>올림포스</MenuItem>
                  <MenuItem value={3}>수능특강 LIGHT</MenuItem>
                  <MenuItem value={4}>
                    올림포스 전국연합 기출 영어 독해
                  </MenuItem>
                  {/* </> : 
                    <>
                    </>  */}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <InputLabel id="docu_type2_id-label">분류 2</InputLabel>
                {formData.category1_id == 1 ? (
                  <Select
                    labelId="docu_type2_id-label"
                    id="docu_type2category2_id_id"
                    name="category2_id"
                    value={formData.category2_id}
                    defaultValue={1}
                    label="분류 2"
                    onChange={handleChange}
                  >
                    {/* 여기에 옵션들을 추가하세요 */}
                    <MenuItem value={1}>리딩파워 유형편 기본</MenuItem>
                    <MenuItem value={2}>리딩파워 유형편 완성</MenuItem>
                    <MenuItem value={3}>리딩파워 주제별독해완성</MenuItem>
                  </Select>
                ) : formData.category1_id == 2 ? (
                  <Select
                    labelId="docu_type2_id-label"
                    id="docu_type2category2_id_id"
                    name="category2_id"
                    value={formData.category2_id}
                    defaultValue={1}
                    label="분류 2"
                    onChange={handleChange}
                  >
                    {/* 여기에 옵션들을 추가하세요 */}
                    <MenuItem value={4}>올림포스1</MenuItem>
                    <MenuItem value={5}>올림포스2</MenuItem>
                  </Select>
                ) : formData.category1_id == 3 ? (
                  <Select
                    labelId="docu_type2_id-label"
                    id="docu_type2category2_id_id"
                    name="category2_id"
                    value={formData.category2_id}
                    defaultValue={1}
                    label="분류 2"
                    onChange={handleChange}
                  >
                    {/* 여기에 옵션들을 추가하세요 */}
                    <MenuItem value={6}>수능특강 LIGHT 영어편</MenuItem>
                    <MenuItem value={7}>수능특강 LIGHT 영어독해편</MenuItem>
                  </Select>
                ) : formData.category1_id == 4 ? (
                  <Select
                    labelId="docu_type2_id-label"
                    id="docu_type2category2_id_id"
                    name="category2_id"
                    value={formData.category2_id}
                    defaultValue={1}
                    label="분류 2"
                    onChange={handleChange}
                  >
                    {/* 여기에 옵션들을 추가하세요 */}
                    <MenuItem value={8}>고1</MenuItem>
                    <MenuItem value={9}>고2</MenuItem>
                  </Select>
                ) : null}
              </FormControl>
            </div>
          ) : null}

          {formData.docu_type1_id == "3" ? (
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel id="docu_type2_id-label">분류 1</InputLabel>
              <Select
                labelId="docu_type2_id-label"
                id="category1_id"
                name="category1_id"
                value={formData.category1_id}
                defaultValue={1}
                label="분류 1"
                onChange={handleChange}
              >
                {/* 여기에 옵션들을 추가하세요 */}
                {/* # id	name	docu_type1_id
                  1	리딩파워	2
                  2	올림포스	2
                  3	수능특강 LIGHT	2
                  4	올림포스 전국연합 기출 영어 독해	2
                  5	수능특강 영어	3
                  6	수능특강 영어독해	3
                  7	수능완성	3 */}
                <MenuItem value={5}>수능특강 영어</MenuItem>
                <MenuItem value={6}>수능특강 영어독해</MenuItem>
                <MenuItem value={7}>수능완성</MenuItem>
              </Select>
            </FormControl>
          ) : null}

          <div>pdf</div>
          <FileUploader
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
          ></FileUploader>
          <br />
          <div>hwp</div>
          <FileUploader
            selectedFiles={selectedFiles2}
            setSelectedFiles={setSelectedFiles2}
          ></FileUploader>
          <br />

          <Button variant="contained" type="submit">
            업로드
          </Button>
        </form>
      </StyledFormContainer>
    </div>
  );
};

export default DocuUploadPage;
