import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { client } from "../../client";
import { GET_USER_INFO } from "./gql/userinfo.gql";
import { HAS_MEMBERSHIP_NOW } from "../payment/gql/payment.gql";
import DrawerBasic from "../admin/common/Drawer";
import { Box } from "@mui/material";

const Header = () => {
  const accessToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState("");
  const [userPoint, setUserPoint] = useState(0);
  const [userId, setUserId] = useState(0);
  const [hasMembership, setHasMembership] = useState(false);
  const [isAdmin, setIsAdmin] = useState(0);

  const handleHomeClick = () => {
    navigate("/");
  };

  const handleSignUpClick = () => {
    navigate("/signup");
  };

  const handleLoginClick = () => {
    navigate("/login");
  };

  const imgStyle = {
    maxWidth: "100%",
    height: "auto",
  };

  const handleMyPageClick = () => {
    navigate("/mypage");
  };

  const handleLogoutClick = () => {
    setIsLoggedIn(false);
    localStorage.setItem("isLoggedIn", false);
    localStorage.removeItem("accessToken");
    navigate("/login");
  };

  useEffect(() => {
    setIsLoggedIn(localStorage.getItem("isLoggedIn") === "true");

    client
      .query({
        query: GET_USER_INFO,
        context: {
          headers: {
            authorization: accessToken ? `Bearer ${accessToken}` : "",
          },
        },
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        console.log(result);
        setUserName(result.data.getUserInfo.name);
        setUserPoint(result.data.getUserInfo.current_point);
        setUserId(result.data.getUserInfo.id);
        setIsAdmin(result.data.getUserInfo.isAdmin);
      })
      .catch((error) => {
        console.log(error);
        if (error.message.includes("Unauthorized") && isLoggedIn) {
          localStorage.setItem("isLoggedIn", false);
          setIsLoggedIn(false);
          alert("로그인이 필요합니다.");
          navigate("/login");
        }
      });

    client
      .query({
        query: HAS_MEMBERSHIP_NOW,
        variables: {
          user_id: userId,
        },
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        console.log("hasMembershipNow", res);
        setHasMembership(res.data.hasMembershipNow);
      })
      .catch((err) => {
        console.error("Error fetching membership info", err);
      });
  }, [isLoggedIn, userName, accessToken]);

  return (
    <HeaderContainer>
      <Title onClick={handleHomeClick}>
        <ImgContainer>
          <img
            style={imgStyle}
            src="https://dfx-img.s3.ap-northeast-2.amazonaws.com/KakaoTalk_Photo_2024-02-27-01-20-09+(1).jpeg"
          ></img>
        </ImgContainer>
      </Title>
      <Spacer />
      <Nav>
        <NavLink href="/">홈</NavLink>
        <NavLink href="/search/mock-tests">문제 검색</NavLink>
        <NavLink href="/payment">이용권 구매</NavLink>
        <NavLink href="/customer/faq">고객센터</NavLink>
        {isLoggedIn && isAdmin ? <DrawerBasic>관리자페이지</DrawerBasic> : null}
      </Nav>
      <div>
        {isLoggedIn ? (
          <Box display={"flex"} alignItems={"center"}>
            <Username>
              {userName}님 ({/* {userPoint.toLocaleString()}{" "} */}
              {userPoint?.toLocaleString()} )
            </Username>
            <LogoutButton onClick={handleMyPageClick}>마이페이지</LogoutButton>
            <LogoutButton onClick={handleLogoutClick}>로그아웃</LogoutButton>
            <UserInfoContainer />
          </Box>
        ) : (
          <>
            <Button onClick={handleSignUpClick}>회원가입</Button>
            <Button onClick={handleLoginClick}>로그인</Button>
          </>
        )}
      </div>
    </HeaderContainer>
  );
};

export default Header;

const LoginInfoContainer = styled.div`
  flex: row;
  width: 40%;
`;

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 2rem;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 95vw;
`;

const Spacer = styled.div`
  flex: 0.1;
`;

const Title = styled.h1`
  cursor: pointer;
  font-size: ${(props) => props.theme.fontSize.bigtitle};
  color: ${(props) => props.theme.colors.accent};
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  margin-right: auto;
`;

const NavLink = styled.a`
  margin: 0 1rem;
  text-decoration: none;
  color: #000;
  font-size: ${(props) => props.theme.fontSize.title};
  font-weight: 700;

  &:hover {
    color: #0064ff;
    font-weight: 700;
  }
`;

const ImgContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 200px;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  margin-left: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: ${(props) => props.theme.fontSize.subtitle};
  font-weight: 500;
  transition: background-color 0.3s, color 0.3s;

  &:first-of-type {
    background: ${(props) => props.theme.colors.accent};
    color: #fff;
    &:hover {
      background: ${(props) => props.theme.colors.accentHover};
    }
  }
  &:last-of-type {
    background: transparent;
    color: #000;
    &:hover {
      color: ${(props) => props.theme.colors.accent};
    }
  }
`;

const Username = styled.span`
  color: #000;
  margin-right: 1rem;
  font-weight: 500;
`;

const LogoutButton = styled.button`
  width: 115px;
  padding: 0.5rem 0.5rem;
  margin-left: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  background: ${(props) => props.theme.colors.accent};
  font-size: ${(props) => props.theme.fontSize.subtitle};
  color: #fff;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background: ${(props) => props.theme.colors.accentHover};
    color: #fff;
  }
`;

const MembershipDivide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background-color: #f6c90e;
  color: #fff;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 700;
  margin-left: 5px;
  margin-top: 2px;
  margin-bottom: 2px;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
`;

const UserInfoContainer = styled.div`
  display: flex;
  align-items: center; // Align items vertically in the center
  justify-content: space-between;
  width: auto;
`;
