import React, { useState, useEffect } from "react";
import * as S from "../Filter.style";
import ListComponent from "../pagenation/List";
import PaginationComponent from "../pagenation/PagenationComponent";

import { client } from "../../../client";
import {
  GET_ALL_DOCUTYPE,
  GET_DOCUMENT_URL,
  GET_FILTER_DATA,
} from "./gql/document.gql";

const ITEMS_PER_PAGE = 10;
const MAX_PAGES = 5;

const HighschoolTextbook = () => {
  const [documentData, setDocumentData] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [selectedPublishers, setSelectedPublishers] = useState([]);
  const [selectedDocumentTypes, setSelectedDocumentTypes] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const headers = [
    "번호",
    "과목",
    "교과서",
    "자료",
    "업로드일",
    "다운로드수",
    "가격",
    "파일",
  ];
  const columnRatios = "0.1fr 0.2fr 0.25fr 0.6fr 0.2fr 0.15fr 0.2fr 0.1fr";

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSet, setPageSet] = useState(1);
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;

  const [subjects, setSubjects] = useState([]);
  const [publishers, setPublishers] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);

  const getFilter = () => {
    setIsLoading(true);
    client
      .query({
        query: GET_FILTER_DATA,
        variables: {
          docu_type1_id: 4,
        },
      })
      .then((res) => {
        const fetchedData = JSON.parse(res.data.getFilterListByDocumentType1);
        const subjectData = fetchedData.filterList.find(
          (f) => f.col_name === "subject"
        ).value;
        const publisherData = fetchedData.filterList.find(
          (f) => f.col_name === "publisher"
        ).value;
        const documentTypeData = fetchedData.filterList.find(
          (f) => f.col_name === "docu_type2"
        ).value;
        setSubjects(subjectData);
        setPublishers(publisherData);
        setDocumentTypes(documentTypeData);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const fetchData = () => {
    client
      .query({
        query: GET_ALL_DOCUTYPE,
        variables: {
          document_search_input: {
            docu_type1_id: 4,
            docu_type2_id: selectedDocumentTypes,
            subject: selectedSubjects,
            publisher_id: selectedPublishers,
            name: searchTerm,
            page: 1,
            pageSize: 300,
          },
          fetchPolicy: "no-cache",
        },
      })
      .then((res) => {
        const documents = res.data.searchDocuTypeV2.map(async (item, index) => {
          const {
            id,
            subject,
            publisher,
            name,
            created_at,
            downloaded,
            price,
            documentUrlList,
          } = item;
          const date = new Date(parseInt(created_at, 10));
          const formattedDate = date.toISOString().split("T")[0];

          try {
            // const response = await client.query({
            //   query: GET_DOCUMENT_URL,
            //   variables: { document_id: id, type: "PDF" },
            //   fetchPolicy: "no-cache",
            // });
            // const url = response.data.getDocumentUrlByType;
            const urls = documentUrlList;
            let pdfUrl;
            let hwpUrl;

            urls.map((item) => {
              item.type === "HWP"
                ? (hwpUrl = item.object_url)
                : (pdfUrl = item.object_url);
            });
            return {
              index: id,
              subject,
              publisher,
              name,
              created_at: formattedDate,
              downloaded,
              price,
              downloadUrl: {
                pdfUrl,
                hwpUrl,
                id,
                price,
              },
            };
          } catch (err) {
            console.log(err);
            return {
              index: id,
              subject,
              publisher,
              name,
              created_at: formattedDate,
              downloaded,
              price,
            };
          }
        });
        Promise.all(documents).then(setDocumentData).catch(console.error);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getFilter();
    fetchData();
  }, []);

  const currentItems = documentData.slice(indexOfFirstItem, indexOfLastItem);

  const handleSearch = (event) => {
    event.preventDefault();
    fetchData();
  };

  const handleSubjectChange = (subject) => (event) => {
    if (event.target.checked) {
      setSelectedSubjects([...selectedSubjects, subject]);
    } else {
      setSelectedSubjects(selectedSubjects.filter((item) => item !== subject));
    }
  };

  const handlePublisherChange = (id) => (event) => {
    if (event.target.checked) {
      setSelectedPublishers([...selectedPublishers, id]);
    } else {
      setSelectedPublishers(selectedPublishers.filter((item) => item !== id));
    }
  };

  const handleDocumentTypeChange = (id) => (event) => {
    if (event.target.checked) {
      setSelectedDocumentTypes([...selectedDocumentTypes, id]);
    } else {
      setSelectedDocumentTypes(
        selectedDocumentTypes.filter((item) => item !== id)
      );
    }
  };

  return (
    <S.Wrapper>
      <S.Container>
        <S.FilterContainer>
          <S.FilterSection>
            <S.FilterTitle>과목</S.FilterTitle>
            {subjects.map((subject) => (
              <S.FilterCheckboxLabel key={subject}>
                <S.FilterCheckbox
                  type="checkbox"
                  value={subject}
                  checked={selectedSubjects.includes(subject)}
                  onChange={handleSubjectChange(subject)}
                />
                {subject}
              </S.FilterCheckboxLabel>
            ))}
          </S.FilterSection>
          <S.FilterSection>
            <S.FilterTitle>출판사</S.FilterTitle>
            {publishers.map((publisher) => (
              <S.FilterCheckboxLabel key={publisher.id}>
                <S.FilterCheckbox
                  type="checkbox"
                  value={publisher.name}
                  checked={selectedPublishers.includes(publisher.id)}
                  onChange={handlePublisherChange(publisher.id)}
                />
                {publisher.name}
              </S.FilterCheckboxLabel>
            ))}
          </S.FilterSection>
          <S.FilterSection>
            <S.FilterTitle>자료유형</S.FilterTitle>
            {documentTypes.map((type) => (
              <S.FilterCheckboxLabel key={type.id}>
                <S.FilterCheckbox
                  type="checkbox"
                  value={type.name}
                  checked={selectedDocumentTypes.includes(type.id)}
                  onChange={handleDocumentTypeChange(type.id)}
                />
                {type.name}
              </S.FilterCheckboxLabel>
            ))}
          </S.FilterSection>
        </S.FilterContainer>
        <S.SearchContainer as="form" onSubmit={handleSearch}>
          <S.Input
            type="text"
            placeholder="검색어를 입력하세요"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <S.SearchButton type="submit">검색</S.SearchButton>
        </S.SearchContainer>
      </S.Container>
      <ListComponent
        headers={headers}
        columnRatios={columnRatios}
        items={currentItems}
        fetchData={fetchData}
      />
      <PaginationComponent
        data={documentData}
        currentPage={currentPage}
        setPage={setCurrentPage}
        pageSet={pageSet}
        setPageSet={setPageSet}
        itemsPerPage={ITEMS_PER_PAGE}
        maxPages={MAX_PAGES}
      />
    </S.Wrapper>
  );
};

export default HighschoolTextbook;
