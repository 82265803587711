import styled from "styled-components";

export const Title = styled.h3`
  text-align: center;
  margin-bottom: 2rem; // More space below the title
`;

export const SignUpContainer = styled.div`
  max-width: 500px;
  margin: 2rem auto;
  padding: 2rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: #fff;
`;

export const Divider = styled.hr`
  border: none;
  height: 2px;
  background-color: ${(props) =>
    props.disabled ? "#ddd" : props.theme.colors.accent};
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const FormGroup = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const Label = styled.label`
  margin-right: 1rem;
  flex-basis: 100px;
  text-align: left;
`;

export const Input = styled.input`
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  flex: 1;
  margin-right: 0.5rem;
`;

export const Button = styled.button`
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  background-color: ${(props) =>
    props.disabled ? "#ddd" : props.theme.colors.accent};
  color: white;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  transition: transform 0.1s ease;
  &:active {
    transform: translateY(2px);
  }
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const WarningText = styled.span`
  color: red;
  font-size: 0.6rem;
`;

export const CorrectText = styled.span`
  color: green;
  font-size: 0.6rem;
`;
