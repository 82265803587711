import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import styled from "styled-components";
import Button from "@mui/material/Button";
import { gql } from "@apollo/client";
import { client } from "../../../client";

import AWS from "aws-sdk";
import FileUploader from "../admin-document/FileUploader";
import { TextField } from "@mui/material";

const ModalContainer = styled(Box)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  background-color: white; /* Change to white background */
  padding: 20px;
  z-index: 100;
  border: 1px solid #ccc; /* Add a subtle border */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
`;

const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center; /* Center-align all children */
  margin-top: 30px;
  margin-bottom: 30px;
`;

const UserInfoItem = styled.div`
  display: flex; /* Display children in a row */
  flex-direction: row;
  margin-bottom: 10px; /* Add margin between child components */
`;

const NoticeText = styled.textarea`
  width: 200px;
  height: 125px;
`;

const ModifyErrorAdmin = ({
  isOpen,
  data,
  handleCloseClick,
  refetchParentData,
  children,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [showAlert, setShowAlert] = useState(false);

  // TODO env 에 넣기
  const ACCESS_KEY = "AKIA2MB36EXBJXFEOVGR";
  const SECRET_ACCESS_KEY = "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";
  const REGION = "ap-northeast-2";
  const S3_BUCKET = "seum-file";

  AWS.config.update({
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const uploadFile = (file) => {
    console.log("uploadFile...!", file);
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: "upload/" + file?.name,
    };

    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        console.log("evt...", evt);
        setProgress(Math.round((evt.loaded / evt.total) * 100));
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
          setSelectedFile(null);
        }, 3000);
      })
      .send((err, data) => {
        if (err) {
          console.error("Error uploading:", err);
        } else {
          console.log("Upload successful:", data);
          const objectUrl = myBucket.getSignedUrl("getObject", {
            Key: params.Key,
          });

          const objectUrlString = objectUrl.split("?")[0];

          handleErrorClick(objectUrlString);

          alert(`수정이 완료되었습니다.`);

          window.location.reload();
        }
      });
  };

  const [editableData, setEditableData] = useState({});
  useEffect(() => {
    setEditableData({
      id: data?.id,
      user_id: data?.user_id,
      type: data?.type,
      state: data?.state,
      title: data?.title,
      reply: data?.reply,
      content: data?.content,
      object_url: data?.object_url,
    });
  }, [data]);

  const handleInputChange = (key, value) => {
    setEditableData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleErrorClick = (objectUrlString) => {
    const MODIFY_ERROR_ADMIN = gql`
      mutation modifyErrorReport(
        $id: Int!
        $user_id: Int!
        $type: reportTypeEnum!
        $state: Int
        $title: String!
        $content: String
        $object_url: String
        $reply: String
      ) {
        modifyErrorReport(
          id: $id
          user_id: $user_id
          type: $type
          state: $state
          title: $title
          content: $content
          object_url: $object_url
          reply: $reply
        ) {
          message
        }
      }
    `;

    let variables = {
      id: editableData.id,
      user_id: editableData.user_id,
      type: editableData.type,
      state: 3,
      title: editableData.title,
      content: editableData.content,
      reply: editableData.reply,
    };

    if (selectedFile) {
      variables.object_url = objectUrlString;
    }

    client
      .mutate({
        mutation: MODIFY_ERROR_ADMIN,
        variables: variables,
      })
      .then((res) => {
        console.log("update");
        console.log(res);
        refetchParentData();
        handleCloseClick();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updatedTime = (params) => {
    const date = new Date(parseInt(params));
    return date.toLocaleString("ko-kr");
  };

  if (!isOpen) return null;

  const excludeKeys = [
    "__typename",
    "created_at",
    "updated_at",
    "isResponded",
    "state",
  ];

  return (
    <ModalContainer>
      <h2>답변하기</h2>

      <UserInfoContainer
        style={{ height: "100%", overflowY: "auto", maxHeight: "400px" }}
      >
        {Object.keys(data).map((key) => {
          if (excludeKeys.includes(key)) return null;
          return (
            <div key={key}>
              {key === "object_url" ||
              key === "created_at" ||
              key === "updated_at" ||
              key === "reply" ||
              key === "visible" ? (
                <UserInfoItem style={{ width: "100%" }}>
                  <div>
                    <Box mr={"5px"}>{key}: </Box>
                  </div>
                  <div>
                    {key === "visible" ? (
                      <select
                        value={editableData[key] ? "1" : "0"}
                        onChange={(e) => handleInputChange(key, e.target.value)}
                      >
                        <option value={"1"}>노출</option>
                        <option value={"0"}>비노출</option>
                      </select>
                    ) : key === "updated_at" || key === "created_at" ? (
                      <Box>{updatedTime(data[key])}</Box>
                    ) : key === "object_url" ? (
                      <div>
                        <Box
                          display={"flex"}
                          ml={"10px"}
                          maxHeight={"500px"}
                          maxWidth={"600px"}
                        >
                          <img
                            src={data.object_url}
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                              objectFit: "contain",
                            }}
                          />
                        </Box>
                      </div>
                    ) : key === "content" ? (
                      <Box>
                        <NoticeText
                          type="text"
                          value={editableData[key]}
                          onChange={(e) =>
                            handleInputChange(key, e.target.value)
                          }
                        />
                      </Box>
                    ) : key === "reply" ? (
                      <Box>
                        <NoticeText
                          type="text"
                          value={editableData[key]}
                          onChange={(e) =>
                            handleInputChange(key, e.target.value)
                          }
                        />
                      </Box>
                    ) : (
                      <input
                        type="text"
                        value={editableData[key]}
                        onChange={(e) => handleInputChange(key, e.target.value)}
                      />
                    )}
                  </div>
                </UserInfoItem>
              ) : (
                <UserInfoItem>
                  {key} : {data[key]}
                </UserInfoItem>
              )}
            </div>
          );
        })}
      </UserInfoContainer>

      <Button onClick={() => uploadFile(selectedFile)}>저장</Button>

      <Button onClick={() => handleCloseClick()}> 닫기 </Button>
    </ModalContainer>
  );
};

export default ModifyErrorAdmin;
