import React, { useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { gql } from "@apollo/client";
import { client } from "../../client";

const PointUnit = ({ children }) => {

  return (
    <PointUnitContainer>
        {children} <UnitIcon>P</UnitIcon>
    </PointUnitContainer>
  );
};

export default PointUnit;

const PointUnitContainer = styled.header`
  font-weight: 500;
  font-size: 0.8rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end; // 우측 정렬을 위한 속성 추가
  justify-content: center;
  align-items: center;
  
`;


const UnitIcon = styled.div`
  font-weight: 800;
  margin-left: 0.2rem;
  font-size: 1rem;
  color: #0064FF;

  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

`

