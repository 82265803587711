// ModalComponent.js
import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import styled from "styled-components";
import Button from "@mui/material/Button";
import { gql } from "@apollo/client";
import { client } from "../../../client";
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
} from "@mui/material";
import UserPendingPayment from "./UserPendingPayment";
import UserPayment from "./UserPayment";

const ModalContainer = styled(Box)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 70%;
  background-color: white;
  padding: 20px;
  z-index: 100;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
`;

const TitleContainer = styled.div`
  display: flex;
  margin-left: 1.2rem;
`;

const UserFinancialModal = ({ isOpen, data, handleCloseClick, children }) => {
  if (!isOpen) return null;

  return (
    <ModalContainer>
      <TitleContainer>
        <h3>유저 정보</h3>
      </TitleContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>유저 번호</TableCell>
            <TableCell>멤버십 정보</TableCell>
            <TableCell>남은 기간</TableCell>
            <TableCell>잔여 포인트</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{data.id}</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>{data.current_point}</TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <TitleContainer>
        <h3>무통장 입금 내역</h3>
      </TitleContainer>
      <UserPendingPayment userData={data}></UserPendingPayment>

      <TitleContainer>
        <h3>포인트 사용 내역</h3>
      </TitleContainer>
      <UserPayment userData={data}></UserPayment>

      <Button onClick={() => handleCloseClick()}> 닫기 </Button>
    </ModalContainer>
  );
};

export default UserFinancialModal;
