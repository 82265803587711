import { gql } from "@apollo/client";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import UserPage from "../component/admin/admin-user/UserPage";
import DocuType1Page from "../component/admin/admin-document/DocuType1Page";
import Drawer from '@mui/joy/Drawer';
import DrawerBasic from "../component/admin/common/Drawer";


const Admin = () => {

    return(
        <div>
          <nav>
            <ul>
              <li>
              <DrawerBasic>
                {/* Drawer content */}
                아아
              </DrawerBasic>
              </li>
              <li><Link to="user">유저 관리</Link></li>
              <li><Link to="document1">자료 유형</Link></li>
              <li><Link to="upload">자료 업로드하기</Link></li>
            </ul>
          </nav>
          <UserPage/>
          <DocuType1Page/>
          <Routes>
            {/* Document 페이지 */}
            <Route path="admin/user">
              {/* 기본 Document 페이지 */}
              <Route
                index
                element={
                  <div>
                    <h2>Admin Document</h2>
                  </div>
                }
              />
            </Route>
              <Route path="admin/document" element={<DocuType1Page />} />
          </Routes>
        </div>
    );
};

export default Admin;