// SidebarMenu.js

import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { client } from "../../../client";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";

import BookModal from "./BookModal";

// DataGrid

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, InputBase, Typography } from "@mui/material";

const GET_BOOK_LIST = gql`
  query getBookExhibitionByAdmin($id: Int, $is_view: Int, $type: Type) {
    getBookExhibitionByAdmin(id: $id, is_view: $is_view, type: $type) {
      id
      title
      type
      is_view
      description
      view_order
      created_at
      updated_at
      __typename
      object_url
      sample_url
    }
  }
`;

const BookExhibition = () => {
  const [bookList, setBookList] = useState([]);
  const [selectedBook, setSelectedBook] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const fetchData = async () => {
    await client
      .query({
        query: GET_BOOK_LIST,
        variables: {},
        // fetchPolicy: "no-cache",
      })
      .then((res) => {
        console.log(res);
        setBookList(res.data.getBookExhibitionByAdmin);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEditClick = (book) => {
    setSelectedBook(book);
    setModalOpen(!isModalOpen);
  };

  const handleCloseClick = () => {
    setModalOpen(false);
  };

  const handleDeleteClick = async (data) => {
    const isConfirmed = window.confirm("정말 삭제하시겠습니까?");
    if (isConfirmed) {
      const DELETE_BOOK_EXHIBITION = gql`
        mutation deleteBookExhibitionByAdmin($id: Int) {
          deleteBookExhibitionByAdmin(id: $id) {
            message
          }
        }
      `;

      try {
        const res = await client.mutate({
          mutation: DELETE_BOOK_EXHIBITION,
          variables: {
            id: data.id,
          },
        });
        window.alert("삭제되었습니다.");
        window.location.reload();
      } catch (err) {
        console.log(err);
      }
    } else {
      console.log("취소");
    }
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "type", headerName: "타입", flex: 1 },
    { field: "title", headerName: "제목", flex: 1 },
    { field: "description", headerName: "설명", flex: 1 },
    { field: "view_order", headerName: "노출 순서" },
    {
      field: "is_view",
      headerName: "노출 여부",
      renderCell: (params) => {
        if (params.value === 1) {
          return "노출";
        } else {
          return "비노출";
        }
      },
    },
    {
      field: "created_at",
      headerName: "생성일자",
      flex: 1,
      renderCell: (params) => {
        params = parseInt(params.value);
        return (
          <Box whiteSpace={"normal"}>
            {new Date(params).toLocaleString("ko-kr")}
          </Box>
        );
      },
    },
    {
      field: "updated_at",
      headerName: "수정일자",
      flex: 1,
      renderCell: (params) => {
        params = parseInt(params.value);
        return (
          <Box whiteSpace={"normal"}>
            {new Date(params).toLocaleString("ko-kr")}
          </Box>
        );
      },
    },
    {
      field: "object_url",
      headerName: "이미지",
      flex: 2,
      renderCell: (params) => (
        <div
          style={{
            height: "150px",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            flex: 1,
          }}
        >
          <img src={params.value} alt={params.value} />
        </div>
      ),
    },
    {
      field: "edit",
      headerName: "수정",
      flex: 1,
      renderCell: (params) => (
        <Button onClick={() => handleEditClick(params.row)}>수정</Button>
      ),
    },
    {
      field: "delete",
      headerName: "삭제",
      flex: 1,
      renderCell: (params) => (
        <Button onClick={() => handleDeleteClick(params.row)}>삭제</Button>
      ),
    },
  ];

  return (
    <div>
      <Box
        margin={"7px"}
        display={"flex"}
        height={"70px"}
        justifyContent={"space-between"}
      >
        <Box ml={"5px"}>
          <h4>샘플 / 지원 자료 관리</h4>
        </Box>
      </Box>
      <Box ml={"5px"} display={"flex"}></Box>
      <Box ml={"5px"}>
        <DataGrid
          rows={bookList}
          columns={columns}
          rowHeight={150}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          components={{
            Toolbar: () => <GridToolbar />,
          }}
        />
      </Box>

      <BookModal
        isOpen={isModalOpen}
        handleCloseClick={handleCloseClick}
        refetchParentData={fetchData}
        data={selectedBook}
      ></BookModal>
    </div>
  );
};

export default BookExhibition;
