import React from "react";
import styled from "styled-components";

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  margin-top: 10px;
`;

const PageButton = styled.span`
  cursor: pointer;
  padding: 5px;
  margin-right: 8px;
  border-radius: 10px;
  background-color: ${(props) => (props.active ? "#5b00ff" : "white")};
  color: ${(props) => (props.active ? "white" : "black")};

  &:hover {
    background-color: #3500d3;
    color: white;
  }
`;

const PaginationComponent = ({
  data,
  currentPage,
  setPage,
  pageSet,
  setPageSet,
  itemsPerPage,
  maxPages,
}) => {
  const lastPage = Math.ceil(data.length / itemsPerPage);
  const lastPageInSet = Math.min(pageSet * maxPages, lastPage);
  const totalSets = Math.ceil(lastPage / maxPages);
  const firstPageInSet = (pageSet - 1) * maxPages + 1;
  const range = (start, end) => {
    let length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
  };
  return (
    <Pagination>
      <PageButton
        onClick={() => {
          setPageSet(1);
          setPage(1);
        }}
      >
        {"<<"}
      </PageButton>
      <PageButton onClick={() => pageSet > 1 && setPageSet(pageSet - 1)}>
        {"<"}
      </PageButton>
      {range(firstPageInSet, lastPageInSet).map((number) => (
        <PageButton
          key={number}
          active={number === currentPage}
          onClick={() => setPage(number)}
        >
          {number}
        </PageButton>
      ))}
      <PageButton
        onClick={() => pageSet < totalSets && setPageSet(pageSet + 1)}
      >
        {">"}
      </PageButton>
      <PageButton
        onClick={() => {
          setPageSet(totalSets);
          setPage(lastPage);
        }}
      >
        {">>"}
      </PageButton>
    </Pagination>
  );
};

export default PaginationComponent;
