import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import React, { useState } from "react";
import styled from "styled-components";
import { client } from "../../client"
import { GET_USER_INFO } from "../common/gql/userinfo.gql"
import { GET_DUCUMENT_BY_ID } from "../document/page/gql/document.gql";
import DocumentInfoContainer from "../document/detail/DocumentIInfoContainer";
import PaymentMethodContainer from "./PaymentSingleMethodContainer";


const PaymentSingleDocument = () => {
  const [accessToken, setAccessToken] = useState(localStorage.getItem("accessToken"))
  const [userInfo, setUserInfo] = useState();
  const [docuInfo, setDocuInfo] = useState();
  let { documentId } = useParams();

  useEffect(() => {
    if (!documentId) return; // payDocumentId가 없으면(fetchDocument가 호출되면) 무시

    const fetchDocument = async () => {
      await client
        .query({
          query: GET_DUCUMENT_BY_ID,
          variables: { document_id: parseInt(documentId) },
          fetchPolicy: "no-cache",
        })
        .then((res) => {
          setDocuInfo({
            ...res.data.getDocument,
            docu_type1_name: res.data.getDocument.docu_type1["name"],
            docu_type2_name: res.data.getDocument.docu_type2["name"],
          });
        });
    };

    fetchDocument();
  }, [documentId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await client.query({
          query: GET_USER_INFO,
          context: {
            headers: {
              authorization: accessToken ? `Bearer ${accessToken}` : "",
            },
          },
          fetchPolicy: "no-cache",
        });
        // 여기서 res.data에서 유저 정보를 추출하여 상태를 업데이트합니다.
        setUserInfo(res.data.getUserInfo);
      } catch (error) {
        console.error("Error fetching user info", error);
      }
    };

    // accessToken이 변경될 때마다 fetchData 함수를 다시 실행합니다.
    fetchData();
  }, [accessToken]);



    return (
      <Container>
        <SinglePayTag>자료 단건 구매하기</SinglePayTag>
        <DocumentInfoContainer docuInfo={docuInfo}>
          <PaymentMethodContainer
            userId={userInfo?.id}
            docuInfo={docuInfo}
          ></PaymentMethodContainer>
        </DocumentInfoContainer>
      </Container>
    );
    }

export default PaymentSingleDocument;



const Container = styled.div`
  min-width: 800px;
  height: 80vh;
  width: 60vw;

  display: flex; 
  flex-direction : column;


  margin-left: auto;
  margin-right: auto;

  padding-top: 2rem;

`;


const SinglePayTag = styled.div`
  all: unset;
  background-color: navy;
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  display: flex;
  align-items: center;
  width: 120px;
  padding: 12px 20px;
  border-radius: 6px;
`