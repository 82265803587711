import React, { useState, useEffect } from "react";

import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import { gql } from "@apollo/client";
import { client } from "../../../client";
import Button from "@mui/material/Button";

import ModifyErrorAdmin from "./ModifyErrorAdmin";

const GET_ERROR_REPORT = gql`
  query getErrorReport($id: Int, $page: Int, $pageSize: Int) {
    getErrorReport(id: $id, page: $page, pageSize: $pageSize) {
      list {
        id
        name
        title
        type
        user_id
        state
        content
        reply
        object_url
        created_at
        updated_at
      }
      totalCount
    }
  }
`;

const ErrorAdmin = () => {
  const [reportList, setReportList] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const enumType = {
    DOWNLOAD: "DOWNLOAD",
    PAYMENT: "PAYMENT",
    REQUEST: "REQUEST",
    ETC: "ETC",
  };

  const fetchData = () => {
    client
      .query({
        query: GET_ERROR_REPORT,
        variables: {
          page: 1,
          pageSize: 100000,
        },
      })
      .then((res) => {
        console.log(res);

        const tempReportList = [...res.data.getErrorReport.list];

        const filteredReportList = tempReportList
          .filter((item) => item.type === enumType.DOWNLOAD)
          .map((item) => ({
            ...item,
            isResponded: false,
          }))
          .reverse();

        setReportList(filteredReportList);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleCloseClick = () => {
    setModalOpen(false);
    window.location.reload();
  };

  const handleDeleteClick = async (data) => {
    const isConfirmed = window.confirm("정말 삭제하시겠습니까?");
    if (isConfirmed) {
      const DELETE_ERROR_REPORT = gql`
        mutation deleteErrorReport($id: Int!, $user_id: Int!) {
          deleteErrorReport(id: $id, user_id: $user_id) {
            message
          }
        }
      `;

      try {
        const res = await client.mutate({
          mutation: DELETE_ERROR_REPORT,
          variables: {
            id: data.id,
            user_id: data.user_id,
          },
        });
        window.alert("삭제되었습니다.");
        window.location.reload();
      } catch (err) {
        console.log(err);
      }
    } else {
      window.alert("취소되었습니다.");
    }
  };

  const handleModifyClick = (data) => {
    setSelectedData(data);
    setModalOpen(!isModalOpen);
  };

  const columns = [
    { field: "id", headerName: "문의 번호", flex: 1 },
    { field: "user_id", headerName: "유저 번호", flex: 1 },
    { field: "name", headerName: "유저 명", flex: 1 },
    {
      field: "type",
      headerName: "문의 유형",
      flex: 1,
    },
    { field: "title", headerName: "제목", flex: 1 },
    { field: "content", headerName: "내용", flex: 2 },
    {
      field: "object_url",
      headerName: "첨부 파일",
      flex: 2,
      renderCell: (params) => (
        <div
          style={{
            height: "50px",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <img src={params.value} alt={params.value} />
        </div>
      ),
    },
    {
      field: "created_at",
      headerName: "문의 일자",
      flex: 1,
      renderCell: (params) => {
        params = parseInt(params.value);
        return (
          <Box whiteSpace={"normal"}>
            {new Date(params).toLocaleString("ko-kr")}
          </Box>
        );
      },
    },
    {
      field: "updated_at",
      headerName: "수정 일자",
      flex: 1,
      renderCell: (params) => {
        params = parseInt(params.value);
        return (
          <Box whiteSpace={"normal"}>
            {new Date(params).toLocaleString("ko-kr")}
          </Box>
        );
      },
    },
    {
      field: "reply",
      headerName: "답변",
      flex: 1,
      renderCell: (data) => (
        <Button onClick={() => handleModifyClick(data.row)}>답변하기</Button>
      ),
    },
    {
      field: "delete",
      headerName: "삭제",
      flex: 1,
      renderCell: (data) => (
        <Button onClick={() => handleDeleteClick(data.row)}>삭제</Button>
      ),
    },
    {
      field: "state",
      headerName: "처리상태",
      flex: 1,
      renderCell: (params) => {
        if (params.value === 1) {
          return <Box>접수중</Box>;
        } else if (params.value === 2) {
          return <Box>접수완료</Box>;
        } else {
          return <Box>답변완료</Box>;
        }
      },
    },
  ];

  return (
    <div>
      <Box
        margin={"7px"}
        display={"flex"}
        height={"70px"}
        justifyContent={"space-between"}
      >
        <Box ml={"5px"}>
          <h2>오류제보</h2>
        </Box>
      </Box>
      <Box>
        <DataGrid
          rows={reportList}
          columns={columns}
          rowHeight={100}
          initialState={{
            pagination: { paginationModel: { pageSize: 100 } },
          }}
          components={{
            Toolbar: () => <GridToolbar />,
          }}
        />
      </Box>

      <ModifyErrorAdmin
        isOpen={isModalOpen}
        handleCloseClick={handleCloseClick}
        refetchParentData={fetchData}
        data={selectedData}
      ></ModifyErrorAdmin>
    </div>
  );
};

export default ErrorAdmin;
