import React, { useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Paper, Button } from '@mui/material'
import { gql } from "@apollo/client";
import { client } from "../../client";
import GridItem from "./GridItem";

const NoticePreviewGrid = () => {
  const GET_USER_INFO = gql`
    query getUserInfo {
      getUserInfo {
        name
      }
    }
  `;
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);


  useEffect(() => {
  }, []);
  
  const items = [
    {
        id: 1,
        title: "공지 사항",
        description: "Hello World!", 
        img: "https://img.iipamaster.com/upload/bnr/20230607/20230607161158_8193.jpg",
    },
    {
        id: 2,
        title: "자주 묻는 질문",
        description: "Probably the most random thing you have ever seen!",
        img: "https://img.iipamaster.com/upload/bnr/20230824/20230824111221_5828.jpg"
    },
]

  return (
    <div>
        <SectionTitle>공지 사항</SectionTitle>
        <GridContainer>
            {items.map((item) => {
                return <GridItem key={item['id']} title={item['title']}></GridItem>
            })}
        </GridContainer>
        <br/>
    </div>
  );
};

export default NoticePreviewGrid;


const GridContainer = styled.div`
    max-width: 1440px;
    display: flex;
    flex-wrap: wrap;
`;

const SectionTitle = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: normal;
    max-height: 4.8rem;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 150%;
    text-align: left;
    margin-left: 1.2rem;
    margin-bottom: 1rem;
`