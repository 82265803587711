import React, { useEffect, useState } from "react";
import { RESET_PASSWORD, SEND_SMS, VERIFY_PASSWORD } from "./Search.gql";
import { client } from "../../client";
import { useNavigate } from "react-router-dom";
import {
  SignUpContainer,
  Divider,
  Form,
  FormGroup,
  Label,
  Title,
  Input,
  Button,
  WarningText,
  CorrectText,
} from "./Search.module";
import { Box } from "@mui/joy";

const SearchPW = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    phone_number: "",
    sms_auth_number: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [verifyCertificationCode, setVerifyCertificationCode] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [certificationCode, setCertificationCode] = useState();
  const [formTouched, setFormTouched] = useState({
    id: false,
    password: false,
  });

  const [countdown, setCountdown] = useState(0);
  const [timer, setTimer] = useState(null);
  const [isCounting, setIsCounting] = useState(false);

  const handleChange = (e) => {
    const { id, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const confirm = (e) => {
    e.preventDefault();

    client
      .mutate({
        mutation: VERIFY_PASSWORD,
        variables: {
          email: formData.email,
          sms_auth_number: parseInt(formData.sms_auth_number),
        },
      })
      .then((res) => {
        setVerifyCertificationCode(true);
        window.alert(
          "인증에 성공했습니다. 아래 버튼을 눌러 비밀번호를 변경해주세요."
        );
        setIsCounting(false);
      })
      .catch((err) => {
        console.log(err);
        window.alert("올바르지 않은 인증 번호이거나 존재하지 않는 회원입니다.");
      });
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    switch (name) {
      case "id":
        setFormTouched({ ...formTouched, id: true });
        break;
      case "password":
        setFormTouched({ ...formTouched, password: true });
        break;
    }
  };

  const showPasswordResetPage = () => {
    setEmailAddress(formData.email);
    setCertificationCode(formData.sms_auth_number);
    setConfirmed(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await client
        .mutate({
          mutation: RESET_PASSWORD,
          variables: {
            email: emailAddress,
            password: formData.password,
            sms_auth_number: parseInt(certificationCode),
          },
        })
        .then((res) => {
          window.alert("비밀번호 재설정이 완료되었습니다.");
          navigate("/login");
        });
    } catch (err) {
      window.alert("비밀번호 재설정에 실패했습니다.");
      setFormData({
        password: "",
        sms_auth_number: "",
        email: "",
      });
      setConfirmed(false);
      console.error(err);
    }
  };

  const sendSMS = async (e) => {
    e.preventDefault();

    clearInterval(timer);

    await client
      .mutate({
        mutation: SEND_SMS,
        variables: {
          phone_number: formData.phone_number,
        },
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        window.alert("인증번호가 발송되었습니다.");
        const newTimer = startCountdown();
        setTimer(newTimer);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const passwordsMatch = () => {
    return formData.password === formData.confirmPassword;
  };

  const validPassword = () => {
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/;

    return passwordRegex.test(formData.password);
  };

  const startCountdown = () => {
    setCountdown(600);
    setIsCounting(true);
    const newTimer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);
    return newTimer;
  };

  useEffect(() => {
    if (countdown === 0) {
      setIsCounting(false);
    }
  }, [countdown]);

  return (
    <div>
      <SignUpContainer>
        <Title>비밀번호 재설정</Title>
        <Divider disabled={!verifyCertificationCode} />
        {confirmed === false ? (
          <Form>
            <FormGroup>
              <Label htmlFor="email">아이디 입력</Label>
              <Input
                id="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="아이디 입력"
                maxLength={255}
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor="phone_number">전화번호</Label>
              <Input
                id="phone_number"
                value={formData.phone_number}
                onChange={handleChange}
                placeholder="-를 제외한 숫자만 입력"
                maxLength={11}
              />
              {isCounting === false ? (
                <Button onClick={sendSMS}>인증번호 발송</Button>
              ) : (
                <Button onClick={sendSMS}>{countdown}초 남음</Button>
              )}
            </FormGroup>

            <FormGroup>
              <Label>인증번호</Label>
              <Input
                id="sms_auth_number"
                value={formData.sms_auth_number}
                onChange={handleChange}
                placeholder="6자리 숫자 입력"
                maxLength={6}
              />
              <Button onClick={confirm}>인증번호 확인</Button>
            </FormGroup>

            <Button
              onClick={showPasswordResetPage}
              disabled={!verifyCertificationCode}
            >
              비밀번호 재설정
            </Button>
          </Form>
        ) : (
          <Form>
            <FormGroup>
              <Label htmlFor="password">비밀번호</Label>
              <Input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="비밀번호를 입력하세요"
              />
              {!validPassword() &&
                formData.password &&
                formTouched.password && (
                  <WarningText>
                    최소 8자리, 영문, 숫자를 포함해야 합니다.
                  </WarningText>
                )}
            </FormGroup>

            <FormGroup>
              <Label htmlFor="confirmPassword">비밀번호 확인</Label>
              <Input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                placeholder="비밀번호 확인"
              />
              {!passwordsMatch() && formData.confirmPassword && (
                <WarningText>비밀번호가 일치하지 않습니다.</WarningText>
              )}
              {passwordsMatch() && formData.confirmPassword && (
                <CorrectText>비밀번호가 일치합니다.</CorrectText>
              )}
            </FormGroup>

            <Button onClick={handleSubmit}>완료</Button>
          </Form>
        )}
      </SignUpContainer>
    </div>
  );
};

export default SearchPW;
