import React, { useState, useEffect } from "react";
import * as S from "../Filter.style";

import PaginationComponent from "../pagenation/PagenationComponent";
import ListComponent from "../pagenation/List";
import { client } from "../../../client";
import {
  GET_ALL_DOCUTYPE,
  GET_DOCUMENT_URL,
  GET_FILTER_DATA,
} from "./gql/document.gql";

const ITEMS_PER_PAGE = 10;
const MAX_PAGES = 5;

// 수능 연계 교재 
const CsatSub = () => {
  const [documentData, setDocumentData] = useState([]);
  const [selectedTextbooks, setSelectedTextbooks] = useState([]);
  const [selectedDocumentTypes, setSelectedDocumentTypes] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const headers = [
    "번호",
    "교재",
    "자료",
    "업로드일",
    "다운로드수",
    "가격",
    "파일",
  ];
  const columnRatios = "0.1fr 0.4fr 0.6fr 0.3fr 0.15fr 0.2fr 0.1fr";

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSet, setPageSet] = useState(1);
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;

  const [textbooks, setTextbooks] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);

  const getFilter = () => {
    setIsLoading(true);
    client
      .query({
        query: GET_FILTER_DATA,
        variables: {
          docu_type1_id: 3,
        },
      })
      .then((res) => {
        const fetchedData = JSON.parse(res.data.getFilterListByDocumentType1);
        const textbookData = fetchedData.filterList.find(
          (f) => f.col_name === "category1"
        ).value;
        const documentTypeData = fetchedData.filterList.find(
          (f) => f.col_name === "docu_type2"
        ).value;
        setTextbooks(textbookData);
        setDocumentTypes(documentTypeData);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const fetchDataV2 = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      const res = await client.query({
        query: GET_ALL_DOCUTYPE,
        context: {
          headers: {
            authorization: accessToken ? `Bearer ${accessToken}` : "",
          },
        },
        variables: {
          document_search_input: {
            docu_type1_id: 3,
            docu_type2_id: selectedDocumentTypes,
            category1_id: selectedTextbooks,
            name: searchTerm,
            page: 1,
            pageSize: 300,
          },
        },
        fetchPolicy: "no-cache",
      });

      const documents = res.data.searchDocuTypeV2.map(async (item, index) => {
        const {
          id,
          category1_name,
          name,
          created_at,
          downloaded,
          price,
          documentUrlList,
        } = item;
        const date = new Date(parseInt(created_at, 10));
        const formattedDate = date.toISOString().split("T")[0];

        const urls = item.documentUrlList;

        let hwpUrl;
        let pdfUrl;

        urls.map((item) => {
          item.type === "HWP"
            ? (hwpUrl = item.object_url)
            : (pdfUrl = item.object_url);
        });

        return {
          index: id,
          category1_name,
          name,
          created_at: formattedDate,
          downloaded,
          price,
          downloadUrl: {
            pdfUrl,
            hwpUrl,
            id,
            price,
          },
        };
      });

      Promise.all(documents).then(setDocumentData).catch(console.error);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    const accessToken = localStorage.getItem("accessToken");

    await client
      .query({
        query: GET_ALL_DOCUTYPE,
        context: {
          headers: {
            authorization: accessToken ? `Bearer ${accessToken}` : "",
          },
        },
        variables: {
          document_search_input: {
            docu_type1_id: 3,
            docu_type2_id: selectedDocumentTypes,
            category1_id: selectedTextbooks,
            name: searchTerm,
            page: 1,
            pageSize: 300,
          },
          fetchPolicy: "no-cache",
        },
      })
      .then((res) => {
        const documents = res.data.searchDocuTypeV2.map(async (item, index) => {
          const {
            id,
            category1_name,
            name,
            created_at,
            downloaded,
            price,
            documentUrlList,
          } = item;
          const date = new Date(parseInt(created_at, 10));
          const formattedDate = date.toISOString().split("T")[0];

          try {
            // const response = await client.query({
            //   query: GET_DOCUMENT_URL,
            //   variables: { document_id: id, type: "PDF" },
            //   fetchPolicy: "no-cache",
            // });
            // const url = response.data.getDocumentUrlByType;
            const urls = documentUrlList;
            let pdfUrl;
            let hwpUrl;

            urls.map((item) => {
              item.type === "HWP"
                ? (hwpUrl = item.object_url)
                : (pdfUrl = item.object_url);
            });
            return {
              index: id,
              category1_name,
              name,
              created_at: formattedDate,
              downloaded,
              price,
              downloadUrl: {
                pdfUrl,
                hwpUrl,
                id,
                price,
              },
            };
          } catch (err) {
            console.log(err);
            return {
              index: id,
              category1_name,
              name,
              created_at: formattedDate,
              downloaded,
              price,
            };
          }
        });
        Promise.all(documents).then(setDocumentData).catch(console.error);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getFilter();
    fetchDataV2();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      fetchDataV2();
    }
  }, [isLoading]);

  const currentItems = documentData.slice(indexOfFirstItem, indexOfLastItem);

  const handleSearch = (event) => {
    event.preventDefault();
    fetchDataV2();
  };

  const handleTextbookChange = (id) => (event) => {
    if (event.target.checked) {
      setSelectedTextbooks([...selectedTextbooks, id]);
    } else {
      setSelectedTextbooks(selectedTextbooks.filter((item) => item !== id));
    }
  };

  const handleDocumentTypeChange = (id) => (event) => {
    if (event.target.checked) {
      setSelectedDocumentTypes([...selectedDocumentTypes, id]);
    } else {
      setSelectedDocumentTypes(
        selectedDocumentTypes.filter((item) => item !== id)
      );
    }
  };

  return (
    <S.Wrapper>
      <S.Container>
        <S.FilterContainer>
          <S.FilterSection>
            <S.FilterTitle>교재</S.FilterTitle>
            {textbooks.map((textbook) => (
              <S.FilterCheckboxLabel key={textbook.id}>
                <S.FilterCheckbox
                  type="checkbox"
                  value={textbook.name}
                  checked={selectedTextbooks.includes(textbook.id)}
                  onChange={handleTextbookChange(textbook.id)}
                />
                {textbook.name}
              </S.FilterCheckboxLabel>
            ))}
          </S.FilterSection>
          <S.FilterSection>
            <S.FilterTitle>자료유형</S.FilterTitle>
            {documentTypes.map((documentType) => (
              <S.FilterCheckboxLabel key={documentType.id}>
                <S.FilterCheckbox
                  type="checkbox"
                  value={documentType.name}
                  checked={selectedDocumentTypes.includes(documentType.id)}
                  onChange={handleDocumentTypeChange(documentType.id)}
                />
                {documentType.name}
              </S.FilterCheckboxLabel>
            ))}
          </S.FilterSection>
        </S.FilterContainer>
        <S.SearchContainer as="form" onSubmit={handleSearch}>
          <S.Input
            type="text"
            placeholder="검색어를 입력하세요"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <S.SearchButton type="submit">검색</S.SearchButton>
        </S.SearchContainer>
      </S.Container>
      <ListComponent
        headers={headers}
        columnRatios={columnRatios}
        items={currentItems}
        fetchData={fetchDataV2}
      />
      <PaginationComponent
        data={documentData}
        currentPage={currentPage}
        setPage={setCurrentPage}
        pageSet={pageSet}
        setPageSet={setPageSet}
        itemsPerPage={ITEMS_PER_PAGE}
        maxPages={MAX_PAGES}
      />
    </S.Wrapper>
  );
};

export default CsatSub;
