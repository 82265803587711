import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { gql } from "@apollo/client";
import { client } from "../../../client";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

import ModifyNoticeAdmin from "./ModifyCashPayAdmin";

const GET_CASH_PAY_ADMIN = gql`
  query getCashPayByAdmin($page:Int, $pageSize:Int) {
    getCashPayByAdmin(page:$page, pageSize:$pageSize) {
      id 
      status
      amount
      payment_method
      document_id
      user_name
      created_at
    }
  }
`;

const CashPayAdmin = () => {
  const [dataList, setDataList] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const navigate = useNavigate();

  const fetchData = () => {
    client
      .query({
        query: GET_CASH_PAY_ADMIN,
        variables: {
          page: 1,
          pageSize: 1000,
        },
      })
      .then((res) => {
        console.log(res);
        setDataList(res.data.getCashPayByAdmin);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCloseClick = () => {
    setModalOpen(false);
  };

  const handleDeleteClick = async (data) => {
    const isConfirmed = window.confirm("정말 삭제하시겠습니까?");
    if (isConfirmed) {
      const DELETE_NOTICE_ADMIN = gql`
        mutation deleteNoticeByAdmin($id: Int) {
          deleteNoticeByAdmin(id: $id) {
            message
          }
        }
      `;

      try {
        const res = await client.mutate({
          mutation: DELETE_NOTICE_ADMIN,
          variables: {
            id: data.id,
          },
        });
        window.alert("삭제되었습니다.");
        window.location.reload();
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleModifyClick = (data) => {
    setSelectedData(data);
    setModalOpen(!isModalOpen);
  };

  const handleCreateClick = () => {
    navigate("/admin/notice/create");
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "status", headerName: "상태", flex: 1 },
    { field: "user_name", headerName: "상태", flex: 1 },
    { field: "amount", headerName: "금액", flex: 1 },
    { field: "document_id", headerName: "자료번호", flex: 1 },
    {
      field: "created_at",
      headerName: "신청 일자",
      flex: 1,
      renderCell: (params) => {
        params = parseInt(params.value);
        return (
          <Box whiteSpace={"normal"}>
            {new Date(params).toLocaleString("ko-kr")}
          </Box>
        );
      },
    },
    {
      field: "modify",
      headerName: "수정",
      flex: 1,
      renderCell: (data) => (
        <Button onClick={() => handleModifyClick(data.row)}>수정</Button>
      ),
    },
  ];

  return (
    <div>
      <Box
        margin={"7px"}
        display={"flex"}
        height={"70px"}
        justifyContent={"space-between"}
      >
        <Box ml={"15px"}>
          <h3>무통장입금 관리</h3>
        </Box>
      </Box>
      <Box ml={"5px"}>
        <DataGrid
          rows={dataList}
          columns={columns}
          rowHeight={150}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          components={{ Toolbar: () => <GridToolbar /> }}
        />

        <ModifyNoticeAdmin
          isOpen={isModalOpen}
          handleCloseClick={handleCloseClick}
          refetchParentData={fetchData}
          data={selectedData}
        ></ModifyNoticeAdmin>
      </Box>
    </div>
  );
};

export default CashPayAdmin;
