import React, { useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Paper } from '@mui/material'
import { gql } from "@apollo/client";
import { client } from "../../client";
import GridItem from "./GridItem";
import Carousel from 'react-material-ui-carousel'

const SampleList = () => {
    const GET_SAMPLE_LIST = gql`
    query getBookExhibitionByAdmin($type:Type) {
        getBookExhibitionByAdmin(type:$type) {
          title
          object_url
          sample_url
        }
      }
    `;


  const [ sampleList, setSampleList ] = useState([]);

  const fetchData = async () => {
    await client
        .query({
          query: GET_SAMPLE_LIST,
          variables : {
            type : 'SAMPLE'
          },
          fetchPolicy: "no-cache"
        })
        .then((res) => {
            setSampleList(res.data.getBookExhibitionByAdmin)
          
        })
        .catch((err) => {
          console.log(err);
        });
  };

  useEffect(() => {
    fetchData()
  }, []);

  // 다운로드 관련
  const handleDownload = (url, fileName) => {

    const a = window.open(url, '_blank');
    if (a && a.focus) {
      a.focus();
    } else {
      alert('팝업 차단이 감지되었습니다. 새 창을 열어주세요.');
    }
    
    // const a = document.createElement("a");
    // a.href = `${tempUrl}`;
    // a.download = fileName || "download";
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);

    // // 방식2

    // fetch(tempUrl)
    //   .then((response) => response.blob())
    //   .then((blob) => {
    //     const url = window.URL.createObjectURL(new Blob([blob]));
    //     const a = document.createElement("a");
    //     a.href = tempUrl;
    //     a.download = fileName || "download";
    //     document.body.appendChild(a);
    //     a.click();
    //     window.URL.revokeObjectURL(url);
    //     document.body.removeChild(a); 
    //   })
    //   .catch((error) => {
    //     console.error("파일 다운로드 오류:", error);
    //   });
  };
  

  return (
    <LayoutContainer>
        <br/>
        <br/>
        <SectionTitle>샘플 보기</SectionTitle>
        <Container>
            {sampleList.map((item, itemIndex) => {
                return <BookContainer key={itemIndex} >
                    <BookItem  src={item.object_url}></BookItem>
                    <Title>{item['title']}</Title>
                    {/* <Description>{item['description']}</Description> */}
                    <Button onClick={() => handleDownload(item.sample_url, item.title)}>다운로드</Button>
                </BookContainer>
            })}
        </Container>
    </LayoutContainer>
  );
};

export default SampleList;

const LayoutContainer = styled.div`
    width: 30%
    padding-left: 1.2rem;
`

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2개의 열을 가진 그리드를 생성합니다. */
    grid-template-rows: repeat(2, 1fr); /* 2개의 행을 가진 그리드를 생성합니다. */
    gap: 10px; /* 그리드 아이템 사이의 간격을 설정합니다. */
    // padding-left: 1.3rem;
`


const BookContainer = styled.div`
    width: 90%;
    height: 350px;
    display: flex;
    flex-direction: column;

	align-items: center;
`

const Title = styled.div`
    font-size:  ${(props) => props.theme.fontSize.subtitle};
    font-weight: 600;

    word-wrap: normal;
    max-height: 4.8rem;
    line-height: 150%;
    width :  100%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`


const BookItem = styled.img`
    height: 240px;  /* Match the height of the parent container */
    width: 240px;
    object-fit: cover; /* 이미지를 
    padding-left: 1rem;

  
`
const SectionTitle = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: normal;
    max-height: 4.8rem;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 150%;
    text-align: left;
    margin-bottom: 1rem;
`


const Button = styled.button`
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 50%;
  font-weight: 700;
  transition: background-color 0.3s, color 0.3s;

    background:  ${(props) => props.theme.colors.accent};
    color: #fff;
    &:hover {
        background: ${(props) => props.theme.colors.accentHover};
    }
`;