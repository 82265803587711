import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const IconAndTop = ({ data }) => {
  const navigate = useNavigate();
  const handleItemClick = (item) => {
    // 여기에서 itemId를 이용하여 navigation을 처리합니다.
    navigate(`/document/${item.id}`, { state: { item: item } });
  };
  const routeToPayment = (path) => {
    // 여기에서 itemId를 이용하여 navigation을 처리합니다.
    navigate(`/${path}`);
  };

  const [months, setMonths] = useState([]);
  const [documentData, setDocumentData] = useState([]);

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleFaqClick = () => {
    navigate("/customer/faq");
  };

  const handleErrorClick = () => {
    navigate("/customer/records/edit");
  };

  const handleInquiryClick = () => {
    navigate("/customer/records/inquiry");
  };

  const handleNoticeClick = () => {
    navigate("/customer/notice");
  };

  useEffect(() => {}, []);

  useEffect(() => {
    const fetchDocumentData = async () => {
      try {
        const newDataPromises = data.map(async (item) => {
          const {
            id,
            grade,
            year,
            docu_type1_name,
            docu_type2_name,
            month_id,
            name,
            created_at,
            downloaded,
            price,
            documentUrlList,
          } = item;
          const date = new Date(parseInt(created_at, 10));
          const formattedDate = date.toISOString().split("T")[0];

          let hwpUrl;
          let pdfUrl;

          documentUrlList.forEach((urlItem) => {
            if (urlItem.type === "HWP") {
              hwpUrl = urlItem.object_url;
            } else if (urlItem.type === "PDF") {
              pdfUrl = urlItem.object_url;
            }
          });

          return {
            index: id,
            id,
            grade,
            year,
            docu_type1_name,
            docu_type2_name,
            month: months.find((m) => m.id == month_id)?.name,
            name,
            uploadedDate: formattedDate,
            downloaded,
            price,
            downloadUrl: {
              pdfUrl,
              hwpUrl,
              id,
              price,
            },
          };
        });

        const newData = await Promise.all(newDataPromises);
        setDocumentData(newData);
      } catch (error) {
        console.error("에러", error);
      }
    };

    fetchDocumentData();
  }, [data, months]);

  return (
    // 가로 컨테이너
    <Container>
      {/* // 가로 컨테이너 - 아이콘 */}
      <ShortCutContainer>
        <SectionTitle>바로가기</SectionTitle>
        <IconContainer>
          <IconItem onClick={() => routeToPayment("customer/notice")}>
            <IconImgItem>
              <img
                width="70px"
                src="https://seum-file.s3.ap-northeast-2.amazonaws.com/upload/1.+%E1%84%80%E1%85%A9%E1%86%BC%E1%84%8C%E1%85%B5%E1%84%89%E1%85%A1%E1%84%92%E1%85%A1%E1%86%BC+%E1%84%8B%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%8F%E1%85%A9%E1%86%AB.png"
              ></img>
            </IconImgItem>
            <div>공지사항</div>
          </IconItem>
          <IconItem onClick={() => handleFaqClick()}>
            <IconImgItem>
              <img
                width="70px"
                src="https://seum-file.s3.ap-northeast-2.amazonaws.com/upload/2.+FAQ+%E1%84%8B%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%8F%E1%85%A9%E1%86%AB.png"
              ></img>
            </IconImgItem>
            <div>FAQ</div>
          </IconItem>
          <IconItem onClick={() => routeToPayment("payment")}>
            <IconImgItem>
              <img
                width="70px"
                src="https://seum-file.s3.ap-northeast-2.amazonaws.com/upload/3.+%E1%84%8B%E1%85%B5%E1%84%8B%E1%85%AD%E1%86%BC%E1%84%80%E1%85%AF%E1%86%AB+%E1%84%80%E1%85%AE%E1%84%86%E1%85%A2+%E1%84%8B%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%8F%E1%85%A9%E1%86%AB.png"
              ></img>
            </IconImgItem>
            <div>이용권 구매</div>
          </IconItem>
          <IconItem onClick={() => handleInquiryClick()}>
            <IconImgItem>
              <img
                width="70px"
                src="https://seum-file.s3.ap-northeast-2.amazonaws.com/upload/4.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%8B%E1%85%B4%E1%84%92%E1%85%A1%E1%84%80%E1%85%B5+%E1%84%8B%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%8F%E1%85%A9%E1%86%AB.png"
              ></img>
            </IconImgItem>
            <div>문의하기</div>
          </IconItem>
          <IconItem onClick={() => handleErrorClick()}>
            <IconImgItem>
              <img
                width="70px"
                src="https://seum-file.s3.ap-northeast-2.amazonaws.com/upload/5.+%E1%84%8B%E1%85%A9%E1%84%85%E1%85%B2%E1%84%89%E1%85%B5%E1%86%AB%E1%84%80%E1%85%A9+%E1%84%8B%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%8F%E1%85%A9%E1%86%AB.png"
              ></img>
            </IconImgItem>
            <div>오류신고</div>
          </IconItem>
          <IconItem onClick={() => routeToPayment("search/mock-tests")}>
            <IconImgItem>
              <img
                width="70px"
                src="https://seum-file.s3.ap-northeast-2.amazonaws.com/upload/6.+%E1%84%86%E1%85%A9%E1%84%8B%E1%85%B4%E1%84%80%E1%85%A9%E1%84%89%E1%85%A1%E1%84%92%E1%85%A1%E1%86%A8%E1%84%91%E1%85%A7%E1%86%BC+%E1%84%8B%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%8F%E1%85%A9%E1%86%AB.png"
              ></img>
            </IconImgItem>
            <div>모의고사/학평</div>
          </IconItem>
          <IconItem onClick={() => routeToPayment("search/highschool-sub")}>
            <IconImgItem>
              <img
                width="70px"
                src="https://seum-file.s3.ap-northeast-2.amazonaws.com/upload/7.+EBS%E1%84%87%E1%85%AE%E1%84%80%E1%85%AD%E1%84%8C%E1%85%A2+%E1%84%8B%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%8F%E1%85%A9%E1%86%AB.png"
              ></img>
            </IconImgItem>
            <div>EBS부교재</div>
          </IconItem>
          <IconItem onClick={() => routeToPayment("search/csat-sub")}>
            <IconImgItem>
              <img
                width="70px"
                src="https://seum-file.s3.ap-northeast-2.amazonaws.com/upload/8.+%E1%84%89%E1%85%AE%E1%84%82%E1%85%B3%E1%86%BC%E1%84%8B%E1%85%A7%E1%86%AB%E1%84%80%E1%85%A8%E1%84%80%E1%85%AD%E1%84%8C%E1%85%A2+%E1%84%8B%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%8F%E1%85%A9%E1%86%AB.png"
              ></img>
            </IconImgItem>
            <div>수능연계교재</div>
          </IconItem>
          <IconItem
            onClick={() => routeToPayment("search/highschool-textbook")}
          >
            <IconImgItem>
              <img
                width="70px"
                src="https://seum-file.s3.ap-northeast-2.amazonaws.com/upload/9.+%E1%84%80%E1%85%A9%E1%84%83%E1%85%B3%E1%86%BC%E1%84%80%E1%85%AD%E1%84%80%E1%85%AA%E1%84%89%E1%85%A5+%E1%84%8B%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%8F%E1%85%A9%E1%86%AB.png"
              ></img>
            </IconImgItem>
            <div>고등교과서</div>
          </IconItem>
          <IconItem onClick={() => alert("아직 준비중인 기능입니다.")}>
            <IconImgItem>
              <img
                width="70px"
                src="https://seum-file.s3.ap-northeast-2.amazonaws.com/upload/10.+%E1%84%83%E1%85%A5+%E1%84%8B%E1%85%A1%E1%86%AF%E1%84%8B%E1%85%A1%E1%84%87%E1%85%A9%E1%84%80%E1%85%B5+%E1%84%8B%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%8F%E1%85%A9%E1%86%AB.png"
              ></img>
            </IconImgItem>
            <div>더 알아보기</div>
          </IconItem>
        </IconContainer>
      </ShortCutContainer>
      {/* 세로 컨테이너 - top 5 */}
      <TopChartContainer>
        <SectionTitle>인기자료 TOP5</SectionTitle>
        {/* 순위 리스트 */}
        <ItemList>
          {documentData.map((item, index) => {
            // console.log("item...", item);

            return (
              <TopChartItem
                key={index}
                onClick={() => {
                  handleItemClick(item);
                }}
              >
                <TopChartItemRank>{index + 1}</TopChartItemRank>
                <TopChartItemText>{item.name}</TopChartItemText>
              </TopChartItem>
            );
          })}
        </ItemList>
      </TopChartContainer>
    </Container>
  );
};

export default IconAndTop;

const Container = styled.div`
  min-width: 1200px;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  // justify-content: space-between;
`;

const ShortCutContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 900px;
  width: 63.7%;
`;

const IconContainer = styled.div`
  // display: flex;
  // flex-grow: 1;
  // flex-direction: row;
  // justify-content:space-evenly;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 3rem 2px; /* 필요에 따라 조절 가능 */
  margin-top: 1rem;
`;

const TopChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;
  margin-left: 1.3rem;
`;

const IconImgItem = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`;

const IconItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  font-size: ${(props) => props.theme.fontSize.subtitle};
  font-weight: 600;

  padding-top: 1rem;
  padding-bottom: 1rem;
  border: 2px solid transparent; /* 기본적으로 투명한 테두리 설정 */
  border-radius: 15px; /* 테두리를 둥글게 만듭니다. */

  transition: transform 0.3s ease; /* Add a smooth transition effect */
  &:hover {
    cursor: pointer;
    transform: scale(1.1); /* Increase the size on hover */
    border-color: #d13040; /* 호버 시 테두리 색상 변경 */
  }
`;

// transition: transform 0.3s ease; /* Add a smooth transition effect */
// &:hover {
//   transform: scale(1.1); /* Increase the size on hover */
// }
// `;

const ItemList = styled.div`
  flex-grow: 1;
  heigth: 100%;
  width: 100%;
  // background-color: #f4f5f8;
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  // justify-content:space-evenly;

  padding-top: 2.5rem;
  //padding-bottom: rem;
`;

const TopChartItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  align-items: center;
  margin-bottom: 25px;
  padding-bottom: 25px;
  width: 100%;
  border-bottom: solid 2px #80808080;

  &:hover {
    font-weight: bold; /* Increase font weight on hover */
    cursor: pointer; /* Change cursor to pointer (hand) on hover */
    opacity: 0.7; /* Reduce opacity on hover */
  }
`;

const TopChartItemRank = styled.div`
  font-weight: 900;
  color: rgba(0, 0, 0, 0.8);
  line-height: 24px !important;
  font-family: NotoSansKR, "SF Pro Text", "Segoe UI", "Helvetica Neue", Arial,
    sans-serif !important;
  margin-right: 1.6rem;
  margin-left: 1rem;
  font-size: 40px;
  color: #e2392b; /* 호버 시 테두리 색상 변경 */
`;

const TopChartItemText = styled.div`
  width: 400px;
  display: flex;
  font-size: ${(props) => props.theme.fontSize.subtitle};
  font-weight: 600;
`;

const TopChartItemRank1 = styled.div`
  font-weight: 900;
  color: #0064ff;
  line-height: 24px !important;
  font-family: NotoSansKR, "SF Pro Text", "Segoe UI", "Helvetica Neue", Arial,
    sans-serif !important;
  margin-right: 1.6rem;
  margin-left: 1rem;
  font-size: ${(props) => props.theme.fontSize.subtitle};
`;

const SectionTitle1 = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: normal;
  max-height: 4.8rem;
  font-weight: 700;
  line-height: 120%;
  text-align: left;
  font-size: ${(props) => props.theme.fontSize.title};
`;

const SectionTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: normal;
  max-height: 4.8rem;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 150%;
  text-align: left;
  margin-bottom: 0.4rem;
`;
