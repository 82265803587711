import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import UpperBar from "./style/UpperBar";
import {
  Container,
  H3,
  TopContainer,
  renderTextWithLineBreaks,
  ContentField,
} from "./style/Customer.module";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Pagination,
  Button,
} from "@mui/material";

import { gql } from "@apollo/client";
import { client } from "../../client";

const GET_NOTICE_ADMIN_V2 = gql`
  query getNoticeByAdmin {
    getNoticeByAdmin {
      emergency {
        id
        user_id
        name
        visible
        title
        content
        object_url
        created_at
        updated_at
      }
      important {
        id
        user_id
        name
        visible
        title
        content
        object_url
        created_at
        updated_at
      }
      general {
        id
        user_id
        name
        visible
        title
        content
        object_url
        created_at
        updated_at
      }
    }
  }
`;

const GET_NOTICE_ADMIN = gql`
  query getNoticeByAdmin(
    $id: Int
    $visible: Boolean
    $page: Int
    $pageSize: Int
  ) {
    getNoticeByAdmin(
      id: $id
      visible: $visible
      page: $page
      pageSize: $pageSize
    ) {
      id
      user_id
      name
      visible
      title
      content
      object_url
      created_at
      updated_at
    }
  }
`;

const Notice = () => {
  const [noticeList, setNoticeList] = useState([]);
  const [emergencyNoticeList, setEmergencyNoticeList] = useState([]);
  const [selectedNoticeId, setSelectedNoticeId] = useState(null);
  const [page, setPage] = useState(1);

  const [visibleNoticeCount, setVisibleNoticeCount] = useState(3);
  const [showClicked, setShowClicked] = useState(false);

  const fetchData = () => {
    client
      .query({
        query: GET_NOTICE_ADMIN_V2,
        variables: {
          page: page,
          pageSize: 100,
        },
      })
      .then((res) => {
        const result = [];
        const emergencyResult = [];
        // 긴급
        res.data.getNoticeByAdmin.emergency.map((item, index) => {
          const newItem = { ...item };
          newItem["table_idx"] = " ";
          // res.data.getNoticeByAdmin.emergency.length - index;
          newItem["type"] = "emergency";
          emergencyResult.push(newItem);
        });

        // 중요
        res.data.getNoticeByAdmin.important.map((item, index) => {
          const newItem = { ...item };
          newItem["table_idx"] = " ";
          // res.data.getNoticeByAdmin.emergency.length - index;
          newItem["type"] = "important";
          emergencyResult.push(newItem);
        });

        // 일반 공지
        res.data.getNoticeByAdmin.general.map((item, index) => {
          console.log("item...", item);
          const newItem = { ...item };
          newItem["table_idx"] =
            res.data.getNoticeByAdmin.general.length - index;
          newItem["type"] = "general";
          result.push(newItem);
        });
        setNoticeList(result);
        setEmergencyNoticeList(emergencyResult);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  const modifyDate = (date) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(parseInt(date)).toLocaleString("ko-KR", options);
  };

  const toggleNoticeContent = (noticeId) => {
    setSelectedNoticeId(selectedNoticeId === noticeId ? null : noticeId);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const showEmergencyNotice = () => {
    setVisibleNoticeCount(emergencyNoticeList.length);
    setShowClicked(!showClicked);
  };

  function NoticeImgItem(notice) {
    const isValidSrc =
      notice &&
      notice?.object_url?.includes("upload") &&
      notice?.object_url?.includes(".png");

    return (
      <div>
        {isValidSrc && (
          <img
            width={"300px"}
            style={{ maxHeight: "300px" }}
            src={notice.object_url}
            alt="이미지"
            onError={(e) => (e.currentTarget.style.display = "none")}
          />
        )}
      </div>
    );
  }

  const handleDownload = (url, fileName) => {
    const a = window.open(url, "_blank");
    if (a && a.focus) {
      a.focus();
    } else {
      alert("팝업 차단이 감지되었습니다. 새 창을 열어주세요.");
    }
  };

  return (
    <div>
      <Container>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          marginTop={"30px"}
          fontSize={"30px"}
          fontWeight={"700"}
          alignItems={"center"}
        >
          <p>공지사항</p>
        </Box>

        <TopContainer>
          <UpperBar />
        </TopContainer>

        <TableContainer
          component={Paper}
          sx={{ height: "100%", marginBottom: "10px" }}
        >
          <Table>
            <TableHead sx={{ height: "75px" }}>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  width={"8%"}
                  sx={{ fontSize: "18px" }}
                >
                  <Box fontWeight={600}>번호</Box>
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  width={"58%"}
                  sx={{ fontSize: "18px" }}
                >
                  <Box fontWeight={600}>제목</Box>
                </TableCell>
                <TableCell component="th" scope="row" sx={{ fontSize: "18px" }}>
                  <Box fontWeight={600} textAlign={"right"}>
                    작성자
                  </Box>
                </TableCell>
                <TableCell component="th" scope="row" sx={{ fontSize: "18px" }}>
                  <Box fontWeight={600} align={"right"}>
                    작성일
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>

            {emergencyNoticeList && (
              <TableBody>
                {emergencyNoticeList
                  // .slice(0, visibleNoticeCount)
                  .map((notice) => (
                    <React.Fragment key={notice.id}>
                      <TableRow onClick={() => toggleNoticeContent(notice.id)}>
                        <TableCell
                          component="th"
                          scope="row"
                          width={"8%"}
                          sx={{ fontSize: "16px" }}
                        >
                          {/* <Box>{notice.id}</Box> */}
                          <Box>{notice.table_idx}</Box>
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          width={"52%"}
                          sx={{ fontSize: "16px" }}
                        >
                          {notice.type == "general" ? (
                            <Box>{notice.title}</Box>
                          ) : notice.type == "emergency" ? (
                            <Box style={{ fontWeight: "bold", color: "red" }}>
                              {notice.title}
                            </Box>
                          ) : notice.type == "important" ? (
                            <Box style={{ fontWeight: "bold", color: "blue" }}>
                              {notice.title}
                            </Box>
                          ) : null}
                        </TableCell>
                        <TableCell
                          align="right"
                          component="th"
                          scope="row"
                          width={"20%"}
                          sx={{ fontSize: "16px" }}
                        >
                          <Box>{notice.name}</Box>
                        </TableCell>
                        <TableCell
                          align="right"
                          component="th"
                          scope="row"
                          width={"20%"}
                          sx={{ fontSize: "16px" }}
                        >
                          <Box textAlign={"right"}>
                            {modifyDate(notice.created_at)}
                          </Box>
                        </TableCell>
                      </TableRow>
                      {selectedNoticeId === notice.id && (
                        <React.Fragment>
                          <TableRow>
                            <TableCell colSpan={4}>
                              <Box
                                display="flex"
                                style={{
                                  overflowY: "auto",
                                  maxHeight: "400px",
                                }}
                              >
                                <Box margin={1} width={"100%"}>
                                  <ContentField>
                                    {renderTextWithLineBreaks(notice.content)}
                                  </ContentField>
                                </Box>
                                <Box>{NoticeImgItem(notice)}</Box>
                              </Box>
                            </TableCell>
                          </TableRow>
                          {notice.object_url && (
                            <TableRow>
                              <TableCell
                                colSpan={6}
                                sx={{ textAlign: "center" }}
                              >
                                <Button
                                  onClick={() =>
                                    handleDownload(
                                      notice.object_url,
                                      notice.title
                                    )
                                  }
                                >
                                  다운로드
                                </Button>
                              </TableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                        // <TableRow>
                        //   <TableCell colSpan={4} width={"100%"}>
                        //     <Box display={"flex"}>
                        //       <Box margin={1} width={"100%"}>
                        //         <ContentField>
                        //           {renderTextWithLineBreaks(notice.content)}
                        //         </ContentField>
                        //       </Box>
                        //       <Box>{NoticeImgItem(notice)}</Box>
                        //     </Box>
                        //   </TableCell>
                        // </TableRow>
                      )}
                    </React.Fragment>
                  ))}
                {/* {!showClicked && emergencyNoticeList.length >= 4 && (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Box onClick={showEmergencyNotice} textAlign={"center"}>
                        공지 더보기 ({emergencyNoticeList.length - 3}개)
                      </Box>
                    </TableCell>
                  </TableRow>
                )} */}
              </TableBody>
            )}

            <TableBody>
              {noticeList.map((notice) => (
                <React.Fragment key={notice.id}>
                  <TableRow onClick={() => toggleNoticeContent(notice.id)}>
                    <TableCell
                      component="th"
                      scope="row"
                      width={"8%"}
                      sx={{ fontSize: "16px" }}
                    >
                      {/* <Box>{notice.id}</Box> */}
                      <Box>{notice.table_idx}</Box>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      width={"52%"}
                      sx={{ fontSize: "16px" }}
                    >
                      {notice.type == "general" ? (
                        <Box>{notice.title}</Box>
                      ) : notice.type == "emergency" ? (
                        <Box style={{ fontWeight: "bold", color: "red" }}>
                          {notice.title}
                        </Box>
                      ) : notice.type == "important" ? (
                        <Box style={{ fontWeight: "bold", color: "blue" }}>
                          {notice.title}
                        </Box>
                      ) : null}
                    </TableCell>
                    <TableCell
                      align="right"
                      component="th"
                      scope="row"
                      width={"20%"}
                      sx={{ fontSize: "16px" }}
                    >
                      <Box>{notice.name}</Box>
                    </TableCell>
                    <TableCell
                      align="right"
                      component="th"
                      scope="row"
                      width={"20%"}
                      sx={{ fontSize: "16px" }}
                    >
                      <Box textAlign={"right"}>
                        {modifyDate(notice.created_at)}
                      </Box>
                    </TableCell>
                  </TableRow>
                  {selectedNoticeId === notice.id ? (
                    <React.Fragment>
                      <TableRow>
                        <TableCell colSpan={4}>
                          <Box display={"flex"}>
                            <Box margin={1} width={"100%"}>
                              <ContentField>
                                {renderTextWithLineBreaks(notice.content)}
                              </ContentField>
                            </Box>
                            <Box>{NoticeImgItem(notice)}</Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                      {notice.object_url && (
                        <TableRow>
                          <TableCell colSpan={6} sx={{ textAlign: "center" }}>
                            <Button
                              onClick={() =>
                                handleDownload(notice.object_url, notice.title)
                              }
                            >
                              다운로드
                            </Button>
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  ) : null}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          count={
            Math.ceil(noticeList.length / 10) === 1
              ? Math.ceil(noticeList.length / 10 + 1)
              : Math.ceil(noticeList.length / 10)
          }
          page={page}
          onChange={handleChangePage}
          showFirstButton
          showLastButton
        />
      </Container>
    </div>
  );
};

export default Notice;
