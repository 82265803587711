import { NavLink } from "react-router-dom";
import styled from "styled-components";

const SidebarPayment = ({ changePage }) => {
  return (
    <StyledSidebar>
      <StyledLink onClick={() => changePage()}>구매하기</StyledLink>
      <StyledLink onClick={() => changePage(2)}>구매내역</StyledLink>
    </StyledSidebar>
  );
};

export default SidebarPayment;

const StyledSidebar = styled.div`
  min-width: 150px;
  width: 150px;
  // background-color: #d0d0d0;
  height: 100vh;
  min-height: 100vh;
  padding: 15px;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.1);
`;

const StyledLink = styled(NavLink)`
  display: block;
  padding: 10px 20px;
  color: #333;
  text-decoration: none;
  margin: 5px 0;
  border-radius: 5px;

  &:hover {
    color: #fff;
    background-color: #555;
  }

  span {
    display: block;
    color: #666;
    font-size: 0.8rem;
  }

  &.active {
    color: #fff;
    background-color: #333;
  }
`;
