import React, { useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Paper, Button } from '@mui/material'
import { gql } from "@apollo/client";
import { client } from "../../client";
import GridItem from "./GridItem";
import Carousel from 'react-material-ui-carousel'

const BookList = () => {
    const GET_BOOK_LIST = gql`
    query getBookExhibitionByAdmin($type:Type) {
        getBookExhibitionByAdmin(type:$type) {
          title
          object_url
        }
      }
    `;


  const [ bookList, setBookList ] = useState([]);

  const fetchData = async () => {
    await client
        .query({
          query: GET_BOOK_LIST,
          variables : {
            type : 'SUPPORT'
          },
          fetchPolicy: "no-cache"
        })
        .then((res) => {
            setBookList(res.data.getBookExhibitionByAdmin)
          
        })
        .catch((err) => {
          console.log(err);
        });
  };

  useEffect(() => {
    fetchData()
  }, []);
  
  const chunkSize = 4;
  const chunkedItems = [];
  
  for (let i = 0; i < bookList.length; i += chunkSize) {
    chunkedItems.push(bookList.slice(i, i + chunkSize));
  }


  return (
    <Container>
        <SectionTitle>지원 교재</SectionTitle>
        <CarouselContainer>
            <Carousel>
                {chunkedItems.map((chunk, chunkIndex) => (
                <BookChunksContainer key={chunkIndex}>
                    {chunk.map((item, itemIndex) => (
                        <BookContainer>
                            <BookItem key={itemIndex} src={item.object_url} />
                        </BookContainer>
                    ))}
                </BookChunksContainer>
                ))}
            </Carousel>
        </CarouselContainer>
    </Container>
  );
};

export default BookList;

const Container = styled.div`

`


const CarouselContainer = styled.div`
    min-width: 1200px;
    margin : 2rem;
`

const BookChunksContainer = styled.div`
    display: flex; 
    flex-direction : row;
    flex
    flex: 0 0 auto; /* Flex Item의 크기를 자동으로 계산하지 않도록 설정 */
    width : 100%;
    height : 300px;
    margin-right: 10px; /* 각 배너 사이의 간격을 설정 */
    justify-content:space-evenly;
`
const BookContainer = styled.div`
    width: 30%;       
    
    transition: transform 0.3s ease; /* Add a transition for smooth scaling */

    &:hover {
        transform: scale(1.1); /* Increase the size on hover */
    }
`

const BookItem = styled.img`
    // width: 100%;
    height: 100%;  /* Match the height of the parent container */
    object-fit: cover;  /* Maintain aspect ratio and cover the container */
`
const SectionTitle = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: normal;
    max-height: 4.8rem;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 150%;
    text-align: left;
    margin-bottom: 1rem;
`
