import React, { useState, useEffect } from "react";
import * as S from "../Filter.style";
import ListComponent from "../pagenation/List";
import PaginationComponent from "../pagenation/PagenationComponent";
import { client } from "../../../client";
import {
  GET_ALL_DOCUTYPE,
  GET_DOCUMENT_URL,
  GET_FILTER_DATA,
} from "./gql/document.gql";

const ITEMS_PER_PAGE = 10;
const MAX_PAGES = 5;

const HighschoolSub = () => {
  const [documentData, setDocumentData] = useState([]);
  const [selectedCategory1, setSelectedCategory1] = useState([]);
  const [selectedCategory2, setSelectedCategory2] = useState([]);
  const [selectedDocumentTypes, setSelectedDocumentTypes] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const headers = [
    "번호",
    "분류1",
    "분류2",
    "자료",
    "업로드일",
    "다운로드수",
    "가격",
    "파일",
  ];
  const columnRatios = "0.1fr 0.3fr 0.25fr 0.4fr 0.2fr 0.15fr 0.2fr 0.1fr";

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSet, setPageSet] = useState(1);
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;

  const [categoryData, setCategoryData] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);

  const getFilterData = () => {
    client
      .query({
        query: GET_FILTER_DATA,
        variables: { docu_type1_id: 2 },
      })
      .then((res) => {
        const fetchedData = JSON.parse(res.data.getFilterListByDocumentType1);
        const category12 = fetchedData.filterList.find(
          (f) => f.col_name === "category1"
        ).value;
        setCategoryData(category12);

        const documentType = fetchedData.filterList.find(
          (f) => f.col_name === "docu_type2"
        ).value;
        setDocumentTypes(documentType);
      })
      .catch((err) => {
        console.error("Error fetching filter data", err);
      });
  };

  const fetchDataV2 = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      const res = await client.query({
        query: GET_ALL_DOCUTYPE,
        context: {
          headers: {
            authorization: accessToken ? `Bearer ${accessToken}` : "",
          },
        },
        variables: {
          document_search_input: {
            docu_type1_id: 2,
            docu_type2_id: selectedDocumentTypes,
            category1_id: selectedCategory1,
            category2_id: selectedCategory2,
            name: searchTerm == "" ? null : searchTerm,
            page: 1,
            pageSize: 300,
          },
        },
        fetchPolicy: "no-cache",
      });

      const documents = res.data.searchDocuTypeV2.map(async (item, index) => {
        const {
          id,
          category1_name,
          category2_name,
          name,
          created_at,
          downloaded,
          price,
          documentUrlList,
        } = item;
        const date = new Date(parseInt(created_at, 10));
        const formattedDate = date.toISOString().split("T")[0];

        const urls = item.documentUrlList;

        let hwpUrl;
        let pdfUrl;

        urls.map((item) => {
          item.type === "HWP"
            ? (hwpUrl = item.object_url)
            : (pdfUrl = item.object_url);
        });

        return {
          index: id,
          category1_name,
          category2_name,
          name,
          created_at: formattedDate,
          downloaded,
          price,
          // downloadUrl: documentUrlList,
          downloadUrl: {
            pdfUrl,
            hwpUrl,
            id,
            price,
          },
        };
      });

      Promise.all(documents).then(setDocumentData).catch(console.error);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    console.log("고등부교재...");
    const accessToken = localStorage.getItem("accessToken");
    await client
      .query({
        query: GET_ALL_DOCUTYPE,
        context: {
          headers: {
            authorization: accessToken ? `Bearer ${accessToken}` : "",
          },
        },
        variables: {
          document_search_input: {
            docu_type1_id: 2,
            docu_type2_id: selectedDocumentTypes,
            category1_id: selectedCategory1,
            category2_id: selectedCategory2,
            name: searchTerm == "" ? null : searchTerm,
            page: 1,
            pageSize: 300,
          },
          fetchPolicy: "no-cache",
        },
      })
      .then((res) => {
        console.log("res...", res);
        const documents = res.data.searchDocuTypeV2.map(async (item, index) => {
          const {
            id,
            category1_name,
            category2_name,
            name,
            created_at,
            downloaded,
            price,
            documentUrlList,
          } = item;
          const date = new Date(parseInt(created_at, 10));
          const formattedDate = date.toISOString().split("T")[0];
          try {
            const urls = documentUrlList;
            let pdfUrl;
            let hwpUrl;

            urls.map((item) => {
              item.type === "HWP"
                ? (hwpUrl = item.object_url)
                : (pdfUrl = item.object_url);
            });
            return {
              index: id,
              category1_name,
              category2_name,
              name,
              created_at: formattedDate,
              downloaded,
              price,
              // downloadUrl: documentUrlList,
              downloadUrl: {
                pdfUrl,
                hwpUrl,
                id,
                price,
              },
            };
          } catch (err) {
            console.log(err);
            return {
              index: id,
              category1_name,
              category2_name,
              name,
              created_at: formattedDate,
              downloaded,
              price,
              documentUrlList,
            };
          }
        });
        Promise.all(documents).then(setDocumentData).catch(console.error);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getFilterData();
    fetchData();
  }, []);

  const currentItems = documentData.slice(indexOfFirstItem, indexOfLastItem);

  const handleCheckboxCategory1Change = (category1Id) => {
    const newSelectedCategory1 = selectedCategory1.includes(category1Id)
      ? selectedCategory1.filter((id) => id !== category1Id)
      : [...selectedCategory1, category1Id];
    setSelectedCategory1(newSelectedCategory1);

    const fountItem = categoryData.find((item) => item.id === category1Id);
    const newSelectedCategory2 = fountItem.category2.map((item) => item.id);

    if (!selectedCategory1.includes(category1Id)) {
      setSelectedCategory2([...selectedCategory2, ...newSelectedCategory2]);
    } else {
      setSelectedCategory2(
        selectedCategory2.filter((id) => !newSelectedCategory2.includes(id))
      );
    }
  };

  const handleCheckboxCategory2Change = (category1Id, category2Id) => {
    const newSelectedCategory2 = selectedCategory2.includes(category2Id)
      ? selectedCategory2.filter((id) => id !== category2Id)
      : [...selectedCategory2, category2Id];
    setSelectedCategory2(newSelectedCategory2);

    const isAllCategory2Selected = categoryData
      .find((item) => item.id === category1Id)
      .category2.every((item) => newSelectedCategory2.includes(item.id));
    if (isAllCategory2Selected) {
      setSelectedCategory1([...selectedCategory1, category1Id]);
    } else {
      setSelectedCategory1(
        selectedCategory1.filter((id) => id !== category1Id)
      );
    }
  };

  const handleCheckboxDocumentTypeChange = (docytypeId) => {
    const newSelectedDocumentTypes = selectedDocumentTypes.includes(docytypeId)
      ? selectedDocumentTypes.filter((id) => id !== docytypeId)
      : [...selectedDocumentTypes, docytypeId];
    setSelectedDocumentTypes(newSelectedDocumentTypes);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    fetchData();
  };

  return (
    <S.Wrapper>
      <S.Container>
        <S.FilterContainer>
          <S.FilterSection>
            <S.FilterTitle>교재</S.FilterTitle>
            {categoryData.map((category1) => (
              <S.FilterMap key={category1.id}>
                <S.FilterCheckboxLabel key={category1.id}>
                  <S.FilterCheckbox
                    type="checkbox"
                    value={category1.name}
                    checked={selectedCategory1.includes(category1.id)}
                    onChange={() => handleCheckboxCategory1Change(category1.id)}
                  />
                  {category1.name}
                </S.FilterCheckboxLabel>
                <S.Divider>|</S.Divider>
                {category1.category2.map((option2) => (
                  <S.FilterCheckboxLabel key={option2.id}>
                    <S.FilterCheckbox
                      type="checkbox"
                      value={option2.name}
                      checked={selectedCategory2.includes(option2.id)}
                      onChange={() =>
                        handleCheckboxCategory2Change(category1.id, option2.id)
                      }
                    />
                    {option2.name}
                  </S.FilterCheckboxLabel>
                ))}
              </S.FilterMap>
            ))}
          </S.FilterSection>

          <S.FilterSection>
            <S.FilterTitle>자료</S.FilterTitle>
            {documentTypes.map((documentType) => (
              <S.FilterCheckboxLabel key={documentType.id}>
                <S.FilterCheckbox
                  type="checkbox"
                  value={documentType.name}
                  checked={selectedDocumentTypes.includes(documentType.id)}
                  onChange={() =>
                    handleCheckboxDocumentTypeChange(documentType.id)
                  }
                />
                {documentType.name}
              </S.FilterCheckboxLabel>
            ))}
          </S.FilterSection>
        </S.FilterContainer>
        <S.SearchContainer as="form" onSubmit={handleSearch}>
          <S.Input
            type="text"
            placeholder="검색어를 입력하세요"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <S.SearchButton type="submit">검색</S.SearchButton>
        </S.SearchContainer>
      </S.Container>
      {/* {JSON.stringify(documentData)} */}
      <ListComponent
        headers={headers}
        columnRatios={columnRatios}
        items={currentItems}
        fetchData={fetchDataV2}
      />
      <PaginationComponent
        data={documentData}
        currentPage={currentPage}
        setPage={setCurrentPage}
        pageSet={pageSet}
        setPageSet={setPageSet}
        itemsPerPage={ITEMS_PER_PAGE}
        maxPages={MAX_PAGES}
      />
    </S.Wrapper>
  );
};

export default HighschoolSub;
