// ModalComponent.js
import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import styled from "styled-components";
import Button from "@mui/material/Button";
import { gql } from "@apollo/client";
import { client } from "../../../client";
import FileUploaderV1 from "../admin-document/FileUploaderV1";

import AWS from "aws-sdk";
import FileUploader from "../admin-document/FileUploader";

const ModalContainer = styled(Box)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 70%;
  background-color: white; /* Change to white background */
  padding: 20px;
  z-index: 100;
  border: 1px solid #ccc; /* Add a subtle border */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */

  overflow-y: auto; /* 내용이 넘칠 때 스크롤이 가능하도록 설정합니다 */
`;

const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center; /* Center-align all children */
  margin-top: 30px;
  margin-bottom: 30px;
`;

const UserInfoItem = styled.div`
  display: flex; /* Display children in a row */
  flex-direction: row;
  margin-bottom: 10px; /* Add margin between child components */
`;

const BookModal = ({
  isOpen,
  data,
  handleCloseClick,
  refetchParentData,
  children,
}) => {
  console.log("data...", data);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedSampleFile, setSelectedSampleFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  // TODO env 에 넣기
  const ACCESS_KEY = "AKIA2MB36EXBJXFEOVGR";
  const SECRET_ACCESS_KEY = "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";
  const REGION = "ap-northeast-2";
  const S3_BUCKET = "seum-file";

  AWS.config.update({
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const onClickUpload = () => {
    const files = [selectedFile, selectedSampleFile];

    const uploadPromises = files.map((file) => {
      return new Promise((resolve, reject) => {
        const params = {
          ACL: "public-read",
          Body: file,
          Bucket: S3_BUCKET,
          Key: "upload/" + file?.name,
        };
        myBucket
          .putObject(params)
          .on("httpUploadProgress", (evt) => {
            console.log("evt...", evt);
            setProgress(Math.round((evt.loaded / evt.total) * 100));
            setShowAlert(true);
          })
          .send((err, data) => {
            if (err) {
              console.error("Error uploading:", err);
              reject(err);
            } else {
              console.log("Upload successful:", data);
              const objectUrl = myBucket.getSignedUrl("getObject", {
                Key: params.Key,
              });

              const objectUrlString = objectUrl.split("?")[0];

              console.log("Object URL:", objectUrlString);
              // handleInputChange("object_url", objectUrlString);
              resolve(objectUrlString);

              // console.log("확인 :", selectedFile);
              // handleUpdateClick(objectUrlString);
              // uploadSeondFile(selectedSampleFile);
            }
          });
      });
    });
    Promise.all(uploadPromises)
      .then((objectUrls) => {
        console.log("All uploads completed:", objectUrls);
        handleUpdateClick(objectUrls[0] ?? null, objectUrls[1] ?? null);
        // 여기에서 objectUrls을 사용하여 업로드된 파일들의 URL 리스트를 처리합니다.
      })
      .catch((error) => {
        console.error("One or more uploads failed:", error);
        alert("에러가 발생했습니다.");
        // 업로드 실패에 대한 처리를 수행합니다.
      });
  };

  const [editableData, setEditableData] = useState({});
  useEffect(() => {
    setEditableData({
      id: data?.id || 1,
      type: data?.type || "",
      is_view: data?.is_view || 1,
      view_order: data?.view_order || 0,
      title: data?.title || "",
      description: data?.description || "",
      object_url: data?.object_url || "",
      sample_url: data?.sample_url || "",
    });
  }, [data]);

  const handleInputChange = (key, value) => {
    if (key === "is_view" || key === "view_order") {
      value = isNaN(value) ? 1 : parseInt(value);
    }
    setEditableData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleUpdateClick = (objectUrl1, objectUrl2) => {
    const UPDATE_BOOK_INFO = gql`
      mutation updateBookExhibitionByAdmin(
        $id: Int
        $type: Type
        $is_view: Int
        $view_order: Int
        $title: String
        $description: String
        $object_url: String
        $sample_url: String
      ) {
        updateBookExhibitionByAdmin(
          id: $id
          type: $type
          is_view: $is_view
          view_order: $view_order
          title: $title
          description: $description
          object_url: $object_url
          sample_url: $sample_url
        ) {
          message
        }
      }
    `;

    let variables = {
      id: data.id,
      type: editableData.type,
      is_view: editableData.is_view,
      view_order: editableData.view_order,
      title: editableData.title,
      description: editableData.description,
      object_url: objectUrl1,
      sample_url: objectUrl2,
    };

    client
      .mutate({
        mutation: UPDATE_BOOK_INFO,
        variables: variables,
      })
      .then((res) => {
        console.log("update");
        console.log(res);
        refetchParentData();
        handleCloseClick();
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updatedTime = (params) => {
    const date = new Date(parseInt(params));
    return date.toLocaleString("ko-kr");
  };

  if (!isOpen) return null;

  return (
    <ModalContainer>
      <h2> 정보 수정 </h2>

      <UserInfoContainer>
        {Object.keys(data).map((key) => {
          if (key === "__typename") return null;
          return (
            <div key={key}>
              {key === "is_view" ||
              key === "view_order" ||
              key === "title" ||
              key === "description" ||
              key === "object_url" ||
              key === "sample_url" ||
              key === "created_at" ||
              key === "updated_at" ||
              key === "type" ? (
                <UserInfoItem>
                  <div>
                    <Box mr={"5px"}>{key}: </Box>
                  </div>
                  <div>
                    {key === "type" ? (
                      <select
                        value={editableData[key]}
                        onChange={(e) => handleInputChange(key, e.target.value)}
                      >
                        <option value="SUPPORT">SUPPORT</option>
                        <option value="SAMPLE">SAMPLE</option>
                      </select>
                    ) : key === "updated_at" || key === "created_at" ? (
                      <Box>{updatedTime(data[key])}</Box>
                    ) : key === "is_view" ? (
                      <div>
                        <select
                          value={editableData[key]}
                          onChange={(e) =>
                            handleInputChange(key, e.target.value)
                          }
                        >
                          <option value={1}>노출</option>
                          <option value={2}>비노출</option>
                        </select>
                      </div>
                    ) : key === "object_url" ? (
                      <div>
                        <Box
                          display={"flex"}
                          ml={"10px"}
                          height={"150px"}
                          width={"150px"}
                        >
                          <img src={data.object_url} />
                        </Box>
                        <UserInfoItem>
                          <FileUploaderV1
                            setSelectedFile={setSelectedFile}
                            selectedFile={selectedFile}
                            progress={progress}
                            setProgress={setProgress}
                            showAlert={showAlert}
                            setShowAlert={setShowAlert}
                          ></FileUploaderV1>
                        </UserInfoItem>
                      </div>
                    ) : key === "sample_url" ? (
                      <div>
                        <Box
                          display={"flex"}
                          ml={"10px"}
                          height={"150px"}
                          width={"200px"}
                        >
                          <a href={data.sample_url}> 자료 </a>
                        </Box>
                        <UserInfoItem>
                          <FileUploaderV1
                            setSelectedFile={setSelectedSampleFile}
                            selectedFile={selectedSampleFile}
                            progress={progress}
                            setProgress={setProgress}
                            showAlert={showAlert}
                            setShowAlert={setShowAlert}
                          ></FileUploaderV1>
                        </UserInfoItem>
                      </div>
                    ) : (
                      <input
                        type="text"
                        value={editableData[key]}
                        onChange={(e) => handleInputChange(key, e.target.value)}
                      />
                    )}
                  </div>
                </UserInfoItem>
              ) : (
                <UserInfoItem>
                  {key} : {data[key]}
                </UserInfoItem>
              )}
            </div>
          );
        })}
      </UserInfoContainer>
      <div>업로드 진행률 {progress}</div>
      <Button variant="contained" onClick={() => onClickUpload()}>
        저장
      </Button>

      <Button onClick={() => handleCloseClick()}> 닫기 </Button>
    </ModalContainer>
  );
};

export default BookModal;
