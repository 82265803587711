import React, { useState } from "react";
import styled from "styled-components";
import { client } from "../../../client";
import { GET_USER_INFO } from "../../common/gql/userinfo.gql";
import { PURCHASE_DOCUMENT } from "../page/gql/document.gql";
import { useNavigate } from "react-router-dom";

const FileDownloader = ({
  url,
  documentId,
  point,
  children,
  isRedownload = false,
}) => {
  const accessToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();

  const fileDownload = async () => {
    if (!url) {
      console.error("URL이 제공되지 않았습니다.");
      alert("다운로드할 파일 URL이 없습니다.");
      return;
    } else {
      console.log("URL", url);
    }

    // 예/아니오 응답 받기
    if (!isRedownload) {
      const confirmDownload = window.confirm(
        "포인트를 이용해 파일을 다운로드 하시겠습니까?"
      );
      if (!confirmDownload) {
        return;
      }
    }

    await client
      .query({
        query: GET_USER_INFO,
        context: {
          headers: {
            authorization: accessToken ? `Bearer ${accessToken}` : "",
          },
        },
        fetchPolicy: "no-cache",
      })
      .then(async (res) => {
        await client
          .mutate({
            mutation: PURCHASE_DOCUMENT,
            variables: {
              user_id: res.data.getUserInfo.id,
              document_id: documentId,
              point: point,
              is_redownload: isRedownload,
            },
          })
          .then(async (res) => {
            console.log("purchase document", res);
            const response = await fetch(url);
            if (!response.ok) throw new Error("서버 응답이 올바르지 않습니다.");
            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);

            // URL에서 파일명 추출 (옵션)
            const filename = url.split("/").pop().split("?")[0];

            // 다운로드 링크 생성 및 클릭
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute("download", decodeURIComponent(filename));
            document.body.appendChild(link);
            link.click();

            // 사용 후 다운로드 링크 제거
            document.body.removeChild(link);
            window.URL.revokeObjectURL(downloadUrl);
            if (res.data.purchaseDocument === "membership purchase") {
              alert("멤버십으로 다운로드되었어요!");
              // window.location.reload();
              return;
            } else if (res.data.purchaseDocument === "point purchase") {
              alert("포인트로 다운로드되었어요!");
              // window.location.reload();
            }
          })
          .catch((err) => {
            if (isRedownload) {
              alert(err);
            } else if (err.message === "DOWNLOAD_LOG_EXISTS") {
              // check error message is "DOWNLOAD_LOG_EXISTS"
              alert("이미 다운로드한 파일입니다. 마이페이지에서 확인해주세요.");
              navigate(`/mypage`);
            } else {
              console.error("Error purchasing document", err);
              alert("포인트가 부족합니다.");
              // TODO 포인트가 부족한 경우 직접 구매 모달을 띄운다.
              // input - userId, documentInfo (이름, amount)
              navigate(`/payment/singleDocument/${documentId}`);
            }
          });
      })
      .catch((err) => {
        console.error("Error fetching user id", err);
        alert("로그인이 필요합니다.");
      });
  };

  return (
    <BuyButton onClick={() => fileDownload()}>
      {/* <img src="/images/pdf-icon.png" alt="PDF 다운로드" /> */}
      {children}
      {/* {url} */}
    </BuyButton>
  );
};

const BuyButton = styled.div`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #f0f0f0;
  }

  &:active {
    background-color: #e0e0e0;
  }

  &:focus {
    outline: none;
  }

  img {
    width: 27px;
    height: 30px;
  }
`;

export default FileDownloader;
