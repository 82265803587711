
// 예시 입니다.
 const theme = {
    fonts: {
        title: 'Roboto, sans-serif',
        subtitle: 'Arial, sans-serif',
        normal: 'Helvetica, sans-serif',
        emphasis: 'Georgia, serif',
    },
    fontSize: {
        bigtitle: '3.2rem',
        title: '1.4rem',
        subtitle: '1.2rem',
        smalltitle: '1.1rem',
        normal: '1rem',
        emphasis: 'Georgia, serif',
    },
    colors: {
        primary: '#0064FF',
        secondary: '#2ecc71',
        accent: '#0064FF',
        accentHover: '#0064FF90',
        text: '#333',
        background: '#f8f8f8',
    },
};

export default theme;