import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import React, { useState } from "react";
import styled from "styled-components";
import { client } from "../../client"
import { GET_USER_INFO } from "../common/gql/userinfo.gql"
import { GET_DUCUMENT_BY_ID } from "../document/page/gql/document.gql";
import DocumentInfoContainer from "../document/detail/DocumentIInfoContainer";
import PaymentCashGuide from "./PaymentCashGuide";


const PaymentCash = () => {
  const [accessToken, setAccessToken] = useState(localStorage.getItem("accessToken"))
  const [userId, setUserId] = useState();
  const [docuInfo, setDocuInfo] = useState();
  let { documentId } = useParams();
  const [payDocumentId, setPayDocumentId] = useState(null);
   
  const {state} = useLocation();
  useEffect(() => {

    if(state) {
      setUserId(state.userId)
    } 
    window.scrollTo(0, 0);


  }, [])


  useEffect(() => {
    if (!documentId) return; // payDocumentId가 없으면(fetchDocument가 호출되면) 무시

    const fetchDocument = async () => {
      await client.query({
        query: GET_DUCUMENT_BY_ID,
        variables: { document_id: parseInt(documentId) },
        fetchPolicy: "no-cache",
      }).then((res) => {
        setDocuInfo({
          ...res.data.getDocument,
          docu_type1_name: res.data.getDocument.docu_type1['name'],
          docu_type2_name: res.data.getDocument.docu_type2['name']
        });
      });
    };

    fetchDocument();
  }, [documentId]);


  const accountInfo = (
    <div>
      <div>[ 계좌정보 ]</div>
      <div>신한은행 / 100-036-874700 / (주)에이펙스 김동재</div>
      <div>문의 010-5129-2284</div>
    </div>
  );

  return (
    <Container>
      <SinglePayTag>무통장입금 구매신청 완료</SinglePayTag>
      <DocumentInfoContainer docuInfo={docuInfo} accountInfo={accountInfo}>
        {/* <PaymentMethodContainer userId = {userInfo?.id} docuInfo= {docuInfo}></PaymentMethodContainer> */}
        <PaymentCashGuide docuInfo={docuInfo}></PaymentCashGuide>
      </DocumentInfoContainer>
    </Container>
  );
    }

export default PaymentCash;



const Container = styled.div`
  min-width: 800px;
  height: 80vh;
  width: 60vw;

  display: flex; 
  flex-direction : column;


  margin-left: auto;
  margin-right: auto;

  padding-top: 2rem;

`;


const SinglePayTag = styled.div`
  all: unset;
  background-color: navy;
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  display: flex;
  align-items: center;
  width: 162px;
  padding: 12px 20px;
  border-radius: 6px;
`