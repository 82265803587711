import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { client } from "../../../client";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";

// DataGrid

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, InputBase } from "@mui/material";
import UserFinancialModal from "./UserFinancialModal";

const GET_USER_LIST = gql`
  query getUserListByAdmin(
    $id: Int
    $name: String
    $email: String
    $phone_number: String
    $type: Type
    $region1: String
    $region2: String
    $page: Int
    $pageSize: Int
  ) {
    getUserListByAdmin(
      id: $id
      name: $name
      email: $email
      phone_number: $phone_number
      type: $type
      region1: $region1
      region2: $region2
      page: $page
      pageSize: $pageSize
    ) {
      id
      name
      email
      phone_number
      type
      region1
      region2
      ad_agreement
      current_point
      created_at
    }
  }
`;

const UserFinancialDetails = () => {
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const fetchData = async () => {
    await client
      .query({
        query: GET_USER_LIST,
        variables: {
          page: 1,
          pageSize: 10000,
          // Add other variables as needed
        },
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        setUserList(res.data.getUserListByAdmin);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  // const userList = data.getUserListByAdmin;
  const handleEditClick = (user) => {
    setSelectedUser(user);
    setModalOpen(!isModalOpen);
  };

  const handleCloseClick = () => {
    setModalOpen(false);
  };

  // DataGrid Columns

  const columns = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "name", headerName: "이름", flex: 1 },
    { field: "email", headerName: "이메일", flex: 1 },
    { field: "phone_number", headerName: "핸드폰번호", flex: 1 },
    { field: "type", headerName: "유형", flex: 1 },
    {
      field: "edit",
      headerName: "정보 조회",
      flex: 1,
      renderCell: (params) => (
        <Button
          onClick={() => {
            handleEditClick(params.row);
          }}
        >
          상세보기
        </Button>
      ),
    },
  ];

  return (
    <div>
      <Box
        margin={"7px"}
        display={"flex"}
        height={"70px"}
        justifyContent={"space-between"}
      >
        <Box ml={"5px"}>
          <h2>유저 결제 정보</h2>
        </Box>
      </Box>
      <Box>
        <DataGrid
          rows={userList}
          columns={columns}
          pageSize={5}
          components={{
            Toolbar: () => <GridToolbar />,
          }}
        />
      </Box>

      <UserFinancialModal
        isOpen={isModalOpen}
        handleCloseClick={handleCloseClick}
        refetchParentData={fetchData}
        data={selectedUser}
      ></UserFinancialModal>
    </div>
  );
};

export default UserFinancialDetails;
