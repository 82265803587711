// ModalComponent.js
import React, {useState, useEffect} from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { gql } from '@apollo/client';
import FileUploader from '../admin-document/FileUploader';
import { client } from '../../../client';
import { NavLink } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import AWS from 'aws-sdk';
import FileUploaderV1 from "../admin-document/FileUploaderV1";

const ModalContainer = styled(Box)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 70%;
  background-color: white; /* Change to white background */
  padding: 20px;
  z-index: 100;
  border: 1px solid #ccc; /* Add a subtle border */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
`;

const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  // align-items: center; /* Center-align all children */
  margin-top: 30px;
  margin-bottom: 30px;
`;

const UserInfoItem = styled.div`
  display: flex; /* Display children in a row */
  flex-direction: row;
  margin-bottom: 50px; /* Add margin between child components */
`;

const BannerModal = ({
  isOpen,
  data,
  handleCloseClick,
  refetchParentData,
  children,
}) => {
  // 파일 업로드 파트
  const [selectedFile, setSelectedFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  // TODO env 에 넣기
  const ACCESS_KEY = "AKIA2MB36EXBJXFEOVGR";
  const SECRET_ACCESS_KEY = "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";
  const REGION = "ap-northeast-2";
  const S3_BUCKET = "seum-file";

  AWS.config.update({
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const uploadFile = (file) => {
    console.log("uploadFile...!", file);
    if (file != null) {
      const params = {
        ACL: "public-read",
        Body: file,
        Bucket: S3_BUCKET,
        Key: "upload/" + file?.name,
      };

      myBucket
        .putObject(params)
        .on("httpUploadProgress", (evt) => {
          console.log("evt...", evt);
          setProgress(Math.round((evt.loaded / evt.total) * 100));
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            setSelectedFile(null);
          }, 3000);
        })
        .send((err, data) => {
          if (err) {
            console.error("Error uploading:", err);
          } else {
            console.log("Upload successful:", data);
            // 여기서 파일의 object URL을 사용할 수 있습니다.
            const objectUrl = myBucket.getSignedUrl("getObject", {
              Key: params.Key,
            });
            console.log("Object URL:", objectUrl.split("?")[0]);
            handleInputChange("image_url", objectUrl.split("?")[0]);
            alert(`파일업로드가 완료됐습니다. ${objectUrl.split("?")[0]}`);
            console.log("objectUrl", objectUrl.split("?")[0]);

            // banner update api
            handleBannerSave(objectUrl.split("?")[0]);
          }
        });
    } else {
      handleBannerSave(editableData["image_url"]);
    }
  };

  // useState, useEffect
  const [editableData, setEditableData] = useState({});
  useEffect(() => {
    console.log("data...", data);

    setEditableData({
      id: data?.id || "",
      title: data?.title || "",
      image_url: data?.image_url || "",
      visible: data?.visible,
    });
  }, [data]);

  // functions
  const handleInputChange = (key, value) => {
    setEditableData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleBannerSave = (banner_image_url) => {
    const UPDATE_BANNER_INFO = gql`
      mutation updateBannerImageByAdmin(
        $id: Int
        $title: String
        $image_url: String
        $visible: Boolean
      ) {
        updateBannerImageByAdmin(
          id: $id
          title: $title
          image_url: $image_url
          visible: $visible
        ) {
          message
        }

      }
    `;

    client
      .mutate({
        mutation: UPDATE_BANNER_INFO,
        variables: {
          ...editableData,
          image_url: banner_image_url,
          visible: editableData.visible == 1 ? true : false,
        },
      })
      .then((res) => {
        alert("업데이트가 완료됐습니다.", res);
        refetchParentData();
        handleCloseClick();
      })
      .catch((err) => {
        console.log(err);
      });
  };


  if (!isOpen) return null;

  return (
    <ModalContainer>
      <h2> 배너 정보 수정 </h2>
      {/* {JSON.stringify(data)}
        {JSON.stringify(editableData)} */}

      <UserInfoContainer>
        {Object.keys(data).map((key) => {
          if (key == "__typename") return null;

          return (
            <div key={key}>
              {key === "title" ? (
                <UserInfoItem>
                  <div>
                    <label>{key}: </label>
                  </div>
                  <div>
                    <input
                      type="text"
                      value={editableData[key]}
                      onChange={(e) => handleInputChange(key, e.target.value)}
                    />
                  </div>
                </UserInfoItem>
              ) : key === "visible" ? (
                <UserInfoItem>
                  {/* <div>{key} : {data[key]}</div> */}

                  <Typography>노출 여부</Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={editableData.visible}
                          onChange={(e) =>
                            handleInputChange("visible", e.target.checked)
                          }
                        />
                      }
                    />
                  </FormGroup>
                </UserInfoItem>
              ) : key === "id" ? (
                <UserInfoItem>
                  {key} : {data[key]}
                </UserInfoItem>
              ) : key === "image_url" ? (
                <UserInfoItem>
                  {"배너이미지 : "}
                  <FileUploaderV1
                    // uploadFile = {uploadFile}
                    setSelectedFile={setSelectedFile}
                    selectedFile={selectedFile}
                    progress={progress}
                    setProgress={setProgress}
                    showAlert={showAlert}
                    setShowAlert={setShowAlert}
                  ></FileUploaderV1>
                </UserInfoItem>
              ) : (
                // <div> 아아 </div>
                <UserInfoItem>
                  {key} : {data[key]}
                </UserInfoItem>
              )}
            </div>
          );
        })}
      </UserInfoContainer>
      <Button variant="contained" onClick={() => uploadFile(selectedFile)}>
        {" "}
        저장{" "}
      </Button>
      <Button onClick={() => handleCloseClick()}> 닫기 </Button>
    </ModalContainer>
  );
};

export default BannerModal;
