import { gql } from "@apollo/client";

export const GET_PAYMENT_PRODUCTS = gql`
  query getPaymentProductList($type: ProductType!) {
    getPaymentProductList(type: $type) {
      id
      name
      price
      type
      membership_duration
      point_amount
    }
  }
`;

export const MEMBERSHIP_CHECK = gql`
  query hasMembershipNow($user_id: Int!) {
    hasMembershipNow(user_id: $user_id)
  }
`;

export const GET_USER_ID = gql`
  query getUserInfo {
    getUserInfo {
      id
    }
  }
`;

export const HAS_MEMBERSHIP_NOW = gql`
  query hasMembershipNow($user_id: Int!) {
    hasMembershipNow(user_id: $user_id)
  }
`;

export const CREATE_POINT = gql`
  mutation createPoint($create_point_log_input: CreatePointInput!) {
    createPoint(create_point_log_input: $create_point_log_input)
  }
`;

export const GET_PAYLOG_LIST = gql`
  query getPaylogList($user_id: Int!) {
    getPaylogList(user_id: $user_id) {
      user_id
      cost
      point_change
      payment_way
      product_name
      document_id
      created_at
    }
  }
`;

export const CREATE_ORDER = gql`
  mutation createOrder(
    $product_id: Int
    $document_id: Int
    $payment_method: String!
    $pay_diff_amount: Boolean
  ) {
    createOrder(
      product_id: $product_id
      document_id: $document_id
      payment_method: $payment_method
      pay_diff_amount: $pay_diff_amount
    )
  }
`;

export const PURCHASE_PRODUCT = gql`
  mutation purchaseProduct($payment_log_id: Int!, $imp_uid: String!) {
    purchaseProduct(payment_log_id: $payment_log_id, imp_uid: $imp_uid)
  }
`;

export const GET_DOCUMENT_URL_BY_ID = gql`
  query getDocument($document_id:Int!) {
    getDocument(document_id:$document_id) {
      price
      document_url {
        object_url
      }
    }
  }

`