import React, { useEffect, useState } from "react";
import styled from "styled-components";

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { gql } from "@apollo/client";
import { client } from "../../../client";

import AWS from "aws-sdk";
import FileUploader from "../../admin/admin-document/FileUploader";

const StyledFormContainer = styled.div`
  max-width: 400px;
  margin: 0 auto;
`;

const ErrorReport = ({ isOpen, data, handleCloseClick, refetchParentData }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [showAlert, setShowAlert] = useState(false);

  const enumType = {
    DOWNLOAD: "DOWNLOAD",
    PAYMENT: "PAYMENT",
    ACCOUNT: "ACCOUNT",
    ETC: "ETC",
  };

  Object.freeze(enumType);

  //DecodeToken
  const token = localStorage.getItem("accessToken")?.replace("Bearer ", "");

  const base64Payload = token.split(".")[1];

  const base64 = base64Payload.replace(/-/g, "+").replace(/_/g, "/");

  const decodedJWT = JSON.parse(
    decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    )
  );

  const userId = decodedJWT.id;

  // TODO env 에 넣기
  const ACCESS_KEY = "AKIA2MB36EXBJXFEOVGR";
  const SECRET_ACCESS_KEY = "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";
  const REGION = "ap-northeast-2";
  const S3_BUCKET = "seum-file";

  AWS.config.update({
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const uploadFile = (file) => {
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: "upload/" + file?.name,
    };

    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        console.log("evt...", evt);
        // setProgress(Math.round((evt.loaded / evt.total) * 100))
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
          setSelectedFile(null);
        }, 3000);
      })
      .send((err, data) => {
        if (err) {
          console.error("Error uploading:", err);
        } else {
          console.log("Upload successful:", data);
          const objectUrl = myBucket.getSignedUrl("getObject", {
            Key: params.Key,
          });

          const objectUrlString = objectUrl.split("?")[0];

          setFormData((currentFormData) => ({
            ...currentFormData,
            object_url: objectUrlString,
          }));
          alert(`파일업로드가 완료됐습니다. ${objectUrlString}`);

          handleSubmit(objectUrlString);

          console.log("확인 :", objectUrlString);
        }
      });
  };

  const handleSubmit = (objectUrlString) => {
    const CREATE_FAQ_ADMIN = gql`
      mutation createFaqByAdmin(
        $user_id: Int!
        $visible: Boolean
        $type: faqTypeEnum!
        $title: String!
        $content: String
        $object_url: String
      ) {
        createFaqByAdmin(
          user_id: $user_id
          visible: $visible
          type: $type
          title: $title
          content: $content
          object_url: $object_url
        ) {
          message
        }
      }
    `;

    client
      .mutate({
        mutation: CREATE_FAQ_ADMIN,
        variables: {
          user_id: parseInt(formData.user_id, 10),
          visible: formData.visible,
          type: formData.type,
          title: formData.title,
          content: formData.content,
          object_url: objectUrlString,
        },
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        console.log("res:", res);
        console.log("확인2222 :", objectUrlString);
        window.alert("등록되었습니다.");

        window.location.href = "/admin/faq";
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // form
  const [formData, setFormData] = useState({
    user_id: userId,
    visible: null,
    type: enumType.DOWNLOAD,
    title: null,
    content: null,
    object_url: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    let updatedValue = value;

    setFormData({ ...formData, [name]: updatedValue });
  };

  return (
    <Box marginTop="50px">
      <h2>공지사항 등록</h2>
      <StyledFormContainer>
        <form>
          <FormControl fullWidth sx={{ marginBottom: 2, textAlign: "center" }}>
            <InputLabel id="type">문의 유형</InputLabel>
            <Select
              labelId="type"
              id="type"
              name="type"
              value={formData.type}
              defaultValue={enumType.DOWNLOAD}
              label="type"
              onChange={handleChange}
              sx={{ marginBottom: 2 }}
            >
              <MenuItem value={enumType.DOWNLOAD}>다운로드</MenuItem>
              <MenuItem value={enumType.PAYMENT}>결제</MenuItem>
              <MenuItem value={enumType.ACCOUNT}>계정</MenuItem>
              <MenuItem value={enumType.ETC}>기타</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ marginBottom: 2, textAlign: "center" }}>
            <InputLabel id="visible">노출</InputLabel>
            <Select
              labelId="visible"
              id="visible"
              name="visible"
              value={formData.visible}
              defaultValue={true}
              label="visible"
              onChange={handleChange}
              sx={{ marginBottom: 2 }}
            >
              <MenuItem value={true}>노출</MenuItem>
              <MenuItem value={false}>비노출</MenuItem>
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id="title"></InputLabel>
            <TextField
              label="제목"
              name="title"
              defaultValue={formData.title}
              onChange={handleChange}
              sx={{ marginBottom: 2, width: "400px" }}
            ></TextField>
          </FormControl>
          <FormControl>
            <InputLabel id="content"></InputLabel>
            <TextField
              label="내용"
              name="content"
              multiline
              rows={10}
              defaultValue={formData.content}
              onChange={handleChange}
              sx={{ marginBottom: 2, width: "400px" }}
            ></TextField>
          </FormControl>

          <FileUploader
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
          ></FileUploader>

          <Button variant="contained" onClick={() => uploadFile(selectedFile)}>
            업로드
          </Button>

          <Button onClick={handleCloseClick}>닫기</Button>
        </form>
      </StyledFormContainer>
    </Box>
  );
};

export default ErrorReport;
