import "./App.css";

import Header from "./component/common/Header";
import Home from "./page/Home";
import Login from "./page/Login";
import SignUp from "./page/SignUp";
import Search from "./page/Search";
import Payment from "./page/Payment";
import Paylog from "./component/payment/paylog";
import Admin from "./page/Admin";
import UserPage from "./component/admin/admin-user/UserPage";
import DocuType1Page from "./component/admin/admin-document/DocuType1Page";
import DocuUploadPage from "./component/admin/admin-document/DocuUploadPage";
import BookExhibition from "./component/admin/admin-book/BookExhibition";
import CreateBook from "./component/admin/admin-book/CreateBook";

import DocumentDetail from "./component/document/detail/DocumentDetail";
import Footer from "./component/common/Footer";
import { ThemeProvider } from "styled-components";
import theme from "./component/common/Theme";
import BannerPage from "./component/admin/admin-banner/BannerPage";

import FAQ from "./component/customer/FAQ";
import Notice from "./component/customer/Notice";
import CreateErrorReport from "./component/customer/ErrorReport/CreateErrorReport";
import InquiryRecords from "./component/customer/ErrorReport/InquiryRecords";
import CreateInquiryForm from "./component/customer/ErrorReport/CreateInquiryForm";
import ErrorRecords from "./component/customer/ErrorReport/ErrorRecords";
import InquiryAdmin from "./component/admin/admin-errorReport/InquiryAdmin";

import ErrorAdmin from "./component/admin/admin-errorReport/ErrorAdmin";
import NoticeAdmin from "./component/admin/admin-notice/NoticeAdmin";
import CreateNoticeAdmin from "./component/admin/admin-notice/CreateNoticeAdmin";
import FaqAdmin from "./component/admin/admin-faq/FaqAdmin";
import CreateFaqAdmin from "./component/admin/admin-faq/CreateFaqAdmin";

import PaymentCash from "./component/payment/PaymentCash";
import PaymentSingleDocument from "./component/payment/PaymentSingleDocument";
import CashPayAdmin from "./component/admin/admin-cash-pay/CashPayAdmin";
import MyPage from "./component/common/MyPage/MyPage";
import UserFinancialDetails from "./component/admin/admin-user-financial/UserFinancialDetails";
import Modal from "./component/common/Modal";

import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import SearchId from "./page/find/SearchId";
import SearchPW from "./page/find/SearchPW";

function App() {
  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    const dismissedModal = localStorage.getItem("dismissedModal");
    if (dismissedModal) {
      setShowModal(false);
    }
  }, []);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="signup" element={<SignUp />} />
            <Route path="login" element={<Login />} />
            <Route path="/mypage" element={<MyPage />} />
            <Route path="search/*" element={<Search />} />
            <Route path="payment" element={<Payment />} />
            <Route path="payment/cash/:documentId" element={<PaymentCash />} />
            <Route
              path="payment/singleDocument/:documentId"
              element={<PaymentSingleDocument />}
            />
            <Route path="admin" element={<Admin />} />
            <Route path="document/:id" element={<DocumentDetail />} />
            {/* <Route path="admin" element={<Admin />} /> */}
            <Route path="admin/user" element={<UserPage />} />
            <Route
              path="/admin/user-finance"
              element={<UserFinancialDetails />}
            />
            <Route path="admin/banner" element={<BannerPage />} />
            <Route path="admin/upload" element={<DocuUploadPage />} />
            <Route path="admin/document1" element={<DocuType1Page />} />
            <Route path="admin/book" element={<BookExhibition />} />
            <Route path="admin/uploadBook" element={<CreateBook />} />
            <Route path="admin/error" element={<ErrorAdmin />} />
            <Route path="admin/inquiry" element={<InquiryAdmin />} />
            <Route path="/admin/notice" element={<NoticeAdmin />} />
            <Route path="/admin/faq" element={<FaqAdmin />} />
            <Route path="/admin/faq/create" element={<CreateFaqAdmin />} />
            <Route
              path="/admin/notice/create"
              element={<CreateNoticeAdmin />}
            />
            <Route path="/admin/cashPay" element={<CashPayAdmin />} />

            <Route path="customer/faq" element={<FAQ />} />
            <Route path="customer/notice" element={<Notice />} />
            <Route path="customer/records" element={<InquiryRecords />} />
            <Route path="customer/errors" element={<ErrorRecords />} />
            <Route
              path="customer/records/edit"
              element={<CreateErrorReport />}
            />
            <Route
              path="customer/records/inquiry"
              element={<CreateInquiryForm />}
            />

            <Route path="search/id" element={<SearchId />} />
            <Route path="search/password" element={<SearchPW />} />
          </Routes>

          <Footer />
        </Router>
        {showModal && (
          <Modal
            topPosition={20}
            // title="확인 요망"
            description="Modal Description"
            img_src="https://seum-file.s3.ap-northeast-2.amazonaws.com/KakaoTalk_Photo_2024-05-04-15-37-52.png"
          />
        )}
        {/* {showModal && (
          <Modal
            topPosition={430}
            // title="무통장입금 승인불가"
            description="Modal Description"
            img_src="https://seum-file.s3.ap-northeast-2.amazonaws.com/KakaoTalk_Photo_2024-05-04-15-37-56.png"
          />
        )} */}
      </ThemeProvider>
    </div>
  );
}

export default App;
