import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Pagination,
} from "@mui/material";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import FileDownloader from "../../document/download/FileDownloader";
import SidebarMyPage from "./SideBar";
import MyPageMembership from "./MyPageMembership";
import { GET_PENDING_CASH_PAYMENT } from "../gql/mypage.gql";
import { client } from "../../../client";
import { JWTDecoder } from "../../customer/style/Customer.module";

const MyPagePendingPayment = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const userId = JWTDecoder();

  useEffect(() => {
    client
      .query({
        query: GET_PENDING_CASH_PAYMENT,
        variables: {
          user_id: userId,
        },
      })
      .then((res) => {
        setData(res.data.getPendingCashPaymentMypage);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const modifyDate = (date) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(parseInt(date)).toLocaleString("ko-KR", options);
  };

  return (
    <Box display={"flex"} justifyContent={"center"}>
      <Wrapper>
        {/* {paymentLogs.length} */}
        {/* {JSON.stringify(data)} */}
        <MyPageContainer>
          <Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>신청일</TableCell>
                  <TableCell>자료명</TableCell>
                  <TableCell>금액</TableCell>
                  <TableCell>상태</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((paylog) => {
                  const paymentProductName =
                    paylog?.payment_log?.payment_product?.name;
                  const documentName = paylog?.document?.name;
                  return (
                    <TableRow key={paylog.id}>
                      <TableCell>
                        <Box>{modifyDate(paylog.created_at)}</Box>
                      </TableCell>
                      <TableCell>
                        <Box>
                          {documentName != null
                            ? documentName
                            : paymentProductName}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box>{paylog?.document?.price}</Box>
                      </TableCell>
                      <TableCell>
                        <Box>{"입금대기중"}</Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </MyPageContainer>
      </Wrapper>
    </Box>
  );
};

const Wrapper = styled.div`
  width: 1000px;
`;

const MyPageContainer = styled.div`
  margin: 20px;
  border: 1px solid black;
`;

export default MyPagePendingPayment;
