import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import FileDownloader from "../download/FileDownloader";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {
  GET_ALL_DOCUTYPE,
  GET_DUCUMENT_BY_ID,
  PURCHASE_DOCUMENT,
} from "../page/gql/document.gql";
import { client } from "../../../client";
import { GET_USER_INFO } from "../../common/gql/userinfo.gql";
import FileDownloaderList from "../download/FileDownloaderList";

const DocumentDetail = () => {
  const { state } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { item } = state || {};

  const [data, setData] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const accessToken = localStorage.getItem("accessToken");
  const [hwpUrl, setHwpUrl] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);

  useEffect(() => {
    refetchData();
  }, []);

  const download = () => {
    if (!isLoggedIn) {
      alert("로그인을 먼저 진행해주세요");
      navigate("/login");
    }
  };

  const refetchData = async () => {
    try {
      const res = await client.query({
        query: GET_ALL_DOCUTYPE,
        context: {
          authorization: accessToken ? `Bearer ${accessToken}` : "",
        },
        variables: {
          document_search_input: {
            name: item.name,
            page: 1,
            pageSize: 1,
          },
        },
        fetchPolicy: "no-cache",
      });

      const documents = res.data.searchDocuTypeV2[0];

      documents.documentUrlList.forEach((document) => {
        if (document.type === "PDF") {
          setPdfUrl(document.object_url);
        } else if (document.type === "HWP") {
          setHwpUrl(document.object_url);
        }
      });
    } catch (err) {
      console.error("fetch error ", err);
    }
  };

  const fetchDocument = async () => {
    const fetchResult = await client
      .query({
        query: GET_DUCUMENT_BY_ID,
        variables: { document_id: parseInt(id) },
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        setData({
          ...res.data.getDocument,
          docu_type1_name: res.data.getDocument.docu_type1["name"],
          docu_type2_name: res.data.getDocument.docu_type2["name"],
        });
      });
  };

  useEffect(() => {
    if (!state || state["category1_name"] == null || state == null) {
      fetchDocument(id);
    } else {
      setData(state?.item); // 페이지를 맨 위로 스크롤하는 함수
    }
    setIsLoggedIn(localStorage.getItem("isLoggedIn") === "true");
    window.scrollTo(0, 0);
  }, []);

  const promotePayment = async (item) => {
    const isRedownload = false;

    if (!isRedownload) {
      const confirmDownload = window.confirm(
        "포인트를 이용해 파일을 다운로드 하시겠습니까?"
      );
      if (!confirmDownload) {
        return;
      }
    }

    try {
      const documentRes = await client.query({
        query: GET_DUCUMENT_BY_ID,
        variables: {
          document_id: parseInt(item.index),
        },
      });

      const documentUrl =
        documentRes.data.getDocument.document_url[0].object_url;

      const userRes = await client.query({
        query: GET_USER_INFO,
        context: {
          headers: {
            authorization: accessToken ? `Bearer ${accessToken}` : "",
          },
        },
        fetchPolicy: "no-cache",
      });

      await client
        .mutate({
          mutation: PURCHASE_DOCUMENT,
          variables: {
            user_id: userRes.data.getUserInfo.id,
            document_id: parseInt(id),
            point: item.price,
            is_redownload: isRedownload,
          },
        })
        .then(async (res) => {
          if (res.data.purchaseDocument === "membership purchase") {
            alert(
              "멤버십으로 구매가 완료 되었습니다. pdf와 한글파일 모두 자유롭게 다운로드 가능합니다"
            );
            refetchData();
          } else if (res.data.purchaseDocument === "point purchase") {
            alert(
              "포인트로 구매가 완료 되었습니다. pdf와 한글파일 모두 자유롭게 다운로드 가능합니다"
            );
            refetchData();
          }
        })
        .catch((err) => {
          console.error("Error purchasing document", err);
          if (isRedownload) {
            console.log(err);
            // alert(err);
          } else {
            console.error("Error purchasing document", err);
            alert("포인트가 부족합니다.");
            // TODO 포인트가 부족한 경우 직접 구매 모달을 띄운다.
            // input - userId, documentInfo (이름, amount)
            navigate(`/payment/singleDocument/${item.index}`);
          }
        });
    } catch (err) {
      console.error("Error fetching user id", err);
      alert("로그인이 필요합니다.");
    }
  };


  return (
    <DetailContainer>
      <TitleContainer>{data?.name}</TitleContainer>
      <DocutypeContainer>
        <DocutypeItem>{data?.docu_type1_name}</DocutypeItem>
        <DocutypeItem>{data?.docu_type2_name}</DocutypeItem>
      </DocutypeContainer>

      <PropertyContainer>
        {data?.grade ? (
          <PropertyRow>
            <PropertyTitle> 학년 </PropertyTitle>
            <PropertyValue> {data?.grade} </PropertyValue>
          </PropertyRow>
        ) : null}
        {data?.year ? (
          <PropertyRow>
            <PropertyTitle> 시행 연도 </PropertyTitle>
            <PropertyValue> {data?.year} 년 </PropertyValue>
          </PropertyRow>
        ) : null}
        {data?.month ? (
          <PropertyRow>
            <PropertyTitle> 시행 월 </PropertyTitle>
            <PropertyValue> {data?.month} 월 </PropertyValue>
          </PropertyRow>
        ) : null}
        {data?.subject ? (
          <PropertyRow>
            <PropertyTitle> 과목 </PropertyTitle>
            <PropertyValue> {data?.subject} </PropertyValue>
          </PropertyRow>
        ) : null}
        {data?.publisher ? (
          <PropertyRow>
            <PropertyTitle> 출판사 </PropertyTitle>
            <PropertyValue> {data?.publisher} </PropertyValue>
          </PropertyRow>
        ) : null}
        {data?.category1_name ? (
          <PropertyRow>
            <PropertyTitle> 분류1 </PropertyTitle>
            <PropertyValue> {data?.category1_name} </PropertyValue>
          </PropertyRow>
        ) : null}
        {data?.category2_name ? (
          <PropertyRow>
            <PropertyTitle> 분류2 </PropertyTitle>
            <PropertyValue> {data?.category2_name} </PropertyValue>
          </PropertyRow>
        ) : null}
        <PropertyRow>
          <PropertyTitle> 다운로드 수 </PropertyTitle>
          <PropertyValue> {data?.downloaded} </PropertyValue>
        </PropertyRow>
      </PropertyContainer>
      <ButtonContainer
        onClick={() => {
          download();
        }}
      >
        <ButtonContainerItem>
          {" "}
          <PriceText>가격</PriceText> {"   "}
          {data?.price}원
        </ButtonContainerItem>
        <ButtonContainerItem></ButtonContainerItem>
        {/* <ButtonContainerItem>{JSON.stringify(data?.documentUrlList[0].object_url)}</ButtonContainerItem> */}

        <Box display={"flex"} justifyContent={"space-between"} width={80}>
          {hwpUrl ? (
            <FileDownloaderList
              url={hwpUrl}
              documentId={parseInt(id)}
              point={item?.price}
              isRedownload={true}
              fetchData={refetchData}
            >
              <img src={"/images/hwp-icon.jpeg"} alt=" 다운로드" />
            </FileDownloaderList>
          ) : null}
          {pdfUrl ? (
            <FileDownloaderList
              url={pdfUrl}
              documentId={parseInt(id)}
              point={item?.price}
              isRedownload={true}
              fetchData={refetchData}
            >
              <img src={"/images/pdf-icon.png"} alt=" 다운로드" />
            </FileDownloaderList>
          ) : null}
          {pdfUrl == null && hwpUrl == null ? (
            <Box display={"flex"} alignItems={"center"}>
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMwAAADACAMAAAB/Pny7AAAAYFBMVEX///8AAADx8fF7e3uJiYldXV1JSUnPz89/f3+MjIy/v79BQUH39/cuLi6oqKgMDAw2Njbh4eEfHx/o6OixsbGgoKBOTk4XFxdmZmaUlJRsbGzFxcXa2tooKChUVFS3t7c3fQqgAAADBElEQVR4nO3dC3aqMBSFYRpRUCOoiPVVO/9Z3vai1geEACFn49rfBJp/LUNCUBoE7qlknn6YpedjD3+4B+pQU1LYSI/TSjS3iplq6YHaiCZWMbGSHqgNxqBiDCrGoGIMKsagYgwqxqBiDCrGoGIMKsagYgwqxqBiDCrGoGIMKsagYgwqxqBiDCrGoGIMKsagYgwqxqBiDCrGoGIMKsagYgwqxqBiDCrGoLKMCd/pN2freYnJbi/TqFbRrMQxX1rFVElFYradxlwtE2jJempZCLQEgd3UaGwmEhP10hKKtARB0kPLQWr10XvnLZOTUMvPxTl2HSP5w/rV2G1LLthi/XN5S9LbtdmXu5az3IS5SD5dtSxkVph7euqoJZWdMAXLV2bUGkHcEqyc7GvmEvvLEicXMZF0xdWxe0si3fCn8y4NY8IUdNitZQkyYQpq16UlhZkwhVOXSxrcm46iReuWEO8EbbNu2TJeSQ/9ld6326UtIV8OpkZtWtZAK8y9rM1JWgy0wjxocZQ2x5v8V813aYCT/2bTsOVbesBGzU6fYunhmukmt2pb6dHWyc7WLVjby1LWp09r+QOMejO7h01pjrrCPMitdmkj3BXmnorr3tb644y8wtxT9UfQyyFMmEJW90FbI5z42arb1+ykB9iI+fRpKT28hkz7ms+hTP4bw60a9vayTPUlTeqBchenihvP3SBW/mfH0n3NdnAT5j+dlDwjBHg+1o6evu5rQA9jLOiXI2jYwxgL6mna7PDvxwxWD7s0wW+TOHH/3aevoU7+m/zWAvFwvKPbt4Wm0iNx4HpJO0gPxIks/F08AR8ptaKOeTIb8ALz5H1KfNLfcVgFbCeVVA40vnyMleEbCWfh0T8xnGNfri+mmLHw6J8YzuOmjJHEGMZ4wBjGeMAYxnjAGMZ4wBjGeMAYxnjAGMZ4wBjGeMAYxnjAGMZ4wBjGeMAYxnjAGMZ4wJg3jdEKiO4Ws4hHQGLDezosYoaDMagYg4oxqBiDijGoGIPqEtPDO6QF/P4/gX8280euK7HuygAAAABJRU5ErkJggg=="
                width="30px"
                alt="결제"
                onClick={() => promotePayment(item)}
                style={{ cursor: "pointer" }}
              />
            </Box>
          ) : null}
        </Box>
      </ButtonContainer>
    </DetailContainer>

    // grade
    // year
    // month

    // subject
    // publisher

    // category1_name: null
    // category2_name: null
  );
};

const PriceText = styled.div`
  margin-right: 30px;
`;

const ButtonContainerItem = styled.div`
  display: flex; /* Flexbox 사용 */
  flex-direction: row;

  font-size: 20px;
  font-weight: 600;

  padding-top: 16px;
  margin-bottom: 12px;

  color: rgb(28, 31, 46);

  &:last-child {
    margin-right: 0;
    margin-left: auto;

    padding-top: 16px;
    padding-bottom: 16px;

    display: flex; /* Flexbox 사용 */
    text-align: center; /* 텍스트를 가운데 정렬합니다 */
    justify-content: center; /* 텍스트를 가운데 정렬합니다 */

    // height: 56px;
    width: 200px;
    font-weight: 600;
    color: rgb(255, 255, 255);
    background-color: #0064ff;
    border-radius: 6px;
    border: none;

    padding-left: 30px;
    padding-right: 30px;
    text-align: center;

    cursor: pointer;

    gap: 10px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between; /* 좌우로 간격을 두고 요소를 배치합니다 */
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 10px;
  padding-right: 30px;
`;

const PropertyTitle = styled.div`
  width: 20%;
  color: rgb(137, 144, 171);

  display: flex;
  flex-direction: row;
`;

const PropertyValue = styled.div`
  width: 70%;
  color: rgb(28, 31, 46);
  font-family: Pretendard, sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;

  display: flex;
  flex-direction: row;
`;

const PropertyRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  font-size: 20px;
`;

const PropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  padding-top: 30px;
  padding-bottom: 30px;

  /* 아래쪽 border 속성을 이용하여 수평선 스타일링 */
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); /* 연한색 회색 */
`;

const DocutypeItem = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: rgb(79, 85, 107);

  display: flex;
  position: relative;
  align-items: center;
  line-height: 22px;

  border-radius: 3px;
  border: 1px solid rgb(225, 228, 237);

  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;

  height: 30px;
  transition: all 0.3s ease 0s;
`;

const DocutypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;

  padding-top: 10px;
  padding-bottom: 30px;

  /* 아래쪽 border 속성을 이용하여 수평선 스타일링 */
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); /* 연한색 회색 */
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 28px;
  font-weight: 700;
  color: rgb(28, 31, 46);
  line-height: 1.5;
  word-break: keep-all;
  margin-top: 4px;
`;

const DetailContainer = styled.div`
  min-width: 800px;
  height: 80vh;
  width: 60vw;

  display: flex;
  flex-direction: column;

  margin-left: auto;
  margin-right: auto;

  padding-top: 5rem;
`;

export default DocumentDetail;
