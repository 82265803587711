import React, { useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Paper, Button } from "@mui/material";
import { gql } from "@apollo/client";
import { client } from "../../client";

const GridItem = ({ index, title, subtitle, lastestDocumentList }) => {
  // console.log("lastestDocumentList...", lastestDocumentList);

  const handleItemClick = (item) => {
    // 여기에서 itemId를 이용하여 navigation을 처리합니다.
    navigate(`/document/${item.id}`, { state: { item: item } });
  };
  const navigate = useNavigate();
  const [months, setMonths] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [documentList, setDocumentList] = useState([]);

  useEffect(() => {
    const fetchDocumentData = async (data) => {
      try {
        const newDataPromises = data.map(async (item) => {
          const {
            id,
            grade,
            year,
            docu_type1_name,
            docu_type2_name,
            month_id,
            name,
            created_at,
            downloaded,
            price,
            documentUrlList,
          } = item;
          const date = new Date(parseInt(created_at, 10));
          const formattedDate = date.toISOString().split("T")[0];

          let hwpUrl;
          let pdfUrl;

          documentUrlList.forEach((urlItem) => {
            if (urlItem.type === "HWP") {
              hwpUrl = urlItem.object_url;
            } else if (urlItem.type === "PDF") {
              pdfUrl = urlItem.object_url;
            }
          });

          return {
            index: id,
            id,
            grade,
            year,
            docu_type1_name,
            docu_type2_name,
            month: months.find((m) => m.id == month_id)?.name,
            name,
            uploadedDate: formattedDate,
            downloaded,
            price,
            downloadUrl: {
              pdfUrl,
              hwpUrl,
              id,
              price,
            },
          };
        });

        const newData = await Promise.all(newDataPromises);
        setDocumentList(newData);
      } catch (error) {
        console.error("에러", error);
      }
    };

    if (index == 1) {
      fetchDocumentData(lastestDocumentList?.mockExamList);
    } else if (index == 2) {
      fetchDocumentData(lastestDocumentList?.highSchoolSecondTeachList);
    } else if (index == 3) {
      fetchDocumentData(lastestDocumentList?.specialLectureSATList);
    } else if (index == 4) {
      fetchDocumentData(lastestDocumentList?.highSchoolTextbookList);
    }
  }, [lastestDocumentList]);

  return (
    <GridItemContainer>
      <TitleContainer>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </TitleContainer>
      <ItemContainer>
        {documentList?.map((item, index) => {
          return (
            <Item key={index} onClick={() => handleItemClick(item)}>
              {" "}
              {item?.["name"]}
            </Item>
          );
        })}
      </ItemContainer>
    </GridItemContainer>
  );
};

export default GridItem;

const GridItemContainer = styled.div`
  flex: 1;
  min-width: calc(50% - 32px);
  margin-bottom: 20px;
  height: 320px;
  width: 446px;
  box-sizing: border-box;
  // background-color: #f4f5f8;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 0.2rem;
  padding-left: 1.2rem;
`;

const TitleContainer = styled.div`
  // background-color: #f4f5f8;
  display: flex;
  flex-direction: row;
  padding-bottom: 1rem;
  padding-top: 1rem;
  padding-left: 0.8rem;
  border: 1px solid #00000040; /* 테두리 추가 및 색상 설정 */

  border-top-left-radius: 15px; /* 윗 부분 왼쪽 둥글게 설정 */
  border-top-right-radius: 15px; /* 윗 부분 오른쪽 둥글게 설정 */
  border-bottom: none; /* 아래 라인 보더 없애기 */

  &:hover {
    background-color: rgba(
      0,
      0,
      0,
      0.05
    ); /* hover 상태일 때 아주 옅은 회색으로 배경색 변경 */
  }
`;
const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  margin-bottom: 1.4rem;
  padding-top: 1.6rem;
  padding-left: 1.2rem;
  border: 1px solid #00000040; /* 테두리 추가 및 색상 설정 */
`;

const Title = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: normal;
  max-height: 4.8rem;
  font-weight: 900;
  font-size: ${(props) => props.theme.fontSize.title};
  line-height: 150%;
  text-align: left;
  margin-left: 1rem;

  display: flex;
  align-items: center; /* 수직 가운데 정렬 */
  color: navy; /* 호버 시 테두리 색상 변경 */
`;

const Subtitle = styled.div`
  font-size: 0.8rem;
  text-align: left;
  font-size: ${(props) => props.theme.fontSize.subtitle};
  margin-left: 1rem;

  display: flex;
  align-items: center; /* 수직 가운데 정렬 */
  color: gray; /* 글자 색상을 회색으로 설정 */
`;

const Item = styled.li`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 360px;

  font-size: ${(props) => props.theme.fontSize.smalltitle};
  font-weight: 500;

  line-height: 100%;
  text-align: left;
  margin-left: 1rem;
  margin-bottom: 1.6rem;

  &:hover {
    font-weight: bold; /* Increase font weight on hover */
    cursor: pointer; /* Change cursor to pointer (hand) on hover */
    opacity: 0.7; /* Reduce opacity on hover */
  }
`;
