import React, { useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Carousel from 'react-material-ui-carousel'
import { Paper, Button } from '@mui/material'
import { gql } from "@apollo/client";
import { client } from "../../client";

const Banner = () => {
  const GET_BANNER_LIST = gql`
    query getVisibleBannerImageList {
      getVisibleBannerImageList {
        id
        title
        image_url
        visible
      }
    }
  `;
  
  const [ bannerList, setBannerList ] = useState([]);

  const fetchData = async () => {
    await client
        .query({
          query: GET_BANNER_LIST,
          fetchPolicy: "no-cache"
        })
        .then((res) => {
          setBannerList(res.data.getVisibleBannerImageList);
          
        })
        .catch((err) => {
          console.log(err);
        });
  };

  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);


  useEffect(() => {
    fetchData()
  }, []);
  
  const items = [
    {
        name: "Random Name #2",
        description: "Hello World!", 
        img: "https://img.iipamaster.com/upload/bnr/20230607/20230607161158_8193.jpg",
    },
    {
        name: "Random Name #1",
        description: "Probably the most random thing you have ever seen!",
        img: "https://img.iipamaster.com/upload/bnr/20230824/20230824111221_5828.jpg"
    },
    {
        name: "Random Name #2",
        description: "Hello World!", 
        img: "https://img.iipamaster.com/upload/bnr/20231207/20231207161616_9280.png",
    },
]

  return (
    <BannerContainer>
        <Carousel>
            {
                bannerList.map( (item, i) => 
                <BannerItem key={i} item={item} > 
                    <BannerImg src={item['image_url']}/>
                </BannerItem> 
                )
            }
        </Carousel>
    </BannerContainer>
  );
};

export default Banner;


const BannerContainer = styled.div`
    min-width: 1200px;
    min-height: 300px;
    width: 100%;
    margin: 0 auto; /* 가운데 정렬을 위해 추가 */
    overflow: hidden; /* 내용이 컨테이너를 벗어나는 것을 방지 */
    margin-bottom : 2rem;
`;

const BannerItem = styled.div`
    flex: 0 0 auto; /* Flex Item의 크기를 자동으로 계산하지 않도록 설정 */
    width: 100%;
    min-height: 300px;
    margin-right: 10px; /* 각 배너 사이의 간격을 설정 */
`

const BannerImg = styled.img`
    height: 100%; /* 세로 방향으로도 컨테이너를 채우도록 설정 */
    width: 100%;
    min-height: 300px;
    object-fit: cover; /* 이미지 비율을 유지하면서 컨테이너에 꽉 차도록 설정 */
`


