import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Pagination,
} from "@mui/material";
import { client } from "../../../client";
import { GET_PAYMENT_INFO_V2 } from "../../common/gql/mypage.gql";

const UserPayment = ({ userData }) => {
  const [paymentLogs, setPaymentLogs] = useState([]);

  const userId = userData.id;

  useEffect(() => {
    client
      .query({
        query: GET_PAYMENT_INFO_V2,
        variables: {
          user_id: userId,
        },
      })
      .then((res) => {
        setPaymentLogs(res.data.getPaymentInfoByMypageV2);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const modifyDate = (date) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(parseInt(date)).toLocaleString("ko-KR", options);
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={{ textAlign: "center" }}>결제일</TableCell>
          <TableCell style={{ textAlign: "center" }}>상태</TableCell>
          <TableCell>포인트 금액</TableCell>
          <TableCell>결제 금액</TableCell>
          <TableCell>결제 수단</TableCell>
          <TableCell>상품</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {paymentLogs.map((paylog) => {
          const paymentProductName = paylog?.payment_log?.payment_product?.name;
          const documentName = paylog?.document?.name;
          return (
            <TableRow key={paylog.id}>
              <TableCell>
                <Box>{modifyDate(paylog.created_at)}</Box>
              </TableCell>
              <TableCell>
                <Box>{paylog.status == "USE" ? "사용" : "충전"}</Box>
              </TableCell>
              <TableCell>
                <Box>{paylog.point}</Box>
              </TableCell>
              <TableCell>
                <Box>
                  {paylog.status == "SAVE" ? paylog?.payment_log?.amount : null}
                </Box>
              </TableCell>
              <TableCell>
                <Box>
                  {paylog.status == "SAVE"
                    ? paylog?.payment_log?.payment_method
                    : null}
                </Box>
              </TableCell>
              <TableCell>
                <Box>
                  {documentName != null ? documentName : paymentProductName}
                </Box>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default UserPayment;
