import React, { useState, useEffect } from "react";
import styled from "styled-components";

const ModalWrapper = styled.div`
  position: fixed;
  right: 20px;
  top: ${(props) => props.topPosition}px;
  z-index: 9999;
  width: 540px;
  height: 120px;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

const ImageContainer = styled.div`
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const Modal = ({ title, onDismiss, topPosition, img_src }) => {
  const [dismissed, setDismissed] = useState(false);

  useEffect(() => {
    const dismissedUntil = localStorage.getItem("dismissedUntil");
    if (dismissedUntil && new Date().getTime() < parseInt(dismissedUntil)) {
      setDismissed(true);
    }
  }, []);

  const handleDismiss = () => {
    const now = new Date().getTime();
    const dismissedUntil = now + 3 * 24 * 60 * 60 * 1000; // Three days from now
    localStorage.setItem("dismissedUntil", dismissedUntil);
    setDismissed(true);
    if (onDismiss) onDismiss();
  };

  const description = (
    <p>
      {/* 안녕하세요 APEX 입니다.
      <br />
      <br />
      현재 APEX 는 아직 모바일 결제에 대해 지원하고 있지 않습니다. 결제를
      원하시는 고객 분들은 PC에서 결제를 진행하여 주시기 바랍니다.
      <br />
      <br />
      모바일 결제 후 정상적으로 결제가 진행되지 않은 고객 분들은 고객센터
      (010-5129-2284) 로 문자주시면 조치를 도와드리도록 하겠습니다.
      <br />
      <br />
      모바일 결제는 2~3일 이내로 지원하게 될 예정입니다. */}
      <ImageContainer>
        <Image src={img_src} alt="img" />
      </ImageContainer>
    </p>
  );

  if (dismissed) return null;

  return (
    <ModalWrapper topPosition={topPosition}>
      <ModalContent>
        <h2>{title}</h2>
        {description}
        <button onClick={handleDismiss}>3일간만 보지 않기</button>
      </ModalContent>
    </ModalWrapper>
  );
};

export default Modal;
