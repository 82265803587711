import { Route, Routes } from "react-router-dom";
import Paylog from "../component/payment/paylog";
import Payment from "../component/payment/PaymentProduct";
import PaymentHome from "../component/payment/payment-home";
import SidebarLayoutPayment from "../component/payment/sidebar-payment/SidebarLayoutPayment";

const payment = () => {
    return(
        <SidebarLayoutPayment>
            <Routes>
                <Route path="/" element={<Payment />} />
                <Route path="/2" element={<Paylog />} />
            </Routes>
            {/* {window.location.pathname.includes("2") 
            ? <Paylog />
            : <Payment />
            } */}
        </SidebarLayoutPayment>
    );
};

export default payment;