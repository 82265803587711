import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { client } from "../../client";
import {
  GET_DOCUMENT_URL_BY_ID,
  GET_PAYLOG_LIST,
  GET_USER_ID,
} from "./gql/payment.gql";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import FileDownloader from "../document/download/FileDownloader";
import { GET_DOCUMENT_URL } from "../document/page/gql/document.gql";

const Paylog = () => {
  const accessToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const [paylogs, setPaylogs] = useState([]);
  const [paylogsUrl, setPaylogsUrl] = useState([]);

  var currentPoints = 0;

  useEffect(() => {
    console.log("결제 내역 페이지가 마운트 되었습니다.");
    client
      .query({
        query: GET_USER_ID,
        context: {
          headers: {
            authorization: accessToken ? `Bearer ${accessToken}` : "",
          },
        },
        fetchPolicy: "no-cache",
      })
      .then(async (res) => {
        console.log("res", res);
        const user_id = res.data.getUserInfo.id;
        await client
          .query({
            query: GET_PAYLOG_LIST,
            variables: {
              user_id,
            },
          })
          .then((res) => {
            console.log("res", res);
            const sortedPaylogs = [...res.data.getPaylogList].sort(
              (a, b) => Number(a.created_at) - Number(b.created_at)
            );
            setPaylogs(sortedPaylogs);
          })
          .catch((err) => {
            console.error(err);
            alert("결제 내역을 불러오는데 실패했습니다.");
          });
      })
      .catch((err) => {
        console.error(err);
        alert("로그인이 필요합니다.");
        navigate("/login");
      });
  }, []);

  const getDocumentUrl = async (document_id) => {
    try {
      const res = await client.query({
        query: GET_DOCUMENT_URL,
        variables: {
          document_id,
          type: "PDF",
        },
      });

      console.log("res", res);
      return res.data.getDocumentUrlByType;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  useEffect(() => {
    const fetchUrls = async () => {
      const result = [];
      for (const [index, log] of paylogs.entries()) {
        const url = await getDocumentUrl(log.document_id);
        // console.log("url...", url);
        result.push(url);
      }
      setPaylogsUrl(result);
    };

    fetchUrls();
  }, [paylogs]);

  // const paylogs = [
  //   /* your paylogs array */
  // ]; // assuming paylogs is your array

  return (
    <PaylogWrapper>
      <PaylogTitle>결제 내역</PaylogTitle>
      <Table>
        <thead>
          <tr>
            <th>상품명</th>
            <th>결제 종류</th>
            <th>비용</th>
            <th>포인트</th>
            {/* <th>변경 전 포인트</th>
            <th>변경 후 포인트</th> */}
            <th>문서</th>
            <th>결제일</th>
          </tr>
        </thead>
        <tbody>
          {/* {JSON.stringify(paylogsUrl)} */}
          {paylogs.map((log, index) => {
            var beforePoints = currentPoints;
            var afterPoints = currentPoints;
            if (log.point_change) {
              beforePoints = currentPoints;
              afterPoints = currentPoints + log.point_change;
              currentPoints = afterPoints;
            }

            // const docuUrl = fetchUrl(log.document_id);
            // console.log("docuUrl...", docuUrl);

            return (
              <tr key={index}>
                <td>{log?.product_name}</td>
                <td>{log.payment_way}</td>
                <td>{log.cost}</td>
                <td>
                  {log.point_change
                    ? log.point_change > 0
                      ? `+${log.point_change}P`
                      : `${log.point_change}P`
                    : 0}
                </td>
                {/* <td>{`${beforePoints}P`}</td>
                <td>{`${afterPoints}P`}</td> */}
                <td>
                  {" "}
                  {log.document_id ? (
                    <FileDownloader
                      url={paylogsUrl[index]}
                      documentId={log.document_id}
                      point={0}
                      isRedownload={true}
                    >
                      {" "}
                      <img src="/images/pdf-icon.png" alt="PDF 다운로드" />{" "}
                    </FileDownloader>
                  ) : (
                    ""
                  )}{" "}
                </td>
                <td>
                  {moment(Number(log.created_at)).format("YYYY-MM-DD HH:mm:ss")}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </PaylogWrapper>
  );
};

export default Paylog;

const PaylogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
`;

const PaylogTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const Table = styled.table`
  width: 100%;
  margin: 2rem 0;
  border-collapse: collapse;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;

  th,
  td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: center;
  }

  th {
    background-color: #f4f4f4;
  }
`;
