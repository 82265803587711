import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { client } from "../../client";
import {
  CREATE_PAYMENT_LOG,
  CREATE_POINT,
  GET_MERCHANT_UID,
  GET_PAYMENT_PRODUCTS,
  GET_USER_ID,
  HAS_MEMBERSHIP_NOW,
  PURCHASE_MEMBERSHIP,
  UPDATE_PAYMENT_LOG,
  CREATE_ORDER,
} from "./gql/payment.gql";
import Checkbox from '@mui/material/Checkbox';



const PaymentCashGuide = ({ userId, docuInfo }) => {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("accessToken");
  const [productMembers, setProductMembers] = useState([]);
  // const [productPoints, setProductPoints] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [selectedProduct, setProduct] = useState(null);

  useEffect(() => {}, []);

  return (
    <Container>
      <GuideContainer>
        <CashGuidContainer>
          <li>위 계좌로 자료 금액만큼 입금 및 문자를 남겨주세요</li>
          <li>입금자명은 가급적 회원명과 동일하게 부탁드립니다.</li>
          <li>입금자명이 회원명과 다를 경우는 시간이 소요될 수 있습니다.</li>
          {/* <li>입금 후 별도로 파일을 전송해드립니다.</li> */}
          {/* <br />
          <div>
            <RedText>
              토요일 오후 5시 이후부터는 무통장입금 승인이 불가합니다.{" "}
            </RedText>
            (월요일 오전부터 순차적으로 승인)
            <br />
            <RedText>급히 자료가 필요하시다면,</RedText> 무통장입금 운영시간과
            상관없이 <BlueText>다른 결제수단을</BlueText> 이용해주시길 바랍니다.
            <br />
            무통장 입금 신청 완료 후{" "}
            <RedText>해당 계좌로 입금만 하지 않으시면</RedText> 다른 결제
            수단으로 변경하셔도{" "}
            <RedText>중복으로 결제 처리 되지 않습니다.</RedText>
          </div> */}
        </CashGuidContainer>
      </GuideContainer>
    </Container>
  );
};

export default PaymentCashGuide;
const RedText = styled.span`
  color: red; /* 빨간색 텍스트 색상 */
`;

const BlueText = styled.span`
  color: blue; /* 빨간색 텍스트 색상 */
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const PayMethodItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100px;
  align-items: center;

  justify-content: center;

  bottom: 0px;
  border-radius: 4px;
  border: 1px solid rgb(202, 206, 224);
  background-color: rgb(255, 255, 255);
  color: var(--Gray-gray_500, #6a708a);
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  width: 25%;
  height: 50px;
  padding: 4px 12px;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.colors.accentHover};
    font-weight: 500;
    background: #0064ff;
    font-size: 1.2rem;
    color: #fff;
  }
`;

const GuideContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  
  justify-content: space-evenly; /* Distribute items evenly

`;

const CashGuidContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: gray;
  text-align: left;
  gap: 4px;
  margin-bottom: 8rem;
  font-size: ${(props) => props.theme.fontSize.title};
`;