import { gql } from "@apollo/client";
export const GET_DUCUMENT_BY_ID = gql`
  query getDocument($document_id: Int!) {
    getDocument(document_id: $document_id) {
      id
      docu_type1 {
        name
      }
      docu_type2 {
        name
      }
      is_old
      name
      price
      grade
      year
      month_id
      subject
      publisher {
        name
      }
      category1 {
        name
      }
      category2 {
        name
      }
      downloaded
      created_at
      document_url {
        document_id
        object_url
        type
      }
    }
  }
`;
export const GET_ALL_DOCUTYPE = gql`
  query searchDocuTypeV2($document_search_input: document_search_input) {
    searchDocuTypeV2(document_search_input: $document_search_input) {
      id
      docu_type1_id
      docu_type1_name
      docu_type2_id
      docu_type2_name
      is_old
      grade
      year
      month_id
      name
      subject
      publisher_id
      publisher
      category1_id
      category1_name
      category2_id
      category2_name
      created_at
      downloaded
      price
      documentUrlList {
        id
        document_id
        type
        object_url
      }
    }
  }
`;

export const GET_FILTER_DATA = gql`
  query getFilterListByDocumentType1($docu_type1_id: Int!) {
    getFilterListByDocumentType1(docu_type1_id: $docu_type1_id)
  }
`;

export const GET_DOCUMENT_URL = gql`
  query getDocumentUrlByType($document_id: Int, $type: document_url_type) {
    getDocumentUrlByType(document_id: $document_id, type: $type)
  }
`;

export const PURCHASE_DOCUMENT = gql`
  mutation purchaseDocument(
    $document_id: Int!
    $user_id: Int!
    $point: Int!
    $is_redownload: Boolean
  ) {
    purchaseDocument(
      document_id: $document_id
      user_id: $user_id
      point: $point
      is_redownload: $is_redownload
    )
  }
`;

export const PURCHASE_SINGLE_DOCUMENT = gql`
  mutation purchaseSingleDocument(
    $document_id: Int
    $user_id: Int
    $point: Int
    $current_user_point: Int
    $pay_diff_amount: Boolean
    $payment_method: String
    $merchart_uid: String
    $imp_uid: String
  ) {
    purchaseSingleDocument(
      document_id: $document_id
      user_id: $user_id
      point: $point
      current_user_point: $current_user_point
      pay_diff_amount: $pay_diff_amount
      payment_method: $payment_method
      merchart_uid: $merchart_uid
      imp_uid: $imp_uid
    )
  }
`;
