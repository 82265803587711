import { NavLink } from "react-router-dom";
import styled from "styled-components";

const Sidebar = () => {

  return (
    <StyledSidebar>
      <StyledLink to="mock-tests">모의고사/학평</StyledLink>
      <StyledLink to="highschool-sub">
        고등 부교재 
        {/* <span>(EBS 교재)</span> */}
      </StyledLink>
      <StyledLink to="csat-sub">
        수능연계교재
        {/* <span>(수능특강, 수능완성)</span> */}
      </StyledLink>
      <StyledLink to="highschool-textbook">고등 교과서</StyledLink>
      <StyledLink to="old-edition">구버전 교재자료</StyledLink>
    </StyledSidebar>
  );
};

export default Sidebar;

const StyledSidebar = styled.div`
  min-width: 150px;
  width: 100vw;

  // background-color: #d0d0d0;
  // height: 100vh;
  padding: 32px;

  // box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.1);

  display: flex; /* 아이템을 수평으로 정렬하기 위해 추가 */
  flex-direction: row;
  align-items: center; /* 아이템을 수직으로 정렬하기 위해 추가 */
  
`;

const StyledLink = styled(NavLink)`
  display: block;
  padding-bottom: 0.8rem;
  color: #333;
  text-decoration: none;
  width: 70%;
  font-size: ${(props) => props.theme.fontSize.subtitle};
  font-weight: 700;

  &:hover {
    color: ${(props) => props.theme.colors.accent}
    font-weight: 500;
  }

  span {
    display: block;
    color: #666;
    font-size: 0.8rem;
  }

  &.active {
    // color: #0064FF;
    color: ${(props) => props.theme.colors.accent};
    font-weight: 800;
    border-bottom: 3px solid #0064FF; /* 밑줄 스타일 추가 */
  }
`;
