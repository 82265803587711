import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import styled from "styled-components";
import Button from "@mui/material/Button";
import { gql } from "@apollo/client";
import { client } from "../../../client";

import AWS from "aws-sdk";
import FileUploader from "../admin-document/FileUploader";

const ModalContainer = styled(Box)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  background-color: white; /* Change to white background */
  padding: 20px;
  z-index: 100;
  border: 1px solid #ccc; /* Add a subtle border */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
`;

const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center; /* Center-align all children */
  margin-top: 30px;
  margin-bottom: 30px;
`;

const UserInfoItem = styled.div`
  display: flex; /* Display children in a row */
  flex-direction: row;
  margin-bottom: 10px; /* Add margin between child components */
`;

const NoticeText = styled.textarea`
  width: 200px;
  height: 150px;
`;

const ModifyFaqAdmin = ({
  isOpen,
  data,
  handleCloseClick,
  refetchParentData,
  children,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [showAlert, setShowAlert] = useState(false);

  // TODO env 에 넣기
  const ACCESS_KEY = "AKIA2MB36EXBJXFEOVGR";
  const SECRET_ACCESS_KEY = "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";
  const REGION = "ap-northeast-2";
  const S3_BUCKET = "seum-file";

  AWS.config.update({
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const uploadFile = (file) => {
    console.log("uploadFile...!", file);
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: "upload/" + file?.name,
    };

    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        console.log("evt...", evt);
        setProgress(Math.round((evt.loaded / evt.total) * 100));
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
          setSelectedFile(null);
        }, 3000);
      })
      .send((err, data) => {
        if (err) {
          console.error("Error uploading:", err);
        } else {
          console.log("Upload successful:", data);
          const objectUrl = myBucket.getSignedUrl("getObject", {
            Key: params.Key,
          });

          const objectUrlString = objectUrl.split("?")[0];

          handleInputChange("object_url", objectUrlString);

          handleModifyClick(objectUrlString);
          alert(`수정이 완료되었습니다.`);
        }
      });
  };

  const enumType = {
    DOWNLOAD: "DOWNLOAD",
    PAYMENT: "PAYMENT",
    ACCOUNT: "ACCOUNT",
    ETC: "ETC",
  };

  Object.freeze(enumType);

  const [editableData, setEditableData] = useState({});
  useEffect(() => {
    setEditableData({
      id: data?.id,
      visible: data?.visible,
      type: data?.type,
      title: data?.title,
      content: data?.content,
      object_url: data?.object_url,
    });
  }, [data]);

  const handleInputChange = (key, value) => {
    if (key === "visible") {
      console.log("확인 : ", value);
      let booleanValue = value === "1";
      setEditableData((prevData) => ({
        ...prevData,
        [key]: booleanValue,
      }));
    } else if (key === "id") {
      let intValue = parseInt(value, 10);
      setEditableData((prevData) => ({
        ...prevData,
        [key]: intValue,
      }));
    } else if (key === "type") {
      let typeValue;
      switch (value) {
        case "ACCOUNT":
          typeValue = enumType.ACCOUNT;
          break;
        case "DOWNLOAD":
          typeValue = enumType.DOWNLOAD;
          break;
        case "PAYMENT":
          typeValue = enumType.PAYMENT;
          break;
        case "ETC":
        default:
          typeValue = enumType.ETC;
          break;
      }
      console.log("enum 확인 :", typeValue);

      setEditableData((prevData) => ({
        ...prevData,
        [key]: typeValue,
      }));
    } else {
      setEditableData((prevData) => ({
        ...prevData,
        [key]: value,
      }));
    }
  };

  useEffect(() => {
    console.log("enum 확인2 :", editableData.type);
  }, [editableData.type]);

  const handleModifyClick = (objectUrlString) => {
    const MODIFY_FAQ_ADMIN = gql`
      mutation modifyFaqByAdmin(
        $id: Int
        $visible: Boolean
        $type: faqTypeEnum
        $title: String
        $content: String
        $object_url: String
      ) {
        modifyFaqByAdmin(
          id: $id
          visible: $visible
          type: $type
          title: $title
          content: $content
          object_url: $object_url
        ) {
          message
        }
      }
    `;

    let variables = {
      id: editableData.id,
      visible: editableData.visible,
      type: editableData.type,
      title: editableData.title,
      content: editableData.content,
    };

    if (selectedFile) {
      variables.object_url = objectUrlString;
    }

    client
      .mutate({
        mutation: MODIFY_FAQ_ADMIN,
        variables: variables,
      })
      .then((res) => {
        console.log("update");
        console.log(res);
        console.log("enum 확인3 :", editableData.type);
        refetchParentData();
        handleCloseClick();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updatedTime = (params) => {
    const date = new Date(parseInt(params));
    return date.toLocaleString("ko-kr");
  };

  if (!isOpen) return null;

  return (
    <ModalContainer>
      <h2> FAQ 수정 </h2>

      <UserInfoContainer>
        {Object.keys(data).map((key) => {
          if (key === "__typename") return null;
          return (
            <div key={key}>
              {key === "title" ||
              key === "content" ||
              key === "object_url" ||
              key === "type" ||
              key === "created_at" ||
              key === "updated_at" ||
              key === "visible" ? (
                <UserInfoItem>
                  <div>
                    <Box mr={"5px"}>{key}: </Box>
                  </div>
                  <div>
                    {key === "visible" ? (
                      <select
                        value={editableData[key] ? "1" : "0"}
                        onChange={(e) => handleInputChange(key, e.target.value)}
                      >
                        <option value={1}>노출</option>
                        <option value={0}>비노출</option>
                      </select>
                    ) : key === "updated_at" || key === "created_at" ? (
                      <Box>{updatedTime(data[key])}</Box>
                    ) : key === "object_url" ? (
                      <div>
                        <Box
                          display={"flex"}
                          ml={"10px"}
                          maxHeight={"150px"}
                          maxWidth={"150px"}
                        >
                          <img src={data.object_url} />
                        </Box>
                        <UserInfoItem>
                          <FileUploader
                            setSelectedFile={setSelectedFile}
                            selectedFile={selectedFile}
                            progress={progress}
                            setProgress={setProgress}
                            showAlert={showAlert}
                            setShowAlert={setShowAlert}
                          ></FileUploader>
                        </UserInfoItem>
                      </div>
                    ) : key === "content" ? (
                      <Box>
                        <NoticeText
                          type="text"
                          value={editableData[key]}
                          onChange={(e) =>
                            handleInputChange(key, e.target.value)
                          }
                        />
                      </Box>
                    ) : key === "type" ? (
                      <select
                        value={editableData[key]}
                        onChange={(e) => handleInputChange(key, e.target.value)}
                      >
                        <option value="ACCOUNT">계정</option>
                        <option value="DOWNLOAD">다운로드</option>
                        <option value="PAYMENT">결제</option>
                        <option value="ETC">기타</option>
                      </select>
                    ) : (
                      <input
                        type="text"
                        value={editableData[key] || ""}
                        onChange={(e) => handleInputChange(key, e.target.value)}
                      />
                    )}
                  </div>
                </UserInfoItem>
              ) : (
                <UserInfoItem>
                  {key} : {data[key]}
                </UserInfoItem>
              )}
            </div>
          );
        })}
      </UserInfoContainer>

      <Button variant="contained" onClick={() => uploadFile(selectedFile)}>
        저장
      </Button>

      <Button onClick={() => handleCloseClick()}> 닫기 </Button>
    </ModalContainer>
  );
};

export default ModifyFaqAdmin;
