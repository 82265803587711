import { gql } from "@apollo/client";

export const SEND_SMS = gql`
  mutation sendSMSByEmailOrPassword($phone_number: String) {
    sendSMSByEmailOrPassword(phone_number: $phone_number) {
      message
    }
  }
`;

export const FIND_EMAIL = gql`
  mutation findEmailBySMSAuthNumber(
    $phone_number: String
    $sms_auth_number: Int
  ) {
    findEmailBySMSAuthNumber(
      phone_number: $phone_number
      sms_auth_number: $sms_auth_number
    ) {
      message
      info
    }
  }
`;

export const VERIFY_EMAIL = gql`
  mutation verifySMSToFindEmail($phone_number: String, $sms_auth_number: Int) {
    verifySMSToFindEmail(
      phone_number: $phone_number
      sms_auth_number: $sms_auth_number
    ) {
      message
    }
  }
`;

export const VERIFY_PASSWORD = gql`
  mutation verifySMSToChangePassword($email: String, $sms_auth_number: Int) {
    verifySMSToChangePassword(
      email: $email
      sms_auth_number: $sms_auth_number
    ) {
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation changePasswordBySMSAuthNumber(
    $email: String
    $sms_auth_number: Int
    $password: String
  ) {
    changePasswordBySMSAuthNumber(
      email: $email
      sms_auth_number: $sms_auth_number
      password: $password
    ) {
      message
    }
  }
`;
