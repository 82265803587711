import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import FileDownloader from "../download/FileDownloader";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { GET_DUCUMENT_BY_ID } from "../page/gql/document.gql";
import { client } from "../../../client";

const DocumentInfoContainer = ({ children, docuInfo, accountInfo }) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // const [documentId, setDocumentId] = useState(id);

  const download = () => {
    if (!isLoggedIn) {
      alert("로그인을 먼저 진행해주세요");
      navigate("/login");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // docu_type1_name
  // docu_type2_name

  // grade
  // year
  // month

  // subject
  // publisher

  // category1_name: null
  // category2_name: null

  // downloaded
  // object_url
  // documentUrlList: [object Object]

  // return Object.entries(state?.item).map(([key, value], index) => {

  //   return <p key={index}>{`${key}: ${value}`}</p>;
  // });

  // docu_type1_name

  return (
    <DetailContainer>
      <TitleContainer>{docuInfo?.name}</TitleContainer>
      <DocutypeContainer>
        <DocutypeItem>{docuInfo?.docu_type1_name}</DocutypeItem>
        <DocutypeItem>{docuInfo?.docu_type2_name}</DocutypeItem>
      </DocutypeContainer>

      <PropertyContainer>
        {docuInfo?.grade ? (
          <PropertyRow>
            <PropertyTitle> 학년 </PropertyTitle>
            <PropertyValue> {docuInfo?.grade} </PropertyValue>
          </PropertyRow>
        ) : null}
        {docuInfo?.year ? (
          <PropertyRow>
            <PropertyTitle> 시행 연도 </PropertyTitle>
            <PropertyValue> {docuInfo?.year} 년 </PropertyValue>
          </PropertyRow>
        ) : null}
        {docuInfo?.month ? (
          <PropertyRow>
            <PropertyTitle> 시행 월 </PropertyTitle>
            <PropertyValue> {docuInfo?.month} 월 </PropertyValue>
          </PropertyRow>
        ) : null}
        {docuInfo?.subject ? (
          <PropertyRow>
            <PropertyTitle> 과목 </PropertyTitle>
            <PropertyValue> {docuInfo?.subject} </PropertyValue>
          </PropertyRow>
        ) : null}
        {docuInfo?.publisher ? (
          <PropertyRow>
            <PropertyTitle> 출판사 </PropertyTitle>
            <PropertyValue> {docuInfo?.publisher} </PropertyValue>
          </PropertyRow>
        ) : null}
        {docuInfo?.category1_name ? (
          <PropertyRow>
            <PropertyTitle> 분류1 </PropertyTitle>
            <PropertyValue> {docuInfo?.category1_name} </PropertyValue>
          </PropertyRow>
        ) : null}
        {docuInfo?.category2_name ? (
          <PropertyRow>
            <PropertyTitle> 분류2 </PropertyTitle>
            <PropertyValue> {docuInfo?.category2_name} </PropertyValue>
          </PropertyRow>
        ) : null}
        <PropertyRow>
          <PropertyTitle> 다운로드 수 </PropertyTitle>
          <PropertyValue> {docuInfo?.downloaded} </PropertyValue>
        </PropertyRow>
        <PropertyRow>
          <PropertyTitle> 가격 </PropertyTitle>
          <PropertyValue> {docuInfo?.price} 원 </PropertyValue>
        </PropertyRow>
        {accountInfo ? (
          <AccountInfoContainer>{accountInfo}</AccountInfoContainer>
        ) : null}
      </PropertyContainer>
      {children}
    </DetailContainer>
  );
};

const AccountInfoContainer = styled.div`
  display: flex;
  font-size: 20px;
  position: absolute;
  right: 0rem;
`;

const PropertyTitle = styled.div`
  width: 20%;
  color: rgb(137, 144, 171);

  display: flex;
  flex-direction: row;
`;

const PropertyValue = styled.div`
  width: 70%;
  color: rgb(28, 31, 46);
  font-family: Pretendard, sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;

  display: flex;
  flex-direction: row;
`;

const PropertyRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  font-size: 20px;
`;

const PropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  padding-top: 30px;
  padding-bottom: 30px;

  /* 아래쪽 border 속성을 이용하여 수평선 스타일링 */
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); /* 연한색 회색 */
`;

const DocutypeItem = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: rgb(79, 85, 107);

  display: flex;
  position: relative;
  align-items: center;
  line-height: 22px;

  border-radius: 3px;
  border: 1px solid rgb(225, 228, 237);

  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;

  height: 30px;
  transition: all 0.3s ease 0s;
`;

const DocutypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;

  padding-top: 10px;
  padding-bottom: 30px;

  /* 아래쪽 border 속성을 이용하여 수평선 스타일링 */
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); /* 연한색 회색 */
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 28px;
  font-weight: 700;
  color: rgb(28, 31, 46);
  line-height: 1.5;
  word-break: keep-all;
  margin-top: 4px;
`;

const DetailContainer = styled.div`
  position: relative;
  min-width: 800px;
  height: 80vh;
  width: 60vw;

  display: flex;
  flex-direction: column;

  margin-left: auto;
  margin-right: auto;

  padding-top: 1rem;
`;


export default DocumentInfoContainer;
