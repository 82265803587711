// SidebarMenu.js

import React, { useEffect, useState } from "react";
import { gql } from "@apollo/client";
import { client } from "../../../client";
import FileUploaderV1 from "../admin-document/FileUploaderV1";

import styled from "styled-components";
import AWS from "aws-sdk";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
} from "@mui/material";
import FileUploader from "../admin-document/FileUploader";

const StyledFormContainer = styled.div`
  max-width: 400px;
  margin: 0 auto;
`;

const CREATE_BOOK = gql`
  mutation createBookExhibitionByAdmin(
    $type: Type!
    $is_view: Int!
    $view_order: Int
    $title: String!
    $description: String
    $object_url: String
    $sample_url: String
  ) {
    createBookExhibitionByAdmin(
      type: $type
      is_view: $is_view
      view_order: $view_order
      title: $title
      description: $description
      object_url: $object_url
      sample_url: $sample_url
    ) {
      message
    }
  }
`;

const CreateBook = () => {
  useEffect(() => {}, []);

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedSampleFile, setSelectedSampleFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  // TODO env 에 넣기
  const ACCESS_KEY = "AKIA2MB36EXBJXFEOVGR";
  const SECRET_ACCESS_KEY = "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";
  const REGION = "ap-northeast-2";
  const S3_BUCKET = "seum-file";

  AWS.config.update({
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const onClickUpload = () => {
    const files = [selectedFile, selectedSampleFile];

    const uploadPromises = files.map((file) => {
      return new Promise((resolve, reject) => {
        const params = {
          ACL: "public-read",
          Body: file,
          Bucket: S3_BUCKET,
          Key: "upload/" + file?.name,
        };
        myBucket
          .putObject(params)
          .on("httpUploadProgress", (evt) => {
            console.log("evt...", evt);
            setProgress(Math.round((evt.loaded / evt.total) * 100));
            setShowAlert(true);
          })
          .send((err, data) => {
            if (err) {
              console.error("Error uploading:", err);
              reject(err);
            } else {
              console.log("Upload successful:", data);
              const objectUrl = myBucket.getSignedUrl("getObject", {
                Key: params.Key,
              });

              const objectUrlString = objectUrl.split("?")[0];

              console.log("Object URL:", objectUrlString);
              // handleInputChange("object_url", objectUrlString);
              resolve(objectUrlString);

              // console.log("확인 :", selectedFile);
              // handleUpdateClick(objectUrlString);
              // uploadSeondFile(selectedSampleFile);
            }
          });
      });
    });
    Promise.all(uploadPromises)
      .then((objectUrls) => {
        console.log("All uploads completed:", objectUrls);
        handleSubmit(objectUrls[0] ?? null, objectUrls[1] ?? null);
        // 여기에서 objectUrls을 사용하여 업로드된 파일들의 URL 리스트를 처리합니다.
      })
      .catch((error) => {
        console.error("One or more uploads failed:", error);
        alert("에러가 발생했습니다.");
        // 업로드 실패에 대한 처리를 수행합니다.
      });
  };

  const handleSubmit = (objectUrl1, objectUrl2) => {
    client
      .mutate({
        mutation: CREATE_BOOK,
        variables: {
          type: formData.type,
          is_view: formData.is_view,
          view_order: formData.view_order,
          title: formData.title,
          description: formData.description,
          object_url: objectUrl1,
          sample_url: objectUrl2,
        },
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        console.log("값 확인 :", formData.is_view);
        console.log(res);
        window.alert("등록되었습니다.");
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const [formData, setFormData] = useState({
    type: "SUPPORT",
    is_view: 1,
    view_order: null,
    title: null,
    description: null,
    object_url: null,
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    let updatedValue = value;
    if (name === "is_view") {
      updatedValue = parseInt(value, 10);
    } else if (name === "view_order") {
      updatedValue = parseInt(value, 10);
    }
    setFormData({ ...formData, [name]: updatedValue });
  };

  return (
    <div>
      <h2>자료 등록</h2>
      <StyledFormContainer>
        <form>
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel id="type">Type</InputLabel>
            <Select
              labelId="type"
              id="type"
              name="type"
              value={formData.type}
              defaultValue={"SUPPORT"}
              label="type"
              onChange={handleChange}
            >
              <MenuItem value={"SUPPORT"}>지원</MenuItem>
              <MenuItem value={"SAMPLE"}>샘플</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <TextField
              label="제목"
              name="title"
              defaultValue={formData.title}
              onChange={handleChange}
              sx={{ marginBottom: 2 }}
            ></TextField>
          </FormControl>

          {/* <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <TextField
              label="설명"
              name="description"
              defaultValue={formData.description}
              onChange={handleChange}
              sx={{ marginBottom: 2 }}
            ></TextField>
          </FormControl> */}

          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel id="is_view">노출 여부</InputLabel>

            <Select
              labelId="is_view"
              id="is_view"
              name="is_view"
              value={formData.is_view}
              label="is_view"
              onChange={handleChange}
            >
              <MenuItem value={1}>노출</MenuItem>
              <MenuItem value={2}>비노출</MenuItem>
              {console.log(formData.is_view)}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <TextField
              label="노출 순서"
              name="view_order"
              defaultValue={formData.view_order}
              onChange={handleChange}
              sx={{ marginBottom: 2 }}
            ></TextField>
          </FormControl>

          <div>썸네일</div>
          <FileUploaderV1
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
          ></FileUploaderV1>
          <div>샘플</div>
          <FileUploaderV1
            selectedFile={selectedSampleFile}
            setSelectedFile={setSelectedSampleFile}
          ></FileUploaderV1>

          <Button variant="contained" onClick={() => onClickUpload()}>
            업로드
          </Button>
        </form>
      </StyledFormContainer>
    </div>
  );
};

export default CreateBook;
