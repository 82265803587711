import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { client } from "../../client";
import { CREATE_ORDER } from "./gql/payment.gql";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PURCHASE_SINGLE_DOCUMENT } from "../document/page/gql/document.gql";
import { GET_USER_INFO } from "../common/gql/userinfo.gql";

const PaymentMethodContainer = ({ userId, docuInfo }) => {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("accessToken");
  const [selectedProduct, setProduct] = useState(null);
  const [currentPoint, setCurrentPoint] = useState(null);

  // params
  const [searchParams] = useSearchParams();
  // 포트원
  const isPaySuccess = searchParams.get("imp_success");
  const merchantUid = searchParams.get("merchant_uid");
  const impUid = searchParams.get("imp_uid");
  // 커스텀 -  docu id, docu price, method
  const docuId = searchParams.get("docu_id");
  const docuPrice = searchParams.get("docu_price");
  const method = searchParams.get("method");
  const userIdParam = searchParams.get("user_id");

  useEffect(() => {
    const { IMP } = window;
    IMP.init("imp71328801");

    // 모바일 결제 관련 처리
    if (impUid) {
      completeSingleDocuOrder(
        merchantUid,
        impUid,
        parseInt(docuId),
        parseInt(docuPrice),
        currentPoint,
        method,
        parseInt(userIdParam),
        true
      );
    }

    // 현재 유저 정보
    client
      .query({
        query: GET_USER_INFO,
        context: {
          headers: {
            authorization: accessToken ? `Bearer ${accessToken}` : "",
          },
        },
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        console.log(result);
        setCurrentPoint(result.data.getUserInfo.current_point);
      })
      .catch((error) => {
        console.log(error);
        if (error.message.includes("Unauthorized")) {
          localStorage.setItem("isLoggedIn", false);
          alert("로그인이 필요합니다.");
          navigate("/login");
        }
      });
  }, []);

  // 아래 함수 외에는 없어야함
  // 1 createOrder
  const createOrder = async (payMethod) => {
    if (payMethod == "naver") {
      alert(
        "아직 지원하지 않는 결제 수단입니다. 신용카드 / 무통장입금 / 카카오페이를 이용해주세요."
      );
      return;
    }
    await client
      .mutate({
        mutation: CREATE_ORDER,
        variables: {
          user_id: userId,
          product_id: selectedProduct?.id,
          document_id: docuInfo?.id,
          payment_method: payMethod,
          pay_diff_amount: currentPoint != 0 ? true : false,
          // create_payment_log_input : {
          //   user_id: parseInt(userId),
          //   payment_product_id: null,
          //   document_id: docuInfo.id,
          //   payment_method: payMethod,
          //   amount: docuInfo.price,
          // }
        },
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        console.log("res", res);
        if (payMethod == "card") {
          requestToss(res.data.createOrder);
        } else if (payMethod == "kakao") {
          requestKakao(res.data.createOrder);
        } else if (payMethod == "cash") {
          alert("구매 신청이 완료됐습니다. 입금을 진행해주세요");
          navigate(`/payment/cash/${docuInfo.id}`, {
            state: { userId: userId },
          });
        }
      })
      .catch((err) => {
        console.error("Error fetching user id", err);
      });
  };

  // 2 startPayment (requestToss, requestNaver, requestKakao(=handlePayment) )
  const requestToss = (merchant_uid) => {
    const { IMP } = window;
    IMP.request_pay(
      {
        pg: `tosspayments.im_eipekskzh4`,
        pay_method: "CARD",
        merchant_uid: merchant_uid,
        name: docuInfo.name,
        amount: docuInfo.price - currentPoint,
        buyer_email: "",
        buyer_tel: "",
        // redirect : docu id, docu price, method
        m_redirect_url:
          window.location.href +
          `?docu_id=${docuInfo.id}&docu_price=${docuInfo.price}&method=card&user_id=${userId}`,
        card: {
          useInstallment: false,
        },
        bypass: {
          expiredTime: new Date().getTime() + 1000 * 60 * 30,
          cashReceiptTradeOption: "CULTURE",
        },
      },
      (rsp) => {
        if (Object.keys(rsp).includes("error_code")) {
          alert(rsp.error_msg);
        } else {
          const imp_uid = rsp.imp_uid;
          completeSingleDocuOrder(
            merchant_uid,
            imp_uid,
            docuInfo.id,
            docuInfo.price,
            currentPoint,
            "card",
            userId,
            false
          );
        }
      }
    );
  };
  const requestKakao = (merchant_uid) => {
    // completeOrder(merchant_uid, 3, docuInfo);
    const { IMP } = window;
    console.log("결제시작");
    IMP.request_pay(
      {
        pg: `kakaopay.CAXVTL0YKP`,
        pay_method: "card",
        merchant_uid: merchant_uid,
        name: docuInfo.name,
        amount: docuInfo.price - currentPoint,
        buyer_email: "",
        buyer_tel: "",
        // redirect : docu id, docu price, method
        m_redirect_url:
          window.location.href +
          `?docu_id=${docuInfo.id}&docu_price=${docuInfo.price}&method=kakao&user_id=${userId}`,
        card: {
          useInstallment: false,
        },
        bypass: {
          expiredTime: new Date().getTime() + 1000 * 60 * 30,
          cashReceiptTradeOption: "CULTURE",
        },
      },
      (rsp) => {
        if (rsp.success) {
          console.log(rsp);
          const imp_uid = rsp.imp_uid;
          completeSingleDocuOrder(
            merchant_uid,
            imp_uid,
            docuInfo.id,
            docuInfo.price,
            currentPoint,
            "kakao",
            userId,
            false
          );
          // resolve(imp_uid); // 결제 성공
        } else {
          console.log("pg 결제에 실패하였습니다.", rsp);
          // resolve(false); // 결제 실패
        }
      }
    );
  };
  const requestNaver = () => {
    const { IMP } = window;
    console.log("결제시작");
    IMP.request_pay(
      {
        pg: `naverpay.im_eipekskzh4`,
        pay_method: "CARD",
        merchant_uid: "111111ss",
        name: selectedProduct.name,
        amount: selectedProduct.price,
        buyer_email: "",
        buyer_tel: "010-1234-5678",
        m_redirect_url: "/payment/pay",
        card: {
          useInstallment: false,
        },
        bypass: {
          expiredTime: new Date().getTime() + 1000 * 60 * 30,
          cashReceiptTradeOption: "CULTURE",
        },
      },
      (rsp) => {
        if (rsp.success) {
          const imp_uid = rsp.imp_uid;
          // resolve(imp_uid); // 결제 성공
        } else {
          console.log("pg 결제에 실패하였습니다.", rsp);
          // resolve(false); // 결제 실패
        }
      }
    );
  };

  // 3 completeOrder (PENDING 바꾸고, 로그 쌓고, user table 업데이트하고 등 일련의 과정)
  const completeSingleDocuOrder = async (
    merchartUid,
    impUid,
    docuInfoId,
    docuInfoPrice,
    currentPoint,
    payment_method,
    userId,
    isMobile
  ) => {
    // input
    // - merchart uid
    // - imp uid
    // - document id
    // - type : SINGLE_DOCUMENT / MEMBERSHIP / POINT

    await client
      .mutate({
        mutation: PURCHASE_SINGLE_DOCUMENT,
        variables: {
          document_id: docuInfoId,
          user_id: userId,
          point: docuInfoPrice,
          current_user_point: currentPoint,
          pay_diff_amount: true,
          payment_method: payment_method,
          merchart_uid: merchartUid,
          imp_uid: impUid,
        },
      })
      .then((res) => {
        // alert("결제완료 ", res.data.purchaseProduct);
        // 마무리
        if (!isMobile) {
          alert("5초 후 다운로드 됩니다. 다운로드 폴더를 확인하세요");
          const targetUrl = docuInfo.document_url[0].object_url;
          window.open(targetUrl);
        } else {
          alert("완료됐습니다. 마이페이지에서 다운로드하세요.");
        }
      })
      .catch((err) => {
        alert(err);
        console.error("Error completing order", err);
      });

    // 서버 작업
    // payment 를 complete 처리
    // point 포인트 실제 충전 / 소진 히스토리
    // todo : download log (아직 안남겼음, createOrder 할 때 안남김, 테이블 작업 필요)
  };

  return (
    <Container>
      {/* 보유 금액 : {currentPoint} */}
      <PropertyContainer>
        <PropertyRow>
          <PropertyTitle> 보유 포인트 : {currentPoint} </PropertyTitle>
        </PropertyRow>
        <PropertyRow>
          <PropertyTitle>
            기존 보유포인트 차감 후 차액으로 결제됩니다.{" "}
          </PropertyTitle>
        </PropertyRow>
      </PropertyContainer>
      <PayMethodContainer>
        <PayMethodItem onClick={() => createOrder("cash")}>
          무통장입금
        </PayMethodItem>
        <PayMethodItem onClick={() => createOrder("card")}>
          신용카드
        </PayMethodItem>
        <PayMethodItem onClick={() => createOrder("kakao")}>
          <img
            src="https://seum-file.s3.ap-northeast-2.amazonaws.com/upload/kakaopaybtn.svg"
            alt="카카오페이 로고"
            style={{ width: "120px", height: "100px" }}
          />
        </PayMethodItem>
        <PayMethodItem onClick={() => createOrder("naver")}>
          네이버
        </PayMethodItem>
      </PayMethodContainer>
      {/* <div>
        <div>
          <RedText>
            토요일 오후 5시 이후부터는 무통장입금 승인이 불가합니다.{" "}
          </RedText>
          (월요일 오전부터 순차적으로 승인)
        </div>
        <div>
          <RedText>급히 자료가 필요하시다면,</RedText> 무통장입금 운영시간과
          상관없이 <BlueText>다른 결제수단을</BlueText> 이용해주시길 바랍니다.
        </div>
      </div> */}
    </Container>
  );
};

export default PaymentMethodContainer;

const PropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  padding-top: 30px;
  padding-bottom: 30px;

  /* 아래쪽 border 속성을 이용하여 수평선 스타일링 */
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); /* 연한색 회색 */
`;
const PropertyRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  font-size: 20px;
`;
const PropertyTitle = styled.div`
  width: 100%;
  color: black;

  display: flex;
  flex-direction: row;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const PayMethodItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100px;
  align-items: center;

  justify-content: center;

  bottom: 0px;
  border-radius: 4px;
  border: 1px solid rgb(202, 206, 224);
  background-color: rgb(255, 255, 255);
  color: var(--Gray-gray_500, #6a708a);
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  width: 25%;
  height: 50px;
  padding: 4px 12px;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.colors.accentHover};
    font-weight: 500;
    background: #0064ff;
    font-size: 1.2rem;
    color: #fff;
  }
`;

const PayMethodContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  
  justify-content: space-evenly; /* Distribute items evenly

`;
