import * as React from "react";
import Box from "@mui/joy/Box";
import Drawer from "@mui/joy/Drawer";
import Button from "@mui/joy/Button";
import List from "@mui/joy/List";
import Divider from "@mui/joy/Divider";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import { Link } from "react-router-dom";
import theme from "../../common/Theme";

export default function DrawerBasic({ children }) {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (inOpen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(inOpen);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Button
        variant="outlined"
        sx={{
          marginLeft: "1rem",
          color: "#000",
          borderColor: "#000",
          "&:hover": {
            color: theme.colors.accent,
            borderColor: theme.colors.accent,
          },
        }}
        onClick={toggleDrawer(true)}
      >
        {children}
      </Button>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        <Box
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
          sx={{ marginLeft: "1rem", textDecoration: "none" }}
        >
          <h2 style={{ marginLeft: "0.5rem" }}>관리자 메뉴</h2>
          <List>
            <ListItem>
              <Link to="admin/banner">
                <ListItemButton>배너관리</ListItemButton>
              </Link>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem>
              <Link to="admin/user">
                <ListItemButton>유저관리</ListItemButton>
              </Link>
            </ListItem>
            <ListItem>
              <Link to="admin/user-finance">
                <ListItemButton>유저 결제 정보 조회</ListItemButton>
              </Link>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem>
              <Link to="admin/upload">
                <ListItemButton>자료등록</ListItemButton>
              </Link>
            </ListItem>
            <ListItem>
              <Link to="admin/document1">
                <ListItemButton>자료관리</ListItemButton>
              </Link>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem>
              <Link to="admin/uploadBook">
                <ListItemButton>샘플/지원 자료 등록</ListItemButton>
              </Link>
            </ListItem>
            <ListItem>
              <Link to="admin/book">
                <ListItemButton>샘플/지원 자료 관리</ListItemButton>
              </Link>
            </ListItem>
            <Divider />

            <ListItem>
              <Link to="admin/error">
                <ListItemButton>오류제보관리</ListItemButton>
              </Link>
            </ListItem>
            <ListItem>
              <Link to="admin/inquiry">
                <ListItemButton>고객문의관리</ListItemButton>
              </Link>
            </ListItem>
            <ListItem>
              <Link to="admin/faq">
                <ListItemButton>자주묻는질문</ListItemButton>
              </Link>
            </ListItem>
            <ListItem>
              <Link to="admin/notice">
                <ListItemButton>공지사항관리</ListItemButton>
              </Link>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem>
              <Link>
                <ListItemButton>결제상품관리</ListItemButton>
              </Link>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem>
              <Link to="admin/cashPay">
                <ListItemButton>무통장입금관리</ListItemButton>
              </Link>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </Box>
  );
}
