import Sidebar from "./Sidebar";
import styled from "styled-components";

const SidebarLayout = ({ children }) => {
  return (
    <Layout>
      <Sidebar /> 
      <Content>{children}</Content>
    </Layout>
  );
};

export default SidebarLayout;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Content = styled.div`
  flex-grow: 1;
  padding: 20px;
  height: 100%;
`;