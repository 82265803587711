import React, { useState, useEffect } from "react";
import * as S from "../Filter.style";
import PaginationComponent from "../pagenation/PagenationComponent";
import ListComponent from "../pagenation/List";

const ITEMS_PER_PAGE = 10;
const MAX_PAGES = 5;
const OldEdition = () => {
  const [category, setCategory] = useState("");
  const [textbook, setTextbook] = useState("");
  const [documentType, setDocumentTypee] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const categoryOptions = {
    수능연계교재: ["수능특강 영어", "수능특강 영어독해", "수능완성"],
    "EBS 부교재": [
      "올림포스1",
      "올림포스2",
      "리딩파워 기본",
      "리딩파워 완성",
      "리딩파워 주제별 독해",
      "수능특강 LIGHT",
      "올림포스 전국연합기출",
    ],
    "기타 교재": [],
  };

  const documentTypes = ["수업용자료", "워크북", "변형문제", "패키지"];

  const headers = [
    "번호",
    "분류1",
    "분류2",
    "자료",
    "업로드일",
    "다운로드수",
    "가격",
    "다운로드",
  ];
  const columnRatios = "0.1fr 0.2fr 0.2fr 0.6fr 0.2fr 0.15fr 0.2fr 0.2fr";

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSet, setPageSet] = useState(1);
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;

  const handleSearch = () => {
    console.log({ category, textbook, documentType, searchTerm });
  };

  useEffect(() => {
    setTextbook("");
    if (category !== "EBS 부교재") {
      setDocumentTypee("");
    }
  }, [category]);

  return (
    <S.Wrapper>
      <S.Container>
        <S.FilterContainer>
          <S.Select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="">분류</option>
            {Object.keys(categoryOptions).map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </S.Select>
          <S.Select
            value={textbook}
            onChange={(e) => setTextbook(e.target.value)}
            disabled={!category}
          >
            <option value="">교재</option>
            {category &&
              categoryOptions[category]?.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
          </S.Select>
          {category === "EBS 부교재" && (
            <S.Select
              value={documentType}
              onChange={(e) => setDocumentTypee(e.target.value)}
            >
              <option value="">자료유형</option>
              {documentTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </S.Select>
          )}
        </S.FilterContainer>
        <S.SearchContainer>
          <S.Input
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="검색어를 입력하세요"
          />
          <S.SearchButton onClick={handleSearch}>검색</S.SearchButton>
        </S.SearchContainer>
      </S.Container>
      <ListComponent headers={headers} columnRatios={columnRatios} items={[]} />
      <PaginationComponent
        data={[]}
        currentPage={currentPage}
        setPage={setCurrentPage}
        pageSet={pageSet}
        setPageSet={setPageSet}
        itemsPerPage={ITEMS_PER_PAGE}
        maxPages={MAX_PAGES}
      />
    </S.Wrapper>
  );
};

export default OldEdition;
