import { gql } from "@apollo/client";

export const GET_USER_INFO = gql`
  query getUserInfo {
    getUserInfo {
      id
      name
      type
      current_point
      isAdmin
      email
    }
  }
`;
