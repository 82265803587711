import React, { useState } from "react";
import styled from "styled-components";
import { client } from "../client";
import { gql } from "@apollo/client";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
  const SIGN_UP = gql`
    mutation signUp($create_user_input: create_user_input!) {
      signUp(create_user_input: $create_user_input) {
        message
      }
    }
  `;
  const navigate = useNavigate();
  const [formTouched, setFormTouched] = useState({
    id: false,
    password: false,
    mobile: false,
  });
  // const [showPhoneVerificationInput, setShowPhoneVerificationInput] =
  //   useState(false);
  // const [showEmailVerificationInput, setShowEmailVerificationInput] =
  //   useState(false);
  const [formData, setFormData] = useState({
    name: "",
    id: "",
    password: "",
    adminCity: "",
    adminDistrict: "",
    mobile: "",
    email: "",
    type: "",
    receiveAds: false,
    emailVerificationCode: "",
    mobileVerificationCode: "",
    emailVerificationText: "인증번호 받기",
    mobileVerificationText: "인증번호 받기",
    confirmPassword: "",
    personalData: false,
  });

  const validateSignUpForm = () => {
    const {
      name,
      id,
      password,
      adminCity,
      adminDistrict,
      mobile,
      type,
      personalData,
      //emailVerificationCode,
      //mobileVerificationCode,
    } = formData;

    return (
      name?.length > 0 &&
      id?.length > 0 &&
      password?.length > 0 &&
      adminCity?.length > 0 &&
      adminDistrict?.length > 0 &&
      mobile?.length > 0 &&
      type?.length > 0 &&
      personalData &&
      //mobileVerificationCode.length > 0 &&
      //emailVerificationCode.length > 0 &&
      passwordsMatch() &&
      validPassword() &&
      validEmail() &&
      validMobile()
    );
  };
  // const validateEmailForm = () => {
  //   const { id } = formData;
  //   return id.length > 3;
  // };

  // const validateEmailVerificationForm = () => {
  //   const { emailVerificationCode } = formData;
  //   return emailVerificationCode.length > 3;
  // };

  // const validatePhoneForm = () => {
  //   const { mobile } = formData;
  //   return mobile.length > 9;
  // };
  // const validatePhoneVerificationForm = () => {
  //   const { mobileVerificationCode } = formData;
  //   return mobileVerificationCode.length > 3;
  // };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "radio") {
      setFormData({
        ...formData,
        [name]: value,
      });
    } else if (type === "tel") {
      const value = e.target.value.replace(/\D/g, "");
      setFormData({ ...formData, mobile: value });
    } else {
      setFormData({
        ...formData,
        [name]: type === "checkbox" ? checked : value,
      });
    }
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    switch (name) {
      case "id":
        setFormTouched({ ...formTouched, id: true });
        break;
      case "password":
        setFormTouched({ ...formTouched, password: true });
        break;
      case "mobile":
        setFormTouched({ ...formTouched, mobile: true });
        break;
    }
  };

  // const handlePhoneVerification = () => {
  //   // API call
  //   setShowPhoneVerificationInput(true);
  //   setFormData({
  //     ...formData,
  //     mobileVerificationText: "재전송",
  //   });
  // };

  // const handleEmailVerification = () => {
  //   // API call
  //   setShowEmailVerificationInput(true);
  //   setFormData({
  //     ...formData,
  //     emailVerificationText: "재전송",
  //   });
  // };
  const handleCityChange = (e) => {
    setFormData({ ...formData, adminCity: e.target.value, adminDistrict: "" }); // Reset district when city changes
  };

  const handleDistrictChange = (e) => {
    setFormData({ ...formData, adminDistrict: e.target.value });
  };

  const passwordsMatch = () => {
    return formData.password === formData.confirmPassword;
  };

  const validEmail = () => {
    // eslint-disable-next-line no-useless-escape
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(formData.id);
  };

  const validPassword = () => {
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/;

    return passwordRegex.test(formData.password);
  };
  

  const validMobile = () => {
    return formData.mobile.length === 11;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    client
      .mutate({
        mutation: SIGN_UP,
        variables: {
          create_user_input: {
            name: formData.name,
            email: formData.id,
            password: formData.password,
            phone_number: formData.mobile,
            type: formData.type,
            region1: formData.adminCity,
            region2: formData.adminDistrict,
            ad_agreement: formData.receiveAds,
          },
        },
      })
      .then((res) => {
        console.log(res);
        navigate("/login");
      })
      .catch((err) => {
        if (err.message.includes("duplicate email.")) {
          alert("이미 존재하는 아이디입니다");
        }
        setFormData({
          ...formData,
          id: "",
          password: "",
          confirmPassword: "",
        });
        console.log(err);
      });
  };

  return (
    <SignUpContainer>
      <Title>회원가입</Title>
      <Divider disabled={!validateSignUpForm()} />
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label htmlFor="name">이름</Label>
          <Input
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="이름을 입력하세요"
          />
        </FormGroup>

        <FormGroup>
          <Label htmlFor="id">이메일(아이디)</Label>
          <ButtonGroup>
            <Input
              id="id"
              name="id"
              value={formData.id}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="이메일을 입력하세요"
            />
            {!validEmail() && formData.id.length > 0 && formTouched.id && (
              <WarningText>이메일 형식이 올바르지 않습니다</WarningText>
            )}
            {/* <VerificationButton
              type="button"
              onClick={handleEmailVerification}
              disabled={!validateEmailForm()}
            >
              {formData.emailVerificationText}
            </VerificationButton> */}
          </ButtonGroup>
        </FormGroup>

        {/* {showEmailVerificationInput && (
          <FormGroup>
            <Label htmlFor="emailVerificationCode">인증번호</Label>
            <ButtonGroup>
              <Input
                id="emailVerificationCode"
                name="emailVerificationCode"
                value={formData.emailVerificationCode}
                onChange={handleChange}
                placeholder="인증번호 입력"
              />
              <VerificationButton
                type="button"
                disabled={!validateEmailVerificationForm()}
              >
                인증
              </VerificationButton>
            </ButtonGroup>
          </FormGroup>
        )} */}

        <FormGroup>
          <Label htmlFor="password">비밀번호</Label>
          <Input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="비밀번호를 입력하세요"
          />
          {!validPassword() && formData.password && formTouched.password && (
            <WarningText>최소 8자리, 영문, 숫자를 포함해야 합니다.</WarningText>
          )}
        </FormGroup>

        <FormGroup>
          <Label htmlFor="confirmPassword">비밀번호 확인</Label>
          <Input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            placeholder="비밀번호 확인"
          />
          {!passwordsMatch() && formData.confirmPassword && (
            <WarningText>비밀번호가 일치하지 않습니다.</WarningText>
          )}
          {passwordsMatch() && formData.confirmPassword && (
            <CorrectText>비밀번호가 일치합니다.</CorrectText>
          )}
        </FormGroup>

        <FormGroup>
          <Label htmlFor="adminCity">시/도</Label>
          <Select
            id="adminCity"
            name="adminCity"
            value={formData.adminCity}
            onChange={handleCityChange}
          >
            <option value="">시/도 선택</option>
            {Object.keys(koreanAdministrativeDivisions).map((city) => (
              <option key={city} value={city}>
                {city}
              </option>
            ))}
          </Select>

          <Label htmlFor="adminDistrict">구/군</Label>
          <Select
            id="adminDistrict"
            name="adminDistrict"
            value={formData.adminDistrict}
            onChange={handleDistrictChange}
            disabled={!formData.adminCity}
          >
            <option value="">구/군 선택</option>
            {formData.adminCity &&
              koreanAdministrativeDivisions[formData.adminCity].map(
                (district) => (
                  <option key={district} value={district}>
                    {district}
                  </option>
                )
              )}
          </Select>
        </FormGroup>

        <FormGroup>
          <Label htmlFor="mobile">연락처</Label>
          <ButtonGroup>
            <Input
              type="tel"
              id="mobile"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="전화번호 입력(숫자만)"
            />
            {!validMobile() && formData.mobile && formTouched.mobile && (
              <WarningText>전화번호가 유효하지 않습니다.</WarningText>
            )}
            {/* <VerificationButton
              type="button"
              onClick={handlePhoneVerification}
              disabled={!validatePhoneForm()}
            >
              {formData.mobileVerificationText}
            </VerificationButton> */}
          </ButtonGroup>
        </FormGroup>

        {/* {showPhoneVerificationInput && (
          <FormGroup>
            <Label htmlFor="moblieVerificationCode">인증번호</Label>
            <ButtonGroup>
              <Input
                id="mobileVerificationCode"
                name="mobileVerificationCode"
                value={formData.mobileVerificationCode}
                onChange={handleChange}
                placeholder="인증번호 입력"
              />
              <VerificationButton
                type="button"
                disabled={!validatePhoneVerificationForm()}
              >
                인증
              </VerificationButton>
            </ButtonGroup>
          </FormGroup>
        )} */}

        <FormGroup>
          <Label htmlFor="type">회원 유형</Label>
          <CheckboxLabel>
            <Checkbox
              type="radio"
              name="type"
              value="INDIVIDUAL"
              checked={formData.type === "INDIVIDUAL"}
              onChange={handleChange}
            />
            개인
          </CheckboxLabel>
          <CheckboxLabel>
            <Checkbox
              type="radio"
              name="type"
              value="ACADEMY"
              checked={formData.type === "ACADEMY"}
              onChange={handleChange}
            />
            학원
          </CheckboxLabel>
        </FormGroup>

        <CheckboxLabel>
          <Checkbox
            type="checkbox"
            name="receiveAds"
            checked={formData.receiveAds}
            onChange={handleChange}
          />
          광고 수신 동의
        </CheckboxLabel>
        <CheckboxLabel>
          <Checkbox
          type="checkbox"
          name="personalData"
          checked={formData.personalData}
          onChange={handleChange}
          />
          개인정보 동의
        </CheckboxLabel>
        <Button type="submit" disabled={!validateSignUpForm()}>
          가입
        </Button>
      </Form>
    </SignUpContainer>
  );
};

export default SignUp;

const buttonColorValidated = "#5b00ff";
const buttonColorNotValidated = "#ddd";
const buttonHoverColor = "#3500D3";

const SignUpContainer = styled.div`
  max-width: 500px;
  margin: 2rem auto;
  padding: 2rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: #fff;
`;

const Divider = styled.hr`
  border: none;
  height: 2px;
  background-color: ${(props) => (props.disabled ? "#ddd" : "#3500D3")};
  margin-top: 20px;
  margin-bottom: 20px;
`;

const WarningText = styled.span`
  color: red;
  font-size: 0.6rem;
`;

const CorrectText = styled.span`
  color: green;
  font-size: 0.6rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormGroup = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const Label = styled.label`
  margin-right: 1rem;
  flex-basis: 100px;
  text-align: left;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
`;

const Input = styled.input`
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  flex: 1;
  margin-right: 0.5rem;
`;

const VerificationButton = styled.button`
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  background-color: ${(props) =>
    props.disabled ? buttonColorNotValidated : buttonColorValidated};
  color: white;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  transition: transform 0.1s ease;
  &:active {
    transform: translateY(2px);
  }
`;
const CheckboxLabel = styled.label`
  margin-right: 1rem;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const Checkbox = styled.input`
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
`;

const Button = styled.button`
  padding: 0.5rem;
  margin-top: 1.5rem;
  border: none;
  border-radius: 4px;
  background-color: ${(props) =>
    props.disabled ? buttonColorNotValidated : buttonColorValidated};
  color: white;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  transition: transform 0.1s ease;
  &:active {
    transform: translateY(2px);
  }
`;

const Title = styled.h3`
  text-align: center;
  margin-bottom: 2rem; // More space below the title
`;

const koreanAdministrativeDivisions = {
  서울특별시: [
    "종로구",
    "중구",
    "용산구",
    "성동구",
    "광진구",
    "동대문구",
    "중랑구",
    "성북구",
    "강북구",
    "도봉구",
    "노원구",
    "은평구",
    "서대문구",
    "마포구",
    "양천구",
    "강서구",
    "구로구",
    "금천구",
    "영등포구",
    "동작구",
    "서초구",
    "강남구",
    "송파구",
    "강동구",
  ],
  부산광역시: [
    "중구",
    "서구",
    "동구",
    "영도구",
    "부산진구",
    "동래구",
    "남구",
    "북구",
    "해운대구",
    "사하구",
    "금정구",
    "강서구",
    "연제구",
    "수영구",
    "사상구",
    "기장군",
  ],
  대구광역시: [
    "중구",
    "동구",
    "서구",
    "남구",
    "북구",
    "수성구",
    "달서구",
    "달성군",
    "군위군",
  ],
  인천광역시: [
    "중구",
    "동구",
    "미추홀구",
    "연수구",
    "남동구",
    "부평구",
    "계양구",
    "서구",
    "강화군",
    "옹진군",
  ],
  광주광역시: ["동구", "서구", "남구", "북구", "광산구"],
  대전광역시: ["동구", "중구", "서구", "유성구", "대덕구"],
  울산광역시: ["중구", "남구", "동구", "북구", "울주군"],
  세종특별자치시: ["세종특별자치시"],
  경기도: [
    "수원시",
    "성남시",
    "고양시",
    "용인시",
    "부천시",
    "안산시",
    "안양시",
    "남양주시",
    "화성시",
    "평택시",
    "의정부시",
    "시흥시",
    "파주시",
    "광명시",
    "김포시",
    "군포시",
    "광주시",
    "이천시",
    "양주시",
    "오산시",
    "구리시",
    "안성시",
    "포천시",
    "의왕시",
    "하남시",
    "여주시",
    "양평군",
    "동두천시",
    "과천시",
    "가평군",
    "연천군",
  ],
  강원도: [
    "춘천시",
    "원주시",
    "강릉시",
    "동해시",
    "속초시",
    "삼척시",
    "홍천군",
    "횡성군",
    "영월군",
    "평창군",
    "정선군",
    "철원군",
    "화천군",
    "양구군",
    "인제군",
    "고성군",
    "양양군",
  ],
  충청북도: [
    "청주시",
    "충주시",
    "제천시",
    "보은군",
    "옥천군",
    "영동군",
    "증평군",
    "진천군",
    "괴산군",
    "음성군",
    "단양군",
  ],
  충청남도: [
    "천안시",
    "공주시",
    "보령시",
    "아산시",
    "서산시",
    "논산시",
    "계룡시",
    "당진시",
    "금산군",
    "부여군",
    "서천군",
    "청양군",
    "홍성군",
    "예산군",
    "태안군",
  ],
  전라북도: [
    "전주시",
    "군산시",
    "익산시",
    "정읍시",
    "남원시",
    "김제시",
    "완주군",
    "진안군",
    "무주군",
    "장수군",
    "임실군",
    "순창군",
    "고창군",
    "부안군",
  ],
  전라남도: [
    "목포시",
    "여수시",
    "순천시",
    "나주시",
    "광양시",
    "담양군",
    "곡성군",
    "구례군",
    "고흥군",
    "보성군",
    "화순군",
    "장흥군",
    "강진군",
    "해남군",
    "영암군",
    "무안군",
    "함평군",
    "영광군",
    "장성군",
    "완도군",
    "진도군",
    "신안군",
  ],
  경상북도: [
    "포항시",
    "경주시",
    "김천시",
    "안동시",
    "구미시",
    "영주시",
    "영천시",
    "상주시",
    "문경시",
    "경산시",
    "군위군",
    "의성군",
    "청송군",
    "영양군",
    "영덕군",
    "청도군",
    "고령군",
    "성주군",
    "칠곡군",
    "예천군",
    "봉화군",
    "울진군",
    "울릉군",
  ],
  경상남도: [
    "창원시",
    "김해시",
    "진주시",
    "양산시",
    "거제시",
    "통영시",
    "사천시",
    "밀양시",
    "함안군",
    "거창군",
    "창녕군",
    "고성군",
    "하동군",
    "합천군",
    "남해군",
    "함양군",
    "산청군",
    "의령군",
    "함양군",
  ],
  제주특별자치도: ["제주시", "서귀포시"],
};

const Select = styled.select`
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  flex: 1;
  margin-right: 0.5rem;
`;
