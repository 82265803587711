import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { gql } from "@apollo/client";
import { client } from "../../../client";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

import ModifyFaqAdmin from "./ModifyFaqAdmin";

const GET_FAQ_ADMIN = gql`
  query getFaqByAdmin($id: Int, $visible: Boolean, $page: Int, $pageSize: Int) {
    getFaqByAdmin(
      id: $id
      visible: $visible
      page: $page
      pageSize: $pageSize
    ) {
      id
      user_id
      visible
      type
      title
      content
      object_url
      created_at
      updated_at
    }
  }
`;

const FaqAdmin = () => {
  const [FaqList, setFaqList] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const navigate = useNavigate();

  const fetchData = () => {
    client
      .query({
        query: GET_FAQ_ADMIN,
        variables: {
          page: 1,
          pageSize: 100000,
        },
      })
      .then((res) => {
        console.log(res);
        setFaqList(res.data.getFaqByAdmin);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCloseClick = () => {
    setModalOpen(false);
  };

  const handleDeleteClick = async (data) => {
    const isConfirmed = window.confirm("정말 삭제하시겠습니까?");
    if (isConfirmed) {
      const DELETE_FAQ_ADMIN = gql`
        mutation deleteFaqByAdmin($id: Int) {
          deleteFaqByAdmin(id: $id) {
            message
          }
        }
      `;

      try {
        const res = await client.mutate({
          mutation: DELETE_FAQ_ADMIN,
          variables: {
            id: data.id,
          },
        });
        window.alert("삭제되었습니다.");
        window.location.reload();
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleModifyClick = (data) => {
    setSelectedData(data);
    setModalOpen(!isModalOpen);
  };

  const handleCreateClick = () => {
    navigate("/admin/faq/create");
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "user_id", headerName: "유저 번호", flex: 1 },
    {
      field: "type",
      headerName: "종류",
      flex: 1,
    },
    {
      field: "visible",
      headerName: "노출",
      flex: 1,
      renderCell: (params) => {
        if (params.value === true) {
          return <Box>노출</Box>;
        } else {
          return <Box>비노출</Box>;
        }
      },
    },
    { field: "title", headerName: "제목", flex: 2 },
    { field: "content", headerName: "내용", flex: 1 },
    {
      field: "object_url",
      headerName: "첨부 파일",
      flex: 2,
      renderCell: (params) => (
        <div
          style={{
            height: "145px",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <img src={params.value} alt={params.value} />
        </div>
      ),
    },
    {
      field: "created_at",
      headerName: "등록 일자",
      flex: 1,
      renderCell: (params) => {
        params = parseInt(params.value);
        return (
          <Box whiteSpace={"normal"}>
            {new Date(params).toLocaleString("ko-kr")}
          </Box>
        );
      },
    },
    {
      field: "updated_at",
      headerName: "수정 일자",
      flex: 1,
      renderCell: (params) => {
        params = parseInt(params.value);
        return (
          <Box whiteSpace={"normal"}>
            {new Date(params).toLocaleString("ko-kr")}
          </Box>
        );
      },
    },
    {
      field: "modify",
      headerName: "수정",
      flex: 1,
      renderCell: (data) => (
        <Button onClick={() => handleModifyClick(data.row)}>수정</Button>
      ),
    },
    {
      field: "delete",
      headerName: "삭제",
      flex: 1,
      renderCell: (data) => (
        <Button onClick={() => handleDeleteClick(data.row)}>삭제</Button>
      ),
    },
  ];

  return (
    <div>
      <Box
        margin={"7px"}
        display={"flex"}
        height={"70px"}
        justifyContent={"space-between"}
      >
        <Box ml={"15px"}>
          <h3>FAQ</h3>
        </Box>
        <Box display="flex" alignItems="center" mr={"5vw"}>
          <Button
            sx={{ color: "black", fontWeight: 600 }}
            onClick={handleCreateClick}
          >
            FAQ 등록
          </Button>
        </Box>
      </Box>
      <Box ml={"5px"}>
        <DataGrid
          rows={FaqList}
          columns={columns}
          rowHeight={150}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          components={{ Toolbar: () => <GridToolbar /> }}
        />

        <ModifyFaqAdmin
          isOpen={isModalOpen}
          handleCloseClick={handleCloseClick}
          refetchParentData={fetchData}
          data={selectedData}
        ></ModifyFaqAdmin>
      </Box>
    </div>
  );
};

export default FaqAdmin;
