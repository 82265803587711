// SidebarMenu.js

import React, { useEffect, useState } from "react";
import { gql } from "@apollo/client";
import { client } from "../../../client";

import { Box, Switch, FormControlLabel } from "@mui/material";
import Button from "@mui/material/Button";

import ModifyDocument from "./ModifyDocument";

// DataGrid

import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const SEARCH_DOCU_LIST_V2 = gql`
  query searchDocuTypeV2($document_search_input: document_search_input) {
    searchDocuTypeV2(document_search_input: $document_search_input) {
      id
      order
      docu_type1_name
      docu_type1_id
      docu_type2_name
      docu_type2_id
      name
      price
      grade
      year
      month_id
      subject
      publisher_id
      category1_id
      category2_id
      object_url
      document_url_id
      documentUrlList {
        id
        type
        object_url
      }
    }
  }
`;

const SEARCH_DOCU_LIST = gql`
  query searchDocuType($document_search_input: document_search_input) {
    searchDocuType(document_search_input: $document_search_input) {
      id
      documentUrlList {
        id
        type
        object_url
      }
    }
  }
`;

const transformData = (data) => {
  return data.map((item) => {
    let hwpUrl;
    let hwpType;
    let pdfUrl;
    let pdfType;

    item.documentUrlList?.forEach((urlItem) => {
      if (urlItem.object_url.includes(".hwp")) {
        hwpUrl = urlItem.object_url;
        hwpType = urlItem.type;
      } else {
        pdfUrl = urlItem.object_url;
        pdfType = urlItem.type;
      }
    });

    return { ...item, pdfUrl, hwpUrl, pdfType, hwpType };
  });
};

const mergeDocumentLists = (documentList, urlList) => {
  return documentList.map((doc) => {
    const matchingUrlDoc = urlList.find((urlDoc) => urlDoc.id === doc.id);
    return {
      ...doc,
      documentUrlList: matchingUrlDoc ? matchingUrlDoc.documentUrlList : [],
    };
  });
};

const DocuType1Page = () => {
  const [documentList, setDocumentList] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentIdx, setCurrentIdx] = useState(0);

  const fetchDocumentAndUrl = async () => {
    try {
      const [documentRes, urlRes] = await Promise.all([
        client.query({
          query: SEARCH_DOCU_LIST_V2,
          variables: {
            document_search_input: {
              page: 1,
              pageSize: 1000,
            },
          },
          fetchPolicy: "network-only",
        }),
        client.query({
          query: SEARCH_DOCU_LIST,
          variables: {
            document_search_input: {
              page: 1,
              pageSize: 1000,
            },
          },
          fetchPolicy: "network-only",
        }),
      ]);

      const documentData = documentRes.data.searchDocuTypeV2;
      const transformedUrlData = transformData(urlRes.data.searchDocuType);
      const mergedList = mergeDocumentLists(documentData, transformedUrlData);

      const transformedMergedList = transformData(mergedList);

      setDocumentList(transformedMergedList);

      console.log("확인용 :", documentRes.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchDocumentAndUrl();
  }, []);

  // const userList = data.getUserListByAdmin;
  const handleEditClick = (document) => {
    setSelectedDocument(document);

    setModalOpen(!isModalOpen);
  };

  const handleDeleteClick = async (document) => {
    const isConfirmed = window.confirm("정말 삭제하시겠습니까?");

    if (isConfirmed) {
      const DELETE_DOCU = gql`
        mutation deleteDocument($document_id: Int) {
          deleteDocument(document_id: $document_id)
        }
      `;

      try {
        const res = await client.mutate({
          mutation: DELETE_DOCU,
          variables: {
            document_id: document.id,
          },
          fetchPolicy: "no-cache",
        });
        alert("삭제되었습니다.");
        window.location.reload(true);
      } catch (err) {
        console.log(err);
      }
    } else {
      alert("취소되었습니다.");
    }
  };

  const handleCloseClick = () => {
    setModalOpen(false);
  };

  // Tab

  const handleSearchDocuType = async (docu_type1_id) => {
    try {
      const [documentRes, urlRes] = await Promise.all([
        client.query({
          query: SEARCH_DOCU_LIST_V2,
          variables: {
            document_search_input: {
              docu_type1_id: docu_type1_id,
              page: 1,
              pageSize: 1000,
            },
          },
          fetchPolicy: "network-only",
        }),
        client.query({
          query: SEARCH_DOCU_LIST,
          variables: {
            document_search_input: {
              docu_type1_id: docu_type1_id,
              page: 1,
              pageSize: 1000,
            },
          },
          fetchPolicy: "network-only",
        }),
      ]);

      const documentData = documentRes.data.searchDocuTypeV2;
      const transformedUrlData = transformData(urlRes.data.searchDocuType);
      const mergedList = mergeDocumentLists(documentData, transformedUrlData);

      const transformedMergedList = transformData(mergedList);

      setDocumentList(transformedMergedList);
    } catch (err) {
      console.log(err);
    }
  };

  // DataGrid
  const columns = [
    { field: "id", headerName: "ID", flex: 0.2 },
    { field: "order", headerName: "순서", flex: 0.2 },
    { field: "name", headerName: "자료명", flex: 1 },
    { field: "docu_type2_name", headerName: "자료 유형", flex: 1 },
    { field: "subject", headerName: "과목", flex: 1 },
    { field: "grade", headerName: "학년", flex: 0.8 },
    { field: "year", headerName: "연도", flex: 1 },
    { field: "month_id", headerName: "월", flex: 0.8 },
    { field: "price", headerName: "가격", flex: 1 },
    // { field: "object_url", headerName: "objUrl", flex: 3 },
    { field: "pdfUrl", headerName: "PDF URL", flex: 2 },
    { field: "hwpUrl", headerName: "HWP URL", flex: 2 },
    {
      field: "edit",
      headerName: "자료 수정",
      flex: 0.2,
      renderCell: (params) => (
        <Button onClick={() => handleEditClick(params.row)}>수정</Button>
      ),
    },
    {
      field: "delete",
      headerName: "자료 삭제",
      flex: 0.2,
      renderCell: (params) => (
        <Button onClick={() => handleDeleteClick(params.row)}>삭제</Button>
      ),
    },
  ];

  const tabOptions = [
    { text: "모의고사/학평", value: "모의고사/학평", docu_type1_id: 1 },
    {
      text: "고등 부교재 (EBS 교재)",
      value: "고등 부교재 (EBS 교재)",
      docu_type1_id: 2,
    },
    {
      text: "수능연계교재(수능특강, 수능완성)",
      value: "수능연계교재(수능특강, 수능완성)",
      docu_type1_id: 3,
    },
    { text: "고등교과서", value: "고등교과서", docu_type1_id: 4 },
  ];

  // 칼럼 선택 상태 관리
  // 로컬 스토리지에서 열 가시성 상태를 불러옴
  const getColumnVisibilityFromLocalStorage = () => {
    const savedVisibilityModel = localStorage.getItem("columnVisibilityModel");
    return savedVisibilityModel
      ? JSON.parse(savedVisibilityModel)
      : {
          id: true,
          order: true,
          name: true,
          docu_type2_name: true,
          subject: true,
          grade: true,
          year: true,
          month_id: true,
          price: true,
          object_url: true,
        };
  };

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    getColumnVisibilityFromLocalStorage
  );

  // 열 가시성 상태가 변경될 때마다 로컬 스토리지에 저장
  useEffect(() => {
    localStorage.setItem(
      "columnVisibilityModel",
      JSON.stringify(columnVisibilityModel)
    );
  }, [columnVisibilityModel]);

  const handleColumnVisibilityChange = (field) => {
    setColumnVisibilityModel((prevModel) => ({
      ...prevModel,
      [field]: !prevModel[field],
    }));
  };

  return (
    <div>
      <Box
        margin={"7px"}
        display={"flex"}
        height={"70px"}
        justifyContent={"space-between"}
      >
        <Box ml={"5px"}>
          <h4>자료 관리</h4>
        </Box>
      </Box>

      <Box>
        {tabOptions.map((option) => (
          <Button
            key={option.value}
            onClick={() => handleSearchDocuType(option.docu_type1_id)}
          >
            {option.text}
          </Button>
        ))}
      </Box>

      <Box ml={"5px"} mb={"10px"}>
        {columns.map((col) => (
          <FormControlLabel
            key={col.field}
            control={
              <Switch
                checked={columnVisibilityModel[col.field]}
                onChange={() => handleColumnVisibilityChange(col.field)}
              />
            }
            label={
              columnVisibilityModel[col.field]
                ? ` ${col.headerName}`
                : ` ${col.headerName}`
            }
          />
        ))}
      </Box>

      <Box ml={"5px"}>
        <DataGrid
          rows={documentList}
          columns={columns}
          initialState={{
            pagination: { paginationModel: { pageSize: 100 } },
          }}
          pageSizeOptions={[10, 20, 30, 80]}
          components={{
            Toolbar: () => <GridToolbar />,
          }}
          columnVisibilityModel={columnVisibilityModel} // 열 가시성 상태 전달
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
        />
      </Box>

      {/* Modal for Edit */}
      {/* 모달 새로만들기 */}

      <ModifyDocument
        isOpen={isModalOpen}
        handleCloseClick={handleCloseClick}
        data={selectedDocument}
        setModalOpen={setModalOpen}
      ></ModifyDocument>
    </div>
  );
};

export default DocuType1Page;
