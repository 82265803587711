import Banner from "../component/home/Banner";
import DocumentPreviewGrid from "../component/home/DocumentPreviewGrid";
import styled from "styled-components";
import NoticePreviewGrid from "../component/home/NoticePreviewGrid";
import BookList from "../component/home/BookList";
import IconAndTop from "../component/home/IconAndTop";
import SampleList from "../component/home/SampleList";
import { gql } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { client } from "../client";

const Home = () => {
  const [months, setMonths] = useState([]);
  const GET_HOME_DOCU_DATA = gql`
    # Write your query or mutation here
    query getMainPageDocumentList {
      getMainPageDocumentList {
        weeklyList {
          id
          name
          docu_type1_id
          docu_type1_name
          docu_type2_id
          docu_type2_name
          price
          grade
          year
          month_id
          month
          subject
          publisher_id
          publisher
          category1_id
          category1_name
          category2_id
          category2_name

          downloaded
          documentUrlList {
            id
            document_id
            type
            object_url
          }
          created_at
        }

        mockExamList {
          id
          name
          docu_type1_id
          docu_type1_name
          docu_type2_id
          docu_type2_name
          price
          grade
          year
          month_id
          month
          subject
          publisher_id
          publisher
          category1_id
          category1_name
          category2_id
          category2_name

          downloaded
          documentUrlList {
            id
            document_id
            type
            object_url
          }
          object_url
          created_at
        }

        highSchoolSecondTeachList {
          id
          name
          docu_type1_id
          docu_type1_name
          docu_type2_id
          docu_type2_name
          price
          grade
          year
          month_id
          month
          subject
          publisher_id
          publisher
          category1_id
          category1_name
          category2_id
          category2_name

          downloaded

          documentUrlList {
            id
            document_id
            type
            object_url
          }
          created_at
        }

        specialLectureSATList {
          id
          name
          docu_type1_id
          docu_type1_name
          docu_type2_id
          docu_type2_name
          price
          grade
          year
          month_id
          month
          subject
          publisher_id
          publisher
          category1_id
          category1_name
          category2_id
          category2_name

          downloaded
          object_url
          documentUrlList {
            id
            document_id
            type
            object_url
          }
          created_at
        }

        highSchoolTextbookList {
          id
          name
          docu_type1_id
          docu_type1_name
          docu_type2_id
          docu_type2_name
          price
          grade
          year
          month_id
          month
          subject
          publisher_id
          publisher
          category1_id
          category1_name
          category2_id
          category2_name

          downloaded
          object_url
          documentUrlList {
            id
            document_id
            type
            object_url
          }
          created_at
        }
      }
    }
  `;

  const [weelkyData, setWeeklyData] = useState([]);
  const [lastestDocumentList, setLatestDocumentList] = useState([]);

  useEffect(() => {
    client
      .query({
        query: GET_HOME_DOCU_DATA,
        context: {
          headers: {
            // authorization: accessToken ? `Bearer ${accessToken}` : "",
          },
        },
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        setWeeklyData(res.data.getMainPageDocumentList.weeklyList);

        setLatestDocumentList(res.data.getMainPageDocumentList);
      })
      .catch((err) => {
        console.error("err", err);
      });
  }, []);

  return (
    <div>
      <Banner></Banner>
      <HomeLayout>
        {/* 아이콘 (공지, FAQ, 샘플보기) + top 5 */}
        <IconAndTop data={weelkyData}></IconAndTop>
        {/* 최신자료 */}
        <Section2>
          <DocumentPreviewGrid lastestDocumentList={lastestDocumentList}>
            {" "}
          </DocumentPreviewGrid>
          <SampleList></SampleList>
        </Section2>
        {/* 공지 */}
        {/* <NoticePreviewGrid>
                </NoticePreviewGrid> */}
        {/* 샘플 다운로드 */}
        {/* 지원 교재 리스트 */}
        <BookList></BookList>
      </HomeLayout>
    </div>
  );
};

export default Home;

const HomeLayout = styled.div`
  max-width: 1440px;
  margin: 0 auto; /* 가운데 정렬을 위해 추가 */
`;

const Section2 = styled.div`
  max-width: 1440px;
  margin: 0 auto; /* 가운데 정렬을 위해 추가 */
  margin-top: 3rem;
  margin-bottom: 4rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
