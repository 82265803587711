import styled from "styled-components";
import Box from "@mui/material/Box";
import React, { useState, useEffect } from "react";

export const ContentItem = styled.div`
  font-family: Pretendard;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: -0.13px;
  text-align: left;
  color: #777;
`;

export const Container = styled.div`
  max-width: 1200px;
  max-height: 4800px;
  display: block;
  position: sticky;
  margin: 0 auto;
  margin-bottom: 100px;
`;

export const TopContainer = styled.div`
  border-top: 3px solid black;
  padding: 20px 0;
  margin-bottom: 20px;
`;

export const Underline = styled.div`
  border-bottom: 1.5px solid #E8E8E8; /* Adjust the color and thickness as needed */
  margin-bottom: 24px;
  width: 100%;v
`;

export const H3 = styled.div`
  font-size: 24px;
  margin-bottom: 20px;
  display: flex;
  font-weight: 600;
  margin: 50px 50px 20px 30px;
`;

export const ModalContainer = styled(Box)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  background-color: white; /* Change to white background */
  padding: 20px;
  z-index: 100;
  border: 1px solid #ccc; /* Add a subtle border */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
`;

export const TapButton = styled.button`
  border: 1px solid #ccc;
  height: 50px;
  font-size: 1rem;
  font-weight: 600;
  width: 20%;
  background-color: #fff;
  transition: background-color 0.3s, color 0.3s;

  &:hover,
  focus {
    cursor: grab;
    background-color: ${(props) => props.$backgroundColor || "#11235A"};
    color: #fff;
  }
`;

export const TypeBox = styled.div`
  font-size: 15px;
  border: ${(props) => props.$border || "1px solid #11235A"};
  border-radius: 15px;
  text-align: center;
  color: ${(props) => props.color || "#11235A"};
  width: 100px;
  padding: 5px 0;
`;

export const ContentField = styled.div`
  padding: 20px 20px;
  font-size: 15px;
`;

export const ContentFieldReply = styled.div`
  padding: 20px 20px;
  font-size: 15px;
  background-color: #fafafa;
`;

export function renderTextWithLineBreaks(text) {
  return text.split("\n").map((line, index) => (
    <React.Fragment key={index}>
      {line}
      {index < text.split("\n").length - 1 && <br />}
    </React.Fragment>
  ));
}

export function JWTDecoder() {
  const token = localStorage.getItem("accessToken")?.replace("Bearer ", "");

  let decodedJWT = null;
  if (token) {
    const base64Payload = token.split(".")[1];
    const base64 = base64Payload.replace(/-/g, "+").replace(/_/g, "/");
    decodedJWT = JSON.parse(
      decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      )
    );
  }
  const userId = decodedJWT?.id;

  return userId;
}
