import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from "@apollo/client";

const DEV_SERVER_URL = "https://api.a-pex.co.kr/graphql";
const LOCAL_SERVER_URL = "http://localhost:3030/graphql";
const httpLink = new HttpLink({ uri: DEV_SERVER_URL });
// const httpLink = new HttpLink({ uri: LOCAL_SERVER_URL });

const authLink = new ApolloLink((operation, forward) => {
  const accessToken = localStorage.getItem("accessToken");
  operation.setContext({
    headers: {
      authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  });
  return forward(operation);
});

export const client = new ApolloClient({
  // link: httpLink,
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
