import React, { useState, useEffect } from "react";
import { gql } from "@apollo/client";
import { client } from "../../../client";
import {
  Container,
  H3,
  JWTDecoder,
  TopContainer,
  ContentField,
  renderTextWithLineBreaks,
  ContentFieldReply,
} from "../style/Customer.module";
import UpperBar from "../style/UpperBar";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Pagination,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const GET_ERROR_REPORT = gql`
  query getErrorReport($id: Int, $page: Int, $pageSize: Int) {
    getErrorReport(id: $id, page: $page, pageSize: $pageSize) {
      totalCount
      list {
        id
        user_id
        name
        title
        type
        state
        reply
        content
        object_url
        created_at
        updated_at
      }
    }
  }
`;

const ErrorReport = () => {
  const [reportList, setReportList] = useState([]);
  const [selectedReportId, setSelectedReportId] = useState(null);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  const userId = JWTDecoder();

  const enumType = {
    DOWNLOAD: "DOWNLOAD",
    PAYMENT: "PAYMENT",
    REQUEST: "REQUEST",
    ETC: "ETC",
  };

  const fetchData = async () => {
    try {
      await client
        .query({
          query: GET_ERROR_REPORT,
          variables: {
            userId: userId,
            page,
            pageSize: 200,
          },
        })
        .then((res) => {
          const tempReportList = [...res.data.getErrorReport.list];
          console.log("tempReportList...", tempReportList);

          const sortedReportList = tempReportList.sort(
            (l, r) => r.created_at - l.created_at
          );

          const updatedReportList = sortedReportList
            .filter(
              (item) =>
                item.user_id === userId && item.type === enumType.DOWNLOAD
            )
            .map((item) => ({
              ...item,
              isResponded: false,
            }));
          setReportList(updatedReportList);
        });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  const modifyDate = (date) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(parseInt(date)).toLocaleString("ko-KR", options);
  };

  const handleDeleteClick = (report) => async () => {
    const DELETE_ERROR_REPORT = gql`
      mutation deleteErrorReport($id: Int!, $user_id: Int!) {
        deleteErrorReport(id: $id, user_id: $user_id) {
          message
        }
      }
    `;

    try {
      const res = await client.mutate({
        mutation: DELETE_ERROR_REPORT,
        variables: {
          id: report.id,
          user_id: report.user_id,
        },
      });
      window.alert("취소되었습니다.");
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  const toggleReportContent = (reportId) => {
    setSelectedReportId(selectedReportId === reportId ? null : reportId);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleErrorClick = () => {
    navigate("/customer/records/edit");
  };

  function ReportImgItem(report) {
    const isValidSrc = report && report.object_url.includes("upload");

    return (
      <div>
        {isValidSrc && (
          <img
            width="300px"
            style={{ maxHeight: "300px" }}
            src={report.object_url}
            alt="이미지"
            onError={(e) => (e.currentTarget.style.display = "none")}
          />
        )}
      </div>
    );
  }

  return (
    <div>
      <Container>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          marginTop={"30px"}
          fontSize={"30px"}
          fontWeight={"700"}
          alignItems={"center"}
        >
          <p>오류제보</p>
          <Box>
            <Button
              onClick={handleErrorClick}
              sx={{ fontWeight: 600, fontSize: "18px" }}
            >
              제보하기
            </Button>
          </Box>
        </Box>

        <TopContainer>
          <UpperBar />
        </TopContainer>

        <TableContainer
          component={Paper}
          sx={{ height: "100%", marginBottom: "10px" }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="row" width={"60%"}>
                  <Box fontSize={"18px"} fontWeight={600}>
                    제목
                  </Box>
                </TableCell>
                <TableCell component="th" scope="row" width={"10%"}>
                  <Box fontSize={"18px"} fontWeight={600} textAlign={"center"}>
                    작성자
                  </Box>
                </TableCell>
                <TableCell component="th" scope="row" width={"10%"}>
                  <Box fontSize={"18px"} fontWeight={600} textAlign={"center"}>
                    작성일
                  </Box>
                </TableCell>
                <TableCell component="th" scope="row" width={"10%"}>
                  <Box fontSize={"18px"} fontWeight={600} textAlign={"center"}>
                    상태
                  </Box>
                </TableCell>
                <TableCell component="th" scope="row" width={"10%"}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reportList.map((report) => (
                <React.Fragment key={report.id}>
                  <TableRow>
                    <TableCell component="th" scope="row" width={"60%"}>
                      <Box
                        fontSize={"16px"}
                        onClick={() => toggleReportContent(report.id)}
                      >
                        {report.title}
                      </Box>
                    </TableCell>
                    <TableCell
                      align="right"
                      component="th"
                      scope="row"
                      width={"10%"}
                    >
                      {report.name}
                    </TableCell>
                    <TableCell
                      align="right"
                      component="th"
                      scope="row"
                      width={"10%"}
                    >
                      {modifyDate(report.created_at)}
                    </TableCell>
                    <TableCell
                      align="right"
                      component="th"
                      scope="row"
                      width={"10%"}
                    >
                      {report.state === 1 ? (
                        <Box>접수대기</Box>
                      ) : report.state === 2 ? (
                        <Box>접수완료</Box>
                      ) : (
                        <Box>답변완료</Box>
                      )}
                    </TableCell>
                    <TableCell
                      align="right"
                      component="th"
                      scope="row"
                      width={"10%"}
                    >
                      <Button onClick={handleDeleteClick(report)}>취소</Button>
                    </TableCell>
                  </TableRow>

                  {selectedReportId === report.id && (
                    <>
                      <TableRow>
                        <TableCell colSpan={5}>
                          <Box display={"flex"}>
                            <Box width={"100%"}>
                              <ContentField>
                                <Box marginBottom={2} color={"#a1a1b1"}>
                                  Q. {report.title}
                                </Box>
                                {renderTextWithLineBreaks(report.content)}
                              </ContentField>
                            </Box>
                            <Box>{ReportImgItem(report)}</Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                      {report.reply !== null ? (
                        <TableRow>
                          <TableCell colSpan={5} sx={{ background: "#fafafa" }}>
                            <ContentFieldReply>
                              {renderTextWithLineBreaks(report.reply)}
                            </ContentFieldReply>
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </>
                  )}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          count={
            Math.ceil(reportList.length / 10) === 1
              ? Math.ceil(reportList.length / 10 + 1)
              : Math.ceil(reportList.length / 10)
          }
          page={page}
          onChange={handleChangePage}
          showFirstButton
          showLastButton
        />
      </Container>
    </div>
  );
};

export default ErrorReport;
