import React, { useEffect } from "react";

const FileUploader = ({
  setSelectedFiles,
  selectedFiles,
  progress,
  setProgress,
  showAlert,
  setShowAlert,
}) => {
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);

    const combinedFiles = [...selectedFiles, ...files];

    setSelectedFiles(combinedFiles);
  };

  return (
    <div>
      <input
        type="file"
        // accept=".pdf, .hwp, .zip"
        multiple
        onChange={handleFileChange}
      />
    </div>
  );
};

export default FileUploader;
