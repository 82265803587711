// ModalComponent.js
import React, {useState, useEffect} from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import { gql } from '@apollo/client';
import { client } from '../../../client';

const ModalContainer = styled(Box)`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 70%;
    background-color: white; /* Change to white background */
    padding: 20px;
    z-index: 100;
    border: 1px solid #ccc; /* Add a subtle border */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
`;

const UserInfoContainer = styled.div`
    display: flex; 
    flex-direction: column;
    width: 100%;
    align-items: center; /* Center-align all children */
    margin-top: 30px;
    margin-bottom: 30px;
`;

const UserInfoItem = styled.div`
    display: flex; /* Display children in a row */
    flex-direction: row;
    margin-bottom: 10px; /* Add margin between child components */
`;

const UserModal = ({ 
    isOpen, data, handleCloseClick, refetchParentData, children }) => {


    // useState, useEffect
    const [editableData, setEditableData] = useState({});
    useEffect(() => {
      setEditableData({
        name: data?.name || "",
        email: data?.email || "",
        phone_number: data?.phone_number || "",
        type: data?.type || "",
        current_point: data?.current_point || "",
      });
    }, [data]);

    // functions
    const handleInputChange = (key, value) => {
      setEditableData((prevData) => ({
        ...prevData,
        [key]: value,
      }));
    };

    const handleUpdateClick = () => {
      const UPDATE_USER_INFO = gql`
        mutation updateUserInfoByAdmin($update_user_input: update_user_input) {
          updateUserInfoByAdmin(update_user_input: $update_user_input) {
            message
          }
        }
      `;

      client
        .mutate({
          mutation: UPDATE_USER_INFO,
          variables: {
            update_user_input: {
              id: data.id,
              ...editableData,
              region1: data.region1,
              region2: data.region2,
              current_point : parseInt(editableData.current_point)
            },
          },
        })
        .then((res) => {
          console.log("update");
          console.log(res);
          // todo : 저장
          // todo : 유저 데이터 다시 가져오기
          // Close the modal and trigger parent data refetch
          refetchParentData();
          handleCloseClick();
        })
        .catch((err) => {
          console.log(err);
        });
      // 서버에서 수정 가능한 값은 다음과 같다.
      // name: update_user_input.name,
      // phone_number: update_user_input.phone_number,
      // type: update_user_input.type,
      // region1: update_user_input.region1,
      // region2: update_user_input.region2,
      // ad_agreement: update_user_input.ad_agreement,
    };

    if (!isOpen) return null;

    return (
      <ModalContainer>
        <h2> 유저 정보 수정 </h2>
        <UserInfoContainer>
          {Object.keys(data).map((key) => {
            if (key == "__typename") return null;
            return (
              <div key={key}>
                {key === "name" ||
                key === "email" ||
                key === "phone_number" ||
                key === "type" ||
                key === "current_point" ? (
                  <UserInfoItem>
                    <div>
                      <label>{key}: </label>
                    </div>
                    <div>
                      <input
                        type="text"
                        value={editableData[key]}
                        onChange={(e) => handleInputChange(key, e.target.value)}
                      />
                    </div>
                  </UserInfoItem>
                ) : key === "created_at" ? (
                  <div>
                    <div>
                      {key} : {data[key]}
                    </div>
                  </div>
                ) : (
                  // <div> 아아 </div>
                  <UserInfoItem>
                    {key} : {data[key]}
                  </UserInfoItem>
                )}
              </div>
            );
          })}
        </UserInfoContainer>
        <Button variant="contained" onClick={() => handleUpdateClick()}>
          {" "}
          저장{" "}
        </Button>
        <Button onClick={() => handleCloseClick()}> 닫기 </Button>
      </ModalContainer>
    );
};

export default UserModal;
