import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Container = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 2px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  margin-top: 20px;
`;

export const SearchContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 5px;
`;

export const Form = styled.form`
  width: 100%;
`;

export const FilterContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background: #fff;
  padding: 10px;
`;

export const FilterSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: left;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 10px;
`;
export const FilterCategory = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 10px;
`;

export const FilterMap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 10px;
`;
export const Divider = styled.span`
  font-size: 16px;
  margin: 16px 0;
  width : 30px;
  font-weight: bold;
`;

export const FilterCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

export const FilterTitle = styled.h3`
  // font-size: 16px;
  font-size:  ${(props) => props.theme.fontSize.subtitle};
  font-weight: bold;
  margin-right: 1rem;
  width: 80px;
  display: flex;
`;

export const FilterCheckboxLabel = styled.label`
  margin-right: 10px;
  font-size:  ${(props) => props.theme.fontSize.normal};
  margin: 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FilterCheckbox = styled.input`
  margin-right: 5px;
  margin-left: 10px;
  justify-content: center;
`;

export const Select = styled.select`
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

export const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  flex-grow: 1;
`;

export const SearchButton = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
  &:hover {
    background-color: #0056b3;
  }
`;
