import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Input } from "@mui/material";

const StyledFormContainer = styled.div`
  max-width: 400px;
  margin: 0 auto;
`;

const FileUploaderV1 = ({
  uploadFile,
  setSelectedFile,
  selectedFile,
  progress,
  setProgress,
  showAlert,
  setShowAlert,
}) => {
  useEffect(() => {}, []);

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    const fileExt = file.name.split(".").pop();
    console.log("file", file);
    console.log("type", file.type);
    setSelectedFile(e.target.files[0]);
  };

  return (
    <div>
      <Input color="primary" type="file" onChange={handleFileInput} />
    </div>
  );
};

export default FileUploaderV1;
