import React, { useState } from "react";
import styled from "styled-components";
import { Underline } from "./Customer.module";

const Nav = styled.nav`
  display: flex;
  align-items: center;
  margin-right: auto;
  height: 100px;
  max-width: 100%;
  justify-content: center;
`;

const NavLink = styled.a`
  margin: 0 2rem;
  text-decoration: none;
  color: #000;
  font-size: ${(props) => props.theme.fontSize.title};

  &:hover {
    color: #0064ff;
    cursor: grab;
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 25px;
  background-color: #666;
  margin: 0 0.5rem;
`;

const UpperBar = () => {
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("accessToken")
  );

  const handleInquiryClick = () => {
    if (!accessToken) {
      window.alert("로그인이 필요합니다.");
      window.location.href = "/login";
    } else {
      window.location.href = "/customer/records";
    }
  };

  const handleErrorClick = () => {
    if (!accessToken) {
      window.alert("로그인이 필요합니다.");
      window.location.href = "/login";
    } else {
      window.location.href = "/customer/errors";
    }
  };

  return (
    <Nav>
      <NavLink href="/customer/faq">FAQ</NavLink>
      <Divider />
      <NavLink href="/customer/notice">공지사항</NavLink>
      <Divider />
      <NavLink onClick={handleInquiryClick}>문의내역</NavLink>
      <Divider />
      <NavLink onClick={handleErrorClick}>오류제보</NavLink>
    </Nav>
  );
};

export default UpperBar;
