import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import styled from "styled-components";
import Button from "@mui/material/Button";
import { gql } from "@apollo/client";
import { client } from "../../../client";

import AWS from "aws-sdk";
import FileUploader from "../admin-document/FileUploader";

const ModalContainer = styled(Box)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  background-color: white; /* Change to white background */
  padding: 20px;
  z-index: 100;
  border: 1px solid #ccc; /* Add a subtle border */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
`;

const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center; /* Center-align all children */
  margin-top: 30px;
  margin-bottom: 30px;
`;

const UserInfoItem = styled.div`
  display: flex; /* Display children in a row */
  flex-direction: row;
  margin-bottom: 10px; /* Add margin between child components */
`;

const NoticeText = styled.textarea`
  width: 200px;
  height: 150px;
`;

const ModifyCashPayAdmin = ({
  isOpen,
  data,
  handleCloseClick,
  refetchParentData,
  children,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [showAlert, setShowAlert] = useState(false);


  const [editableData, setEditableData] = useState({});
  useEffect(() => {
    setEditableData({
      id: data?.id,
      status: data?.status,
    });
  }, [data]);

  const handleInputChange = (key, value) => {
      setEditableData((prevData) => ({
        ...prevData,
        [key]: value,
      }));
    
  };

  const handleModifyClick = () => {
    const MODIFY_CASH_PAY = gql`
    mutation modifyCashPayByAdmin($id:Int, $status:String) {
      modifyCashPayByAdmin(id:$id, status:$status) {
        message
      }
    }
    `;

    let variables = {
      id: editableData.id,
      status: editableData.status,
    };


    client
      .mutate({
        mutation: MODIFY_CASH_PAY,
        variables: variables,
      })
      .then((res) => {
        console.log("update");
        console.log(res);
        refetchParentData();
        handleCloseClick();
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updatedTime = (params) => {
    const date = new Date(parseInt(params));
    return date.toLocaleString("ko-kr");
  };

  if (!isOpen) return null;

  return (
    <ModalContainer>
      <h2> 수정 </h2>
      {JSON.stringify(editableData)}

      <UserInfoContainer>
        {Object.keys(data).map((key) => {
          if (key === "__typename") return null;
          return (
            <div key={key}>
              { key === 'status' ? (
                <UserInfoItem>
                  <div>
                    <Box mr={"5px"}>{key}: </Box>
                  </div>
                  <div>
                    {key === "status" ? (
                      <select
                        value={editableData[key]}
                        onChange={(e) => handleInputChange(key, e.target.value)}
                      >
                        <option value={"PENDING"}>비승인</option>
                        <option value={"COMPLETE"}>승인</option>
                      </select>
                    ) : key === "updated_at" || key === "created_at" ? (
                      <Box>{updatedTime(data[key])}</Box>
                    ) : (
                      <input
                        type="text"
                        value={editableData[key]}
                        onChange={(e) => handleInputChange(key, e.target.value)}
                      />
                    )}
                  </div>
                </UserInfoItem>
              ) : (
                <UserInfoItem>
                  {key} : {data[key]}
                </UserInfoItem>
              )}
            </div>
          );
        })}
      </UserInfoContainer>

      <Button variant="contained" 
      onClick={() => handleModifyClick()}
      >
        저장
      </Button>

      <Button onClick={() => handleCloseClick()}> 닫기 </Button>
    </ModalContainer>
  );
};

export default ModifyCashPayAdmin;
