import React, { useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { gql } from "@apollo/client";
import { client } from "../../client";

const Footer = () => {

  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/customer/notice");
    // 최상단으로 스크롤 이동
    window.scrollTo(0, 0);
  };

  return (
    <FooterContainer>
      <div> 주식회사 APEX </div>
      <div> 사업자 등록 번호 : 485-86-02896 </div>
      <div> 대표자 : 김동재 </div>
      <div> 업태 및 종목 : 정보통신업 / 온라인 교육 및 콘텐츠 제작업 </div>
      <div>
        {" "}
        사업장 주소 : 경기도 용인시 기흥구 동백3로11번길 13(중동) 메디펠리스
        608호{" "}
      </div>
      <div> 고객센터 : 010-5129-2284 </div>
      <div
        onClick={handleClick}
        style={{ cursor: "pointer" }}
        onMouseEnter={(e) => {
          e.target.style.textDecoration = "underline";
        }}
        onMouseLeave={(e) => {
          e.target.style.textDecoration = "none";
        }}
      >
        {" "}
        개인정보취급방침 및 이용약관{" "}
      </div>
      <br />
      {/* <div> 정보 보호 책임자 : 김동재 </div> */}
      <div> 아이콘 제작자 : Muhazdinata - Flaticon </div>
    </FooterContainer>
  );
};

export default Footer;

const FooterContainer = styled.header`
    background-color: #2e2e2e;
    font-size: 1.2rem;
    color: #919191;
    height: 30vh;
    padding : 3rem;
    text-align: left;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

