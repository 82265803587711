import React, { useEffect, useState } from "react";
import styled from "styled-components";

import UpperBar from "../style/UpperBar";
import {
  ContentItem,
  Container,
  Underline,
  H3,
  ModalContainer,
  JWTDecoder,
} from "../style/Customer.module";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { gql } from "@apollo/client";
import { client } from "../../../client";

import AWS from "aws-sdk";
import FileUploader from "../../admin/admin-document/FileUploader";

const StyledFormContainer = styled.div`
  max-width: 400px;
  // border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 10px;
  padding: 30px 20px;
  margin: 0 auto;
`;

const InquiryForm = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [showAlert, setShowAlert] = useState(false);

  const enumType = {
    DOWNLOAD: "DOWNLOAD",
    PAYMENT: "PAYMENT",
    REQUEST: "REQUEST",
    ETC: "ETC",
  };

  Object.freeze(enumType);

  //DecodeToken
  const userId = JWTDecoder();

  // TODO env 에 넣기
  const ACCESS_KEY = "AKIA2MB36EXBJXFEOVGR";
  const SECRET_ACCESS_KEY = "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";
  const REGION = "ap-northeast-2";
  const S3_BUCKET = "seum-file";

  AWS.config.update({
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const uploadFile = (file) => {
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: "upload/" + file?.name,
    };

    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        console.log("evt...", evt);
        // setProgress(Math.round((evt.loaded / evt.total) * 100))
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
          setSelectedFile(null);
        }, 3000);
      })
      .send((err, data) => {
        if (err) {
          console.error("Error uploading:", err);
        } else {
          console.log("Upload successful:", data);
          const objectUrl = myBucket.getSignedUrl("getObject", {
            Key: params.Key,
          });

          const objectUrlString = objectUrl.split("?")[0];

          setFormData((currentFormData) => ({
            ...currentFormData,
            object_url: objectUrlString,
          }));
          alert(`파일업로드가 완료됐습니다. ${objectUrlString}`);

          handleSubmit(objectUrlString);

          console.log(typeof objectUrlString);
        }
      });
  };

  const handleSubmit = (objectUrlString) => {
    const CREATE_ERROR_REPORT = gql`
      mutation createErrorReport(
        $user_id: Int!
        $type: reportTypeEnum!
        $title: String!
        $content: String
        $object_url: String
      ) {
        createErrorReport(
          user_id: $user_id
          type: $type
          title: $title
          content: $content
          object_url: $object_url
        ) {
          message
        }
      }
    `;

    client
      .mutate({
        mutation: CREATE_ERROR_REPORT,
        variables: {
          user_id: parseInt(formData.user_id, 10),
          type: formData.type,
          title: formData.title,
          content: formData.content,
          object_url: objectUrlString,
        },
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        console.log(res);
        window.alert("등록되었습니다.");

        window.location.href = "/customer/records";
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // form
  const [formData, setFormData] = useState({
    user_id: userId,
    type: enumType.ETC,
    title: null,
    content: null,
    object_url: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    let updatedValue = value;

    setFormData({ ...formData, [name]: updatedValue });
  };

  const cancelButton = () => (window.location.href = "/customer/records");

  return (
    <Container>
      <H3>문의하기</H3>
      <Underline></Underline>
      <StyledFormContainer>
        <form>
          <FormControl>
            <InputLabel id="title"></InputLabel>
            <TextField
              label="제목"
              name="title"
              defaultValue={formData.title}
              onChange={handleChange}
              sx={{ marginBottom: 2, width: "400px" }}
            ></TextField>
          </FormControl>
          <FormControl>
            <InputLabel id="content"></InputLabel>
            <TextField
              label="내용"
              name="content"
              multiline
              rows={10}
              defaultValue={formData.content}
              onChange={handleChange}
              sx={{ marginBottom: 2, width: "400px" }}
            ></TextField>
          </FormControl>

          <FileUploader
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
          ></FileUploader>

          <Button
            sx={{ marginTop: "15px" }}
            onClick={() => uploadFile(selectedFile)}
          >
            제출
          </Button>

          <Button sx={{ marginTop: "15px" }} onClick={() => cancelButton()}>
            취소
          </Button>
        </form>
      </StyledFormContainer>
    </Container>
  );
};

export default InquiryForm;
