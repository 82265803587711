import { gql } from "@apollo/client";
import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { client } from "../client";

const Login = () => {
  const LOGIN = gql`
    mutation login($login_input: login_input!) {
      login(login_input: $login_input) {
        accessToken
      }
    }
  `;

  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({ id: "", password: "" });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const validateLoginForm = () => {
    return credentials.id.length > 0 && credentials.password.length > 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    client
      .mutate({
        mutation: LOGIN,
        variables: {
          login_input: {
            email: credentials.id,
            password: credentials.password,
          },
        },
      })
      .then((res) => {
        console.log(res);
        localStorage.setItem("isLoggedIn", "true");
        localStorage.setItem("accessToken", res.data.login.accessToken);
        navigate("/");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        if (err.message.includes("not exist user")) {
          alert("아이디 또는 비밀번호가 잘못되었습니다.");
          window.location.reload();
        } else if (err.message.includes("password")) {
          alert("아이디 또는 비밀번호가 잘못되었습니다.");
          setCredentials({
            ...credentials,
            password: "",
          });
        } else {
          alert("로그인에 실패했습니다.");
          window.location.reload();
        }
      });
    console.log("Login with:", credentials);
  };

  return (
    <LoginContainer>
      <LoginForm onSubmit={handleSubmit}>
        <LoginTitle>로그인</LoginTitle>
        <Divider disabled={!validateLoginForm()} />
        <InputGroup>
          <Input
            type="text"
            name="id"
            placeholder="아이디"
            value={credentials.id}
            onChange={handleChange}
            required
          />
          <Input
            type="password"
            name="password"
            placeholder="비밀번호"
            value={credentials.password}
            onChange={handleChange}
            required
          />
        </InputGroup>
        <ButtonContainer>
          <Button type="submit" disabled={!validateLoginForm()}>
            로그인
          </Button>
        </ButtonContainer>
        <OptionsGroup>
          <div>
            <OptionLink onClick={() => navigate("/signup")}>
              {" "}
              회원가입
            </OptionLink>
            <OptionLink onClick={() => navigate("/search/id")}>
              {" "}
              아이디 찾기
            </OptionLink>
            <OptionLink onClick={() => navigate("/search/password")}>
              {" "}
              비밀번호 변경
            </OptionLink>
          </div>
        </OptionsGroup>
      </LoginForm>
    </LoginContainer>
  );
};

export default Login;

const buttonColor = "#5b00ff";
const buttonHoverColor = "#3500D3";

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  margin-top: 50px;
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: 100%;
  max-width: 400px;
  background: #fff;
`;

const LoginTitle = styled.h2`
  text-align: center;
  justify-content: center;
  margin-bottom: 0rem;
`;

const Divider = styled.hr`
  margin: 1.5rem 0;
  border: 1px solid ${(props) => (props.disabled ? "#ddd" : buttonColor)};
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const Input = styled.input`
  padding: 0.6rem;
  margin-bottom: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

const Button = styled.button`
  padding: 0.7rem 1rem;
  color: white;
  background-color: ${(props) => (props.disabled ? "#ddd" : buttonColor)};
  border: none;
  border-radius: 5px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  transition: transform 0.1s ease;
  width: 100%;
  font-size: 1.3rem;
  &:active {
    transform: translateY(2px);
  }
`;

const OptionsGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 0.8rem;
`;

const OptionLink = styled.span`
  color: #010;
  margin: 0 0.5rem;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
