import styled from "styled-components";
import React, { useEffect, useState } from "react";
import SidebarPayment from "./SidebarPayment";
// import { Paylog } from "../paylog"
import PaymentProduct from "../PaymentProduct"

import Paylog from "../paylog";



const SidebarLayoutPayment = () => {
  const [page, setPage] = useState(true)
  const changePage = () => {
    setPage(!page)
  }
  return (
    <Layout>
      <SidebarPayment changePage = {changePage} /> 
      <Content>
        { page == 1 
        ? 
        <PaymentProduct></PaymentProduct>
        // 
        : <Paylog></Paylog>
        }
      </Content>
    </Layout>
  );
};

export default SidebarLayoutPayment;

const Layout = styled.div`
  display: flex;
  flex-grow: 1;
`;

const Content = styled.div`
  flex-grow: 1;
  padding: 20px;
  // height: 100vh;
`;