import React, { useState, useEffect } from "react";
import { gql } from "@apollo/client";
import { client } from "../../client";
import {
  Container,
  H3,
  TopContainer,
  TypeBox,
  ContentField,
  TapButton,
  renderTextWithLineBreaks,
  JWTDecoder,
} from "./style/Customer.module";
import UpperBar from "./style/UpperBar";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Pagination,
  Button,
} from "@mui/material";
import styled from "styled-components";

const GET_FAQ_LIST = gql`
  query getFaqByAdmin($id: Int, $page: Int, $pageSize: Int, $visible: Boolean) {
    getFaqByAdmin(
      id: $id
      page: $page
      pageSize: $pageSize
      visible: $visible
    ) {
      id
      user_id
      visible
      type
      title
      content
      object_url
      created_at
    }
  }
`;

const FAQ = () => {
  const [faqList, setFaqList] = useState([]);
  const [selectedFaqId, setSelectedFaqId] = useState(null);
  const [page, setPage] = useState(1);
  const [displayList, setDisplayList] = useState([]);
  const [activeFilter, setActiveFilter] = useState(null);

  const userId = JWTDecoder();

  const fetchData = async () => {
    try {
      await client
        .query({
          query: GET_FAQ_LIST,
          variables: {
            userId: userId,
            page,
            pageSize: 50,
            visible: true,
          },
        })
        .then((res) => {
          const result = [];
          const newFaqByAdmin = res.data.getFaqByAdmin.map((item, index) => {
            const newItem = { ...item };
            newItem["table_idx"] = res.data.getFaqByAdmin.length - index;
            result.push(newItem);
          });
          setFaqList(result);
          setDisplayList(result);
        });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  const modifyDate = (date) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(parseInt(date)).toLocaleString("ko-KR", options);
  };

  const toggleFaqContent = (faqId) => {
    setSelectedFaqId(selectedFaqId === faqId ? null : faqId);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  function FaqImgItem(faq) {
    const isValidSrc = faq && faq.object_url.includes("upload");

    return (
      <div>
        {isValidSrc && (
          <img
            width="300px"
            src={faq.object_url}
            alt="이미지"
            onError={(e) => (e.currentTarget.style.display = "none")}
          />
        )}
      </div>
    );
  }

  function FaqTypeItem(faq) {
    switch (faq.type) {
      case "ACCOUNT":
        return <TypeBox>계정</TypeBox>;
      case "ETC":
        return (
          <TypeBox $border="1px solid #994D1C" color={"#994D1C"}>
            기타
          </TypeBox>
        );
      case "PAYMENT":
        return (
          <TypeBox $border="1px solid #FB6D48" color={"#FB6D48"}>
            결제
          </TypeBox>
        );
      case "DOWNLOAD":
        return (
          <TypeBox $border="1px solid #3887BE" color={"#3887BE"}>
            다운로드
          </TypeBox>
        );
      default:
        return <Box fontSize={"16px"}>{faq.type}</Box>;
    }
  }

  const handleTapButton = (key) => {
    if (activeFilter === key) {
      setDisplayList(faqList);
      setActiveFilter(null);
    } else {
      const filteredList = faqList.filter((faq) => faq.type === key);
      setDisplayList(filteredList);
      setActiveFilter(key);
    }
  };
  return (
    <div>
      <Container>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          marginTop={"30px"}
          fontSize={"30px"}
          fontWeight={"700"}
          alignItems={"center"}
        >
          <p>자주 묻는 질문</p>
        </Box>

        <TopContainer>
          <UpperBar />

          <Box
            display={"flex"}
            backgroundColor={"#F4F4F8"}
            height={"80px"}
            alignItems={"center"}
            padding={"10px 0"}
            justifyContent={"center"}
          >
            <TapButton
              onClick={() => handleTapButton("DOWNLOAD")}
              $backgroundColor="#3887BE"
            >
              다운로드
            </TapButton>
            <TapButton onClick={() => handleTapButton("ACCOUNT")}>
              계정
            </TapButton>
            <TapButton
              onClick={() => handleTapButton("PAYMENT")}
              $backgroundColor="#FB6D48"
            >
              결제
            </TapButton>
            <TapButton
              onClick={() => handleTapButton("ETC")}
              $backgroundColor="#994D1C"
            >
              기타
            </TapButton>
          </Box>
        </TopContainer>

        <TableContainer
          component={Paper}
          sx={{
            height: "100%",
            marginBottom: "10px",
          }}
        >
          <Table>
            <TableHead sx={{ height: "75px" }}>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Box fontSize={"18px"} fontWeight={600}>
                    번호
                  </Box>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Box fontSize={"18px"} fontWeight={600} textAlign={"center"}>
                    구분
                  </Box>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Box fontSize={"18px"} fontWeight={600}>
                    제목
                  </Box>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Box fontSize={"18px"} fontWeight={600}>
                    작성일
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayList.map((faq, index) => (
                <React.Fragment key={faq.id}>
                  <TableRow
                    key={faq.id}
                    onClick={() => toggleFaqContent(faq.id)}
                  >
                    <TableCell component="th" scope="row" width={"5%"}>
                      {/* <Box fontSize={"16px"}>{faq.id}</Box> */}
                      <Box fontSize={"16px"}>{faq.table_idx}</Box>
                    </TableCell>
                    <TableCell component="th" scope="row" width={"10%"}>
                      {FaqTypeItem(faq)}
                    </TableCell>
                    <TableCell component="th" scope="row" width={"60%"}>
                      <Box fontSize={"16px"}>{faq.title}</Box>
                    </TableCell>
                    <TableCell component="th" scope="row" width={"10%"}>
                      <Box fontSize={"16px"}>{modifyDate(faq.created_at)}</Box>
                    </TableCell>
                  </TableRow>

                  {selectedFaqId === faq.id && (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Box display={"flex"}>
                          <Box margin={1} width={"100%"}>
                            <ContentField>
                              {renderTextWithLineBreaks(faq.content)}
                            </ContentField>
                          </Box>
                          <Box>{FaqImgItem(faq)}</Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          count={
            Math.ceil(faqList.length / 10) === 1
              ? Math.ceil(faqList.length / 10 + 1)
              : Math.ceil(faqList.length / 10)
          }
          page={page}
          onChange={handleChangePage}
          showFirstButton
          showLastButton
        />
      </Container>
    </div>
  );
};

export default FAQ;
