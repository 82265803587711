import React from "react";
import { Route, Routes } from "react-router-dom";
import CsatSub from "../component/document/page/CsatSub";
import HighschoolTextbook from "../component/document/page/HighschoolTextbook";
import MockTests from "../component/document/page/MockTests";
import OldEdition from "../component/document/page/OldEdition";
import SidebarLayout from "../component/document/sidebar/SidebarLayout";
import HighschoolSub from "../component/document/page/HighschoolSub";

const Search = () => {
  return (
    <SidebarLayout>
      <Routes>
        <Route path="mock-tests" element={<MockTests />} />
        <Route path="highschool-sub" element={<HighschoolSub />} />
        <Route path="csat-sub" element={<CsatSub />} />
        <Route path="highschool-textbook" element={<HighschoolTextbook />} />
        <Route path="old-edition" element={<OldEdition />} />
      </Routes>
    </SidebarLayout>
  );
};

export default Search;
