import React, { useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Paper, Button } from "@mui/material";
import { gql } from "@apollo/client";
import { client } from "../../client";
import GridItem from "./GridItem";

const DocumentPreviewGrid = ({ lastestDocumentList }) => {
  const GET_USER_INFO = gql`
    query getUserInfo {
      getUserInfo {
        name
      }
    }
  `;
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {}, []);

  const items = [
    {
      id: 1,
      title: "모의고사 / 학평",
      subtitle: "",
      img: "https://img.iipamaster.com/upload/bnr/20230824/20230824111221_5828.jpg",
    },
    {
      id: 2,
      title: "고등 부교재",
      subtitle: "EBS 교재",
      img: "https://img.iipamaster.com/upload/bnr/20231207/20231207161616_9280.png",
    },
    {
      id: 3,
      title: "수능연계교재",
      subtitle: "수능특강, 수능완성",
      img: "https://img.iipamaster.com/upload/bnr/20231207/20231207161616_9280.png",
    },
    {
      id: 4,
      title: "고등교과서",
      subtitle: "오픈 예정",
      description: "Hello World!",
      img: "https://img.iipamaster.com/upload/bnr/20231207/20231207161616_9280.png",
    },
  ];

  return (
    <Container>
      <SectionTitle>최신 자료</SectionTitle>
      <GridContainer>
        {items.map((item) => {
          return (
            <GridItem
              key={item["id"]}
              index={item["id"]}
              title={item["title"]}
              subtitle={item["subtitle"]}
              lastestDocumentList={lastestDocumentList}
            ></GridItem>
          );
        })}
      </GridContainer>
      <br />
    </Container>
  );
};

export default DocumentPreviewGrid;

const Container = styled.div`
  width: 70%;
  min-width: 900px;
  padding-right: 1.4rem;
`;
const GridContainer = styled.div`
  // min-width: 800px;
  display: flex;
  flex-wrap: wrap;
  gap: 1.4rem;
  padding-right: 1.4rem;
`;

const SectionTitle = styled.div`
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: normal;
  max-height: 4.8rem;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 150%;
  text-align: left;
  margin-bottom: 1rem;
  margin-top: 2.2rem;
`;
